const MAX_NEW_QUESTION = 30

let displayLanguage = 'english';
let numberQuestionNew = 0;
let numberQuestionNewRemove = 0;
let numberAnswer = 0;
let numberAnswerAddNew = 4;
let numberAnswerAddUpdate = 0;

let otherAssessment = (function () {
    let modules = {};

    modules.showAnswerOption = function () {
        $('.add-answer-option').append(`
            <div class="answer-container m20t">
              <div class="title">
                <p>List of answer options<span class="requirement-sign">*</span></p>
              </div>

              <div class="pl-3 row">
                <div class="col-12 row answer-option-content">
                  <div class="col-12">
                    <p class="float-left mb-0 mr-4 w-30 font-weight-medium">Vietnamese</p>
                    <p class="float-left mb-0 mr-4 w-30 font-weight-medium">English</p>
                    <p class="float-left mb-0 mr-4 w-30 font-weight-medium">Spanish</p>
                  </div>

                  <div class="col-12 answer-option m20t d-inline-flex">
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_vn[]" id="answer-vn-new-1" placeholder="Please enter Vietnamese answer">
                    </div>
                    
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_en[]" id="answer-en-new-1" placeholder="Please enter English answer">
                    </div>
                    
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_sp[]" id="answer-sp-new-1" placeholder="Please enter Spanish answer">
                    </div>
                  </div>
                  
                  <div class="col-12 answer-option m20t d-inline-flex">
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_vn[]" id="answer-vn-new-2" placeholder="Please enter Vietnamese answer">
                    </div>
                    
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_en[]" id="answer-en-new-2" placeholder="Please enter English answer">
                    </div>
                    
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_sp[]" id="answer-sp-new-2" placeholder="Please enter Spanish answer">
                    </div>
                  </div>
                </div>

                <div class="col-12 m20t">
                  <div class="row">
                    <div class="col-6 text-note"></div>

                    <div class="col-6 title text-right">
                        <div id="add-new-answer">
                            <i class="fas fa-plus-square m5r icon-create pointer"></i><span class="text-black pointer">Add a answer option</span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        `)
    }

    modules.showAnswerOptionEdit = function () {
        $('.edit-answer-option').append(`
            <div class="answer-container m20t">
              <div class="title">
                <p>List of answer options<span class="requirement-sign">*</span></p>
              </div>

              <div class="pl-3 row">
                <div class="col-12 row answer-option-content">
                  <div class="col-12">
                    <p class="float-left mb-0 mr-4 w-30 font-weight-medium">Vietnamese</p>
                    <p class="float-left mb-0 mr-4 w-30 font-weight-medium">English</p>
                    <p class="float-left mb-0 mr-4 w-30 font-weight-medium">Spanish</p>
                  </div>

                  <div class="col-12 answer-option m20t d-inline-flex">
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_vn_update" id="answer-vn-update-1" placeholder="Please enter Vietnamese answer">
                    </div>
                    
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_en_update" id="answer-en-update-1" placeholder="Please enter English answer">
                    </div>
                    
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_sp_update" id="answer-sp-update-1" placeholder="Please enter Spanish answer">
                    </div>
                  </div>
                  
                  <div class="col-12 answer-option m20t d-inline-flex">
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_vn_update" id="answer-vn-update-2" placeholder="Please enter Vietnamese answer">
                    </div>
                    
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_en_update" id="answer-en-update-2" placeholder="Please enter English answer">
                    </div>
                    
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_sp_update" id="answer-sp-update-2" placeholder="Please enter Spanish answer">
                    </div>
                  </div>
                  
                  <div class="col-12 answer-option m20t d-inline-flex">
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_vn_update" id="answer-vn-update-3" placeholder="Please enter Vietnamese answer">
                    </div>
                    
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_en_update" id="answer-en-update-3" placeholder="Please enter English answer">
                    </div>
                    
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_sp_update" id="answer-sp-update-3" placeholder="Please enter Spanish answer">
                    </div>
                  </div>
                  
                  <div class="col-12 answer-option m20t d-inline-flex">
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_vn_update" id="answer-vn-update-4" placeholder="Please enter Vietnamese answer">
                    </div>
                    
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_en_update" id="answer-en-update-4" placeholder="Please enter English answer">
                    </div>
                    
                    <div class="w-30 mr-4">
                        <input class="input-custom-style float-left input-modal" name="answer_sp_update" id="answer-sp-update-4" placeholder="Please enter Spanish answer">
                    </div>
                  </div>
                </div>

                <div class="col-12 m20t">
                  <div class="row">
                    <div class="col-6 text-note"></div>

                    <div class="col-6 title text-right">
                        <div id="add-new-answer-update">
                            <i class="fas fa-plus-square m5r icon-create pointer"></i><span class="text-black pointer">Add a answer option</span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        `)
    }

    modules.showTrashAnswer = function () {
        $('.answer-option').append(`
            <div class="answer-item-trash centered-vertical pointer">
                <i class="fas fa-trash"></i>
            </div>
        `)
    }

    modules.addAnswerOption = function () {
        numberAnswer = $('.answer-option').length;
        numberAnswerAddNew++
        numberAnswer++;
        if (numberAnswer >= 12) {
            $('#add-new-answer').addClass('disable-answer');
            $('.text-note').append(`<span class="requirement-sign">Maximum is 12 answers</span>`)
        }

        $('.answer-option-content').append(`
          <div class="col-12 answer-option m20t d-inline-flex">
            <div class="w-30 mr-4">
                <input class="input-custom-style float-left input-modal" name="answer_vn[]" id="answer-vn-new-${numberAnswerAddNew}" placeholder="Please enter Vietnamese answer">
            </div>
            
            <div class="w-30 mr-4">
                <input class="input-custom-style float-left input-modal" name="answer_en[]" id="answer-en-new-${numberAnswerAddNew}" placeholder="Please enter English answer">
            </div>
            
            <div class="w-30 mr-4">
                <input class="input-custom-style float-left input-modal" name="answer_sp[]" id="answer-sp-new-${numberAnswerAddNew}" placeholder="Please enter Spanish answer">
            </div>
            
            <div class="answer-item-trash centered-vertical pointer">
                <i class="fas fa-trash"></i>
            </div>
          </div>   
        `)
    }

    modules.addAnswerOptionUpdate = function () {
        numberAnswer = $('.answer-option').length;
        numberAnswer++;
        if (numberAnswer >= 12) {
            $('#add-new-answer-update').addClass('disable-answer');
            $('.text-note').append(`<span class="requirement-sign">Maximum is 12 answers</span>`)
        }

        $('.answer-option-content').append(`
          <div class="col-12 answer-option m20t d-inline-flex">
            <div class="w-30 mr-4">
                <input class="input-custom-style float-left input-modal" id="answer-vn-update-${numberAnswerAddUpdate}" name="answer_vn_update" placeholder="Please enter Vietnamese answer">
            </div>
            
            <div class="w-30 mr-4">
                <input class="input-custom-style float-left input-modal" id="answer-en-update-${numberAnswerAddUpdate}" name="answer_en_update" placeholder="Please enter English answer">
            </div>
            
            <div class="w-30 mr-4">
                <input class="input-custom-style float-left input-modal" id="answer-sp-update-${numberAnswerAddUpdate}" name="answer_sp_update" placeholder="Please enter Spanish answer">
            </div>
            
            <div class="answer-item-trash centered-vertical pointer">
                <i class="fas fa-trash"></i>
            </div>
          </div>   
        `)
    }

    modules.showDataFollowLanguageSupport = function (languageValue) {
        let languageSupport = $(languageValue).val();
        displayLanguage = languageSupport;

        if (languageSupport === 'vietnamese') {
            $('.lg-vietnamese').removeClass('d-none');
            $('.lg-english').addClass('d-none');
            $('.lg-spanish').addClass('d-none');
        } else if (languageSupport === 'english') {
            $('.lg-vietnamese').addClass('d-none');
            $('.lg-english').removeClass('d-none');
            $('.lg-spanish').addClass('d-none');
        } else if (languageSupport === 'spanish') {
            $('.lg-vietnamese').addClass('d-none');
            $('.lg-english').addClass('d-none');
            $('.lg-spanish').removeClass('d-none');
        }
    }

    modules.clearErrMessageWhenCloseModal = function (inputClass) {
        $(inputClass).removeClass('error');
        $(inputClass).siblings('.error').html('');
    }

    modules.deleteAssessmentSet = function () {
        let id = $('#assessment-set-id').val();

        if (isNaN(id)) {
            return false;
        }

        $.ajax({
            type: 'DELETE',
            url: '/management/master/assessment/other-assessments/' + id,
            data: {
                id: id
            }
        });
    }

    modules.clearAssessmentQuestionOnList = function () {
        let addId = $('#assessment-question-add-id').val();
        let setQuestionID = $('#assessment-set-question-add-id').val();
        let question = $('.question-add-' + addId);
        otherAssessment.removeErrorMaxQuestion(question);
        question.remove();
        otherAssessment.reOrderNumberNewQuestion(setQuestionID);
    }

    modules.getIdToCreateQuestion = function (obj) {
        $('#assessment-set-id-create').val(obj.attr("data-id"));
    }

    modules.getIdOtherAssessmentSetToSaveQuestion = function (obj) {
        $('#assessment-set-id-save').val(obj.attr("data-assessment-set-id"));
    }

    modules.editOtherAssessmentSet = function (obj) {
        let id = obj.attr("data-id");

        if (isNaN(id)) {
            return false;
        }

        $.ajax({
            type: 'GET',
            url: '/management/master/assessment/other-assessments/' + id + '/edit',
            data: {
                id: id
            },

            success: function (response) {
                $('#edit-other-assessment-name').val(response.data.name);
                $('#other-assessment-id').val(response.data.id)
            }
        });
    }

    modules.updateOtherAssessmentSet = function () {
        let id = $('#other-assessment-id').val();
        let name = $('#edit-other-assessment-name').val();

        $.ajax({
            type: 'PATCH',
            url: '/management/master/assessment/other-assessments/' + id,
            data: {
                id: id,
                name: name,
            },
        });
    }

    modules.hideModal = function (idModal) {
        $(idModal).modal('hide');
    }

    modules.resetDataModal = function (idModal) {
        $(idModal).find('form').trigger('reset');
        $('.answer-container').remove();
    }

    modules.resetDataFormSearch = function (formClass) {
        $(formClass).find('input').val("");
        if ($(formClass).find('.date .error')) {
            $(formClass).find('.date .error').remove();
        }
        $(formClass).find('select').prop("selectedIndex", 0);
        $(formClass).find('select[name="status"]').val("active");
        $(formClass).find('select[id="assessment-status"]').val("all");
        $(formClass).find('select[id="transfer-expert-status"]').val("");
        $(formClass).find('select[id="conversation-status"]').val("finished");
        $(formClass).find('select[name="language"]').val("2");

        $('.input-group.date').datepicker('clearDates');
    }

    modules.addAssessmentQuestionOnList = function () {
        numberQuestionNew ++;
        numberQuestionNewRemove ++;
        let assessmentSetId = $('#assessment-set-id-create').val();
        let numberQuestionOfSet = ($('.set-'+ assessmentSetId+' .parent-content-data').length);
        let typeQuestion = $('#type-question-add').val();
        let questionNameVn = $('#question-name-vn').val();
        let questionNameEn = $('#question-name-en').val();
        let questionNameSp = $('#question-name-sp').val();
        let answersVn = "";
        let answersEn = "";
        let answersSp = "";

        $('input[name^=answer_vn]').each(function(index) {
            answersVn += "<input type='hidden' class='question-add-"+numberQuestionNew+" answer-new-"+numberQuestionNew+" answer-vn-new' id='answer-vn-new-"+numberQuestionNew+"-"+(index + 1)+"' name='data["+numberQuestionNew+"][answers_vn][]' value='"+$(this).val()+"'>";
        });

        $('input[name^=answer_en]').each(function(index) {
            answersEn += "<input type='hidden' class='question-add-"+numberQuestionNew+" answer-new-"+numberQuestionNew+" answer-en-new' id='answer-en-new-"+numberQuestionNew+"-"+(index + 1)+"' name='data["+numberQuestionNew+"][answers_en][]' value='"+$(this).val()+"'>";
        });

        $('input[name^=answer_sp]').each(function(index) {
            answersSp += "<input type='hidden' class='question-add-"+numberQuestionNew+" answer-new-"+numberQuestionNew+" answer-sp-new' id='answer-sp-new-"+numberQuestionNew+"-"+(index + 1)+"' name='data["+numberQuestionNew+"][answers_sp][]' value='"+$(this).val()+"'>";
        });

        $('#question-id-' + assessmentSetId).append(`
            <div class="parent-content-data question-add-${numberQuestionNew} question-add-new-${numberQuestionNew}">
              <div class="content-data">
                <div class="content-number" id="parent-label-question-${numberQuestionOfSet + 1}">
                  <p data-disable="true" class="margin-auto order-question number-question-new" id="label-question-${numberQuestionOfSet + 1}">${numberQuestionOfSet + 1}</p>
                </div>
              </div>

              <li class="row m0 w-100" id="li-question-${numberQuestionOfSet + 1}">
                <div class="col-10 p0 d-flex">
                  <div class="question-content lg-vietnamese ${displayLanguage === 'vietnamese' ? '' : 'd-none'}">
                    <button class="fa fa-bars handle" set-question-id="${assessmentSetId}"></button>
                    
                    <div class="centered-vertical pointer question-edit-new" data-toggle="modal" data-target="#edit-assessment-question" data-question-new-id="${numberQuestionNew}">
                      ${questionNameVn}
                    </div>
                  </div>
                  
                  <div class="question-content lg-english ${displayLanguage === 'english' ? '' : 'd-none'}">
                    <button class="fa fa-bars handle" set-question-id="${assessmentSetId}"></button>
                    
                    <div class="centered-vertical pointer question-edit-new" data-toggle="modal" data-target="#edit-assessment-question" data-question-new-id="${numberQuestionNew}">
                        ${questionNameEn}
                    </div>
                  </div>
                  
                  <div class="question-content lg-spanish ${displayLanguage === 'spanish' ? '' : 'd-none'}">
                    <button class="fa fa-bars handle" set-question-id="${assessmentSetId}"></button> 
                    
                    <div class="centered-vertical pointer question-edit-new" data-toggle="modal" data-target="#edit-assessment-question" data-question-new-id="${numberQuestionNew}">
                        ${questionNameSp}
                    </div>
                  </div>
                </div>

                <div class="col-2 p0 d-flex">
                  <input class="question-content" type="text" value="${otherAssessment.formatDataTypeQuestion(typeQuestion)}" disabled>
                </div>
              </li>

              <div class="question-trash centered-vertical pointer btn-delete" data-assessment-set-id="${assessmentSetId}" data-add-id="${numberQuestionNew}" data-toggle="modal" data-target="#deleteQuestionModal">
                <i class="fas fa-trash"></i>
              </div>
              
                <input type="hidden" class="question-add-${numberQuestionNew}" name="data[${numberQuestionNew}][assessment_id]" value="${assessmentSetId}">
                <input type="hidden" class="question-add-${numberQuestionNew}" id="type-question-${numberQuestionNew}" name="data[${numberQuestionNew}][question_type]" value="${typeQuestion}">
                <input type="hidden" class="question-add-${numberQuestionNew}" id="vietnamese-${numberQuestionNew}" name="data[${numberQuestionNew}][name][]" value="${questionNameVn}">
                <input type="hidden" class="question-add-${numberQuestionNew}" id="english-${numberQuestionNew}" name="data[${numberQuestionNew}][name][]" value="${questionNameEn}">
                <input type="hidden" class="question-add-${numberQuestionNew}" id="spanish-${numberQuestionNew}" name="data[${numberQuestionNew}][name][]" value="${questionNameSp}">

                ${ (typeQuestion === '5' || typeQuestion === '6') ?
                    (
                        answersVn +
                        answersEn +
                        answersSp
                    ) : ""
                }
            </div>
        `)

        if (numberQuestionNewRemove >= MAX_NEW_QUESTION) {
            $('#question-id-' + assessmentSetId).closest('.question-set-content').find('.error-question').text('Maximum is 30 questions');
            $('#question-id-' + assessmentSetId).closest('.question-set-content').find('.container-function-question').addClass('d-none');
        }
    }

    modules.formatDataTypeQuestion = function (typeQuestion) {
        let displayTypeQuestion = "";
        switch(typeQuestion.toString()) {
            case '1':
                displayTypeQuestion = "Text";
                break;
            case '2':
                displayTypeQuestion = "Paragraph";
                break;
            case '3':
                displayTypeQuestion = "Number";
                break;
            case '4':
                displayTypeQuestion = "Date time";
                break;
            case '5':
                displayTypeQuestion = "Single choice";
                break;
            case '6':
                displayTypeQuestion = "Multiple choice";
                break;
            default:
                displayTypeQuestion = typeQuestion;
        }
        return displayTypeQuestion;
    }

    modules.reOrderNumberNewQuestion = function (setQuestionId) {
        $(`#assessment-set-${setQuestionId} .number-question-new`).each(function (index, value) {
            $(value).text(index + 1);
        })
    }

    modules.getIdQuestionToEdit = function (obj) {
        $('#edit-question-new-id').val(obj.attr("data-question-new-id"));
    }

    modules.showDataEditQuestionNew = function () {
        let idQuestion = $('#edit-question-new-id').val();
        let typeQuestion = $('#type-question-' + idQuestion).val();

        $('#vietnamese-data').val($('#vietnamese-' + idQuestion).val());
        $('#english-data').val($('#english-' + idQuestion).val());
        $('#spanish-data').val($('#spanish-' + idQuestion).val());
        $('#question-type-data').val(typeQuestion);

        if (typeQuestion === '5' || typeQuestion === '6') {
            let numberAnswer = $(`.question-add-${idQuestion} .answer-vn-new`).length;
            numberAnswerAddUpdate = $(`.question-add-${idQuestion} .answer-vn-new`).length;
            $('.edit-answer-option').append(`
                <div class="answer-container m20t">
                  <div class="title">
                    <p>List of answer options<span class="requirement-sign">*</span></p>
                  </div>
    
                  <div class="pl-3 row">
                    <div class="col-12 row answer-option-content" id="answer-option-content">
                      <div class="col-12">
                        <p class="float-left mb-0 mr-4 w-30 font-weight-medium">Vietnamese</p>
                        <p class="float-left mb-0 mr-4 w-30 font-weight-medium">English</p>
                        <p class="float-left mb-0 mr-4 w-30 font-weight-medium">Spanish</p>
                      </div>
                    </div>
    
                    <div class="col-12 m20t">
                      <div class="row">
                        <div class="col-6 text-note"></div>
    
                        <div class="col-6 title text-right">
                            <div id="add-new-answer-update">
                                <i class="fas fa-plus-square m5r icon-create pointer"></i><span class="text-black pointer">Add a answer option</span>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            `)

            if (numberAnswer >= 12) {
                $('#add-new-answer-update').addClass('disable-answer');
            }

            if (numberAnswer > 2) {
                for (let i=1; i<=numberAnswer; ++i ){
                    numberAnswerAddUpdate++
                    $('#answer-option-content').append(`
                        <div class="col-12 answer-option m20t d-inline-flex">
                            <div class="w-30 mr-4">
                                <input class="input-custom-style float-left input-modal" name="answer_vn_update" id="answer-vn-update-${numberAnswerAddUpdate}" placeholder="Please enter Vietnamese answer" value="${$(`#answer-vn-new-${idQuestion}-${i}`).val()}">
                            </div>
                            
                            <div class="w-30 mr-4">
                                <input class="input-custom-style float-left input-modal" name="answer_en_update" id="answer-en-update-${numberAnswerAddUpdate}" placeholder="Please enter English answer" value="${$(`#answer-en-new-${idQuestion}-${i}`).val()}">
                            </div>
                            
                            <div class="w-30 mr-4">
                                <input class="input-custom-style float-left input-modal" name="answer_sp_update" id="answer-sp-update-${numberAnswerAddUpdate}" placeholder="Please enter Spanish answer" value="${$(`#answer-sp-new-${idQuestion}-${i}`).val()}">
                            </div>
                            
                            <div class="answer-item-trash centered-vertical pointer">
                                <i class="fas fa-trash"></i>
                            </div>
                        </div>
                    `)
                }
            } else {
                for (let i=1; i<=numberAnswer; ++i ){
                    numberAnswerAddUpdate++
                    $('#answer-option-content').append(`
                        <div class="col-12 answer-option m20t d-inline-flex">
                            <div class="w-30 mr-4">
                                <input class="input-custom-style float-left input-modal" name="answer_vn_update" id="answer-vn-update-${numberAnswerAddUpdate}" placeholder="Please enter Vietnamese answer" value="${$(`#answer-vn-new-${idQuestion}-${i}`).val()}">
                            </div>
                            
                            <div class="w-30 mr-4">
                                <input class="input-custom-style float-left input-modal" name="answer_en_update" id="answer-en-update-${numberAnswerAddUpdate}" placeholder="Please enter English answer" value="${$(`#answer-en-new-${idQuestion}-${i}`).val()}">
                            </div>
                            
                            <div class="w-30 mr-4">
                                <input class="input-custom-style float-left input-modal" name="answer_sp_update" id="answer-sp-update-${numberAnswerAddUpdate}" placeholder="Please enter Spanish answer" value="${$(`#answer-sp-new-${idQuestion}-${i}`).val()}">
                            </div>
                        </div>
                    `)
                }
            }
        }
    }

    modules.updateDataEditQuestionNew = function () {
        let idQuestion = $('#edit-question-new-id').val();
        let questionVN = $('#vietnamese-data').val();
        let questionEN = $('#english-data').val();
        let questionSP = $('#spanish-data').val();
        let typeQuestion = $('#question-type-data').val();

        $('#vietnamese-' + idQuestion).val(questionVN);
        $('#english-' + idQuestion).val(questionEN);
        $('#spanish-' + idQuestion).val(questionSP);
        $('#type-question-' + idQuestion).val(typeQuestion);

        $(`.question-add-${idQuestion} .lg-vietnamese .question-edit-new`).text(questionVN);
        $(`.question-add-${idQuestion} .lg-english .question-edit-new`).text(questionEN);
        $(`.question-add-${idQuestion} .lg-spanish .question-edit-new`).text(questionSP);
        $(`.question-add-${idQuestion} .question-content`).val(otherAssessment.formatDataTypeQuestion(typeQuestion));

        $('.answer-new-' + idQuestion).remove();

        let answersVnUpdate = "";
        let answersEnUpdate = "";
        let answersSpUpdate = "";
        $('input[name^=answer_vn_update]').each(function(index) {
            answersVnUpdate += "<input type='hidden' class='question-add-"+idQuestion+" answer-new-"+idQuestion+" answer-vn-new' id='answer-vn-new-"+idQuestion+"-"+(index + 1)+"' name='data["+idQuestion+"][answers_vn][]' value='"+$(this).val()+"'>";
        });
        $('input[name^=answer_en_update]').each(function(index) {
            answersEnUpdate += "<input type='hidden' class='question-add-"+idQuestion+" answer-new-"+idQuestion+" answer-en-new' id='answer-en-new-"+idQuestion+"-"+(index + 1)+"' name='data["+idQuestion+"][answers_en][]' value='"+$(this).val()+"'>";
        });
        $('input[name^=answer_sp_update]').each(function(index) {
            answersSpUpdate += "<input type='hidden' class='question-add-"+idQuestion+" answer-new-"+idQuestion+" answer-sp-new' id='answer-sp-new-"+idQuestion+"-"+(index + 1)+"' name='data["+idQuestion+"][answers_sp][]' value='"+$(this).val()+"'>";
        });

        $('.question-add-new-' + idQuestion).append(
            answersVnUpdate +
            answersEnUpdate +
            answersSpUpdate
        )
    }

    modules.showDataEditQuestionOld = function (obj) {
        let id = obj.attr("data-id");

        if (isNaN(id)) {
            return false;
        }

        $.ajax({
            type: 'GET',
            url: '/management/master/assessment/other-assessments-questions/' + id,
            data: {
                id: id
            },

            success: function (response) {
                $('#question-type-data-old').val(otherAssessment.formatDataTypeQuestion(response.question[0]['question_type']));

                let questionNameOld = $('.edit-question-name-old');
                questionNameOld.html('');
                questionNameOld.append(`
                    <div class="m10t">
                      <p class="m0">Vietnamese</p>
                      <input class="input-custom-style input-modal other-question-old" name="other_question_trans[${response.question[0]['question_translation_id']}][name]" placeholder="Please enter Vietnamese question" value="${response.question[0]['name']}" maxlength="255">
                    </div>
    
                    <div class="m10t">
                      <p class="m0">English</p>
                      <input class="input-custom-style input-modal other-question-old" name="other_question_trans[${response.question[1]['question_translation_id']}][name]" placeholder="Please enter English question" value="${response.question[1]['name']}" maxlength="255">
                    </div>
    
                    <div class="m10t">
                      <p class="m0">Spanish</p>
                      <input class="input-custom-style input-modal other-question-old" name="other_question_trans[${response.question[2]['question_translation_id']}][name]" placeholder="Please enter Spanish question" value="${response.question[2]['name']}" maxlength="255">
                    </div>
                `)

                if (Object.keys(response.answer).length !== 0) {
                    $('.edit-answer-option-old').append(`
                        <div class="answer-container m20t">
                          <div class="title">
                            <p>List of answer options<span class="requirement-sign">*</span></p>
                          </div>
            
                          <div class="pl-3 row">
                            <div class="col-12 row answer-option-content" id="answer-option-content">
                              <div class="col-12 d-inline-flex">
                                  <div class="w-30 mr-4">
                                    <p class="float-left font-weight-medium">Vietnamese</p>
                                  </div>
                                  <div class="w-30 mr-4">
                                    <p class="float-left font-weight-medium">English</p>
                                  </div>
                                  <div class="w-30 mr-4">
                                    <p class="float-left font-weight-medium">Spanish</p>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    `)

                    Object.entries(response.answer).forEach(([key, value]) => {
                        $('#answer-option-content').append(`
                            <div class="col-12 answer-option m20t d-inline-flex">
                                <div class="w-30 mr-4">
                                    <input class="input-custom-style float-left input-modal other-answer-old" name="other_answer_trans[${value[0]['answer_translation_id']}][name]" placeholder="Please enter Vietnamese answer" value="${value[0]['name']}" maxlength="255">
                                </div>
    
                                <div class="w-30 mr-4">
                                    <input class="input-custom-style float-left input-modal other-answer-old" name="other_answer_trans[${value[1]['answer_translation_id']}][name]" placeholder="Please enter English answer" value="${value[1]['name']}" maxlength="255">
                                </div>
    
                                <div class="w-30 mr-4">
                                    <input class="input-custom-style float-left input-modal other-answer-old" name="other_answer_trans[${value[2]['answer_translation_id']}][name]" placeholder="Please enter Spanish answer" value="${value[2]['name']}" maxlength="255">
                                </div>
                            </div>
                        `)
                    });
                }
            }
        });
    }

    modules.validationEditQuestionOld = function (obj) {
        if ($(obj).val() === '' && !$(this).hasClass('error')) {
            $(obj).addClass('error')
            $(obj).after('<p class="question_error error">This field is required.</p>')
        } else if ($(obj).val().length > 255 && !$(obj).hasClass('error')) {
            $(obj).addClass('error')
            $(obj).after('<p class="question_error error">Please enter no more than 255 characters.</p>')
        }
    }

    modules.clearErrMessageErrValidation = function (obj) {
        if ($(obj).hasClass('error')) {
            $(obj).removeClass('error')
            $(obj).siblings('.question_error').remove();
        }
    }

    modules.removeErrorMaxQuestion = function (obj) {
        $('.error-question').text('');
        numberQuestionNewRemove--;
        obj.closest('.question-set-content').find('.container-function-question').removeClass('d-none');
    }

    return modules;
}(window.jQuery, window, document));

$(document).on("turbolinks:load", function () {
    $(document).on('click', '.no-collapsable', function (e) {
        e.stopPropagation();
    });

    $(document).on('click', '.confirm-delete', function (e) {
        e.preventDefault();

        let id = $(this).data('id');
        $('#delete-questionnaire').data('id', id).modal('show');
    });

    $(document).on('click', '#btnYes', function () {
        // handle deletion here
        let id = $('#delete-questionnaire').data('id');
        $('[data-id=' + id + ']').parent().parent().parent().parent().parent().remove();
        $('#myModal').modal('hide');
    });

    $(document).on('change', '#type-question-add', function () {
        if ($("div").has("answer-option-content")) {
            $(".answer-container").remove();
        }

        let typeQuestion = $("#type-question-add").val();
        if (typeQuestion === '5' || typeQuestion === '6') {
            otherAssessment.showAnswerOption();
        }
    });

    $(document).on('change', '#question-type-data', function () {
        if ($("div").has("answer-option-content")) {
            $(".answer-container").remove();
        }

        let typeQuestion = $("#question-type-data").val();
        if (typeQuestion === '5' || typeQuestion === '6') {
            otherAssessment.showAnswerOptionEdit();
        }
    });

    $(document).off('click', '#add-new-answer');
    $(document).on('click', '#add-new-answer', function () {
        if (!$("div").hasClass("answer-item-trash")) {
            otherAssessment.showTrashAnswer();
        }

        if ($('.answer-option').length < 12) {
            otherAssessment.addAnswerOption();
        }
    });

    $(document).off('click', '#add-new-answer-update');
    $(document).on('click', '#add-new-answer-update', function () {
        if (!$("div").hasClass("answer-item-trash")) {
            otherAssessment.showTrashAnswer();
        }

        if ($('.answer-option').length < 12) {
            otherAssessment.addAnswerOptionUpdate();
        }
    });

    $(document).on('click', '.answer-item-trash', function () {
        $(this).parent().remove();
        numberAnswer--;
        if (numberAnswer < 12) {
            $('#add-new-answer').removeClass('disable-answer');
            $('#add-new-answer-update').removeClass('disable-answer');
            $('.text-note').html('');
        }

        if ($('.answer-option-content').children('.answer-option').length <= 2) {
            $(".answer-item-trash").remove();
        }
    });

    $(document).on('change', '.form-check-input', function () {
        otherAssessment.showDataFollowLanguageSupport(this);
    });

    $(document).on('hidden.bs.modal', '.other-assessment-modal', function () {
        otherAssessment.clearErrMessageWhenCloseModal('.input-modal');
        otherAssessment.resetDataModal('#add-question');
        otherAssessment.resetDataModal('#add-assessment-set');
    });

    $(document).on('click', '.btn-confirm-delete', function () {
        otherAssessment.deleteAssessmentSet();
    });

    $(document).on('click', '.btn-confirm-delete-question', function () {
        otherAssessment.clearAssessmentQuestionOnList(this);
    });

    $(document).on('click', '.btn-create-question', function () {
        $('#add-question').modal('show');
        otherAssessment.resetDataModal('#add-question');
        otherAssessment.getIdToCreateQuestion($(this));
    });

    $(document).on('click', '.question-save-all', function () {
        otherAssessment.getIdOtherAssessmentSetToSaveQuestion($(this));
    });

    $(document).on('click', '.btn-save-confirm', function () {
        let formId = $('#assessment-set-id-save').val();
        $('#assessment-set-' + formId).submit();
        $('#save-confirm').modal('hide');
    });

    $(document).on('click', '.icon-edit-other-assessment', function () {
        otherAssessment.editOtherAssessmentSet($(this));
    });

    $(document).on('click', '.btn-update-other-assessment-set', function (event) {
        event.preventDefault();

        if ($('#edit-other-assessment').valid()) {
            otherAssessment.hideModal('#edit-assessment-set');
            otherAssessment.updateOtherAssessmentSet();
        }
    });

    $(document).off('click', '.btn-add-new-assessment-question');
    $(document).on('click', '.btn-add-new-assessment-question', function (event) {
        event.preventDefault();

        if ($('#create-assessment-question').valid()) {
            otherAssessment.hideModal('#add-question');
            otherAssessment.addAssessmentQuestionOnList();
        }
    });

    $(document).off('click', '.question-edit-new');
    $(document).on('click', '.question-edit-new', function () {
        otherAssessment.getIdQuestionToEdit($(this));
        otherAssessment.showDataEditQuestionNew();
    });

    $(document).on('click', '.btn-update-question-new', function (event) {
        event.preventDefault();

        if ($('#form-edit-assessment-question').valid()) {
            otherAssessment.updateDataEditQuestionNew();
            otherAssessment.hideModal('#edit-assessment-question');
            otherAssessment.resetDataModal('#edit-assessment-question');
        }
    });

    $(document).on('click', '.btn-clear-search', function () {
        otherAssessment.resetDataFormSearch('.form-list-search');
    });

    $(document).on('hidden.bs.modal', '#edit-change', function () {
        otherAssessment.clearErrMessageWhenCloseModal('.input-modal');
    });

    $(document).off('click', '.assessment-question');
    $(document).on('click', '.assessment-question', function () {
        otherAssessment.showDataEditQuestionOld($(this));
    });

    $(document).on('blur', '.other-question-old', function () {
        otherAssessment.clearErrMessageErrValidation($(this));
        otherAssessment.validationEditQuestionOld($(this));
    });

    $(document).on('keyup', '.other-question-old', function () {
        otherAssessment.clearErrMessageErrValidation($(this));
    });

    $(document).on('blur', '.other-answer-old', function () {
        otherAssessment.clearErrMessageErrValidation($(this));
        otherAssessment.validationEditQuestionOld($(this));
    });

    $(document).on('keyup', '.other-answer-old', function () {
        otherAssessment.clearErrMessageErrValidation($(this));
    });

    $(document).on('click', '.btn-update-question-old', function () {
        if (!($('#form-edit-assessment-question-old .input-custom-style').hasClass('error'))) {
            $('#form-edit-assessment-question-old').submit();
        }
    });

    validate_assessment_set_create('#create-other-assessment');

    validate_assessment_set_create('#create-assessment-question');

    validate_assessment_set_create('#edit-other-assessment');

    validate_assessment_set_create('#form-edit-assessment-question');

    function validate_assessment_set_create(form_id) {
        $(form_id).validate({
            ignore: [],

            onfocusout: function (element) {
                this.element(element);
            },

            errorElement: "p",
            normalizer: function (value) {
                return $.trim(value);
            },

            rules: {
                name: {
                    required: true,
                    maxlength: 255,
                    remote: {
                        url: '/management/master/assessment/other-assessments/check_duplicate_fields_name',
                        type: "get",
                        data: {
                            name: function () {
                                return $('#other-assessment-name').val() || $('#edit-other-assessment-name').val();
                            },
                            id: function () {
                                return $('#other-assessment-id').val();
                            }
                        }
                    }
                },
                assessment_code: {
                    required: true,
                    maxlength: 10,
                    remote: {
                        url: '/management/master/assessment/other-assessments/check_duplicate_fields_name',
                        type: "get",
                        data: {
                            assessment_code: function () {
                                return $('#other-assessment-code').val();
                            },
                        }
                    }
                },

                'name[]': {
                    required: true,
                    maxlength: 255,
                },

                'answer_vn[]': {
                    required: true,
                    maxlength: 255,
                },

                'answer_en[]': {
                    required: true,
                    maxlength: 255,
                },

                'answer_sp[]': {
                    required: true,
                    maxlength: 255,
                },

                'answer_vn_update': {
                    required: true,
                    maxlength: 255,
                },

                'answer_en_update': {
                    required: true,
                    maxlength: 255,
                },

                'answer_sp_update': {
                    required: true,
                    maxlength: 255,
                },
            },
            messages: {
                name: {
                    remote: $.validator.format("Name '{0}' is already exists")
                },
                assessment_code: {
                    remote: $.validator.format("Code '{0}' is already exists")
                },
            },
            submitHandler: function (form) {
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }
});