$(document).ready(function () {
    let elementEdit;
    function getHtml(length_temp){
        return `
            <div class="d-flex flex-column">
                <div class="problem-solving-new d-flex justify-content-between align-items-center position-relative difficul-problem-new" id="difficul-problem-${length_temp}">
                    <li id="li-question-1" class="show-modal-topic" data-toggle="modal" data-target="#change-topic">
                        <input class="difficul-problem-title-vn" type="hidden" name="difficult_problem_new[${length_temp}[title-vn]]" placeholder="Added topics">
                        <input class="difficul-problem-title-en" type="" name="difficult_problem_new[${length_temp}[title-en]]" placeholder="Added topics" readonly>
                        <input class="difficul-problem-title-sp" type="hidden" name="difficult_problem_new[${length_temp}[title-sp]]" placeholder="Added topics">
                    </li>
                    <div>
                      <input class="problem-checkbox" type="checkbox" name="difficult_problem_new[${length_temp}[is_show_new]]" value="true">
                    </div>
                    <div class="d-flex justify-content-center align-items-center position-absolute cursor icon-delete">
                      <img src="/assets/icon-delete-4f1ea91e70f5007e58644eda97897b5a3113e8f0a1cde218132770218e01be98.svg">
                    </div>
                </div>
                <span class="error-answer color-red mb-3 ml-4"></span>
            </div>
        `
    }

    function hiddenAlertError(element) {
        $('#title-topic-vn').removeClass('err-input');
        $('.error-answer-vn').text('');
        $('#title-topic-en').removeClass('err-input');
        $('.error-answer-en').text('');
        $('#title-topic-sp').removeClass('err-input');
        $('.error-answer-sp').text('');
    }

    function validateHtmlText(text) {
        let string = text.trim();
        return string;
    }

    function checkErrorModalEdit(textVn, textEn, textSp) {
        let errorVn = showNotification(textVn, '#title-topic-vn', $('.error-answer-vn'));
        let errorEn = showNotification(textEn, '#title-topic-en', $('.error-answer-en'));
        let errorSp = showNotification(textSp, '#title-topic-sp', $('.error-answer-sp'));

        if (errorVn && errorEn && errorSp) {
            return true;
        } else {
            return false;
        }
    }

    function showNotification(text, thisElement, elementError) {
        if (text === '') {
            $(thisElement).addClass('err-input');
            elementError.text('This field is required.');
            return  false;
        } else if (text.length > 30) {
            $(thisElement).addClass('err-input');
            elementError.text('Please enter no more than 30 characters.');
            return false;
        } else {
            $(thisElement).removeClass('err-input').css('border-color', '#b5b5b5');
            elementError.text('');
            return true;
        }
    }

    $(document).on('click', '.show-modal-topic', function () {
        hiddenAlertError('#change-topic');
        elementEdit = $(this);
        $(document).find('#title-topic-vn').val($(this).find('.difficul-problem-title-vn').val().trim())
        $(document).find('#title-topic-en').val($(this).find('.difficul-problem-title-en').val().trim())
        $(document).find('#title-topic-sp').val($(this).find('.difficul-problem-title-sp').val().trim())
    });

    $(document).on('click', '#submit-data-to-form', function () {
        let textVn = validateHtmlText($('#title-topic-vn').val());
        let textEn = validateHtmlText($('#title-topic-en').val());
        let textSp = validateHtmlText($('#title-topic-sp').val());
        if (checkErrorModalEdit(textVn, textEn, textSp)) {
            $("#change-topic .cancel").click();
            $('.modal-backdrop').remove();
            elementEdit.find('.difficul-problem-title-vn').val(textVn);
            elementEdit.find('.difficul-problem-title-en').val(textEn);
            elementEdit.find('.difficul-problem-title-sp').val(textSp);
        }
    });

    $(document).on('click', '.container-problem-topic #add-difficul-problem', function (){
        let length_temp = $('.difficul-problem-new').length;
        let html = getHtml(length_temp);
        $(this).before(html);
    })

    $(document).on('click', '.container-problem-topic .icon-delete', function (){
        $(this).parent().parent().remove();
    })

    $(document).on('click', '.container-problem-topic .update-problem-topic', async function () {
        let valid = true;
        $('.container-problem-topic .difficul-problem-title-en').map( function() {
            let check = showNotification($(this).val().trim(), $(this).parent(), $(this).parent().parent().parent().find('.error-answer'));
            if (check == false) {
                valid = false;
            }
        })
        if (valid == true) {
            $('#form-create-problem-topic').submit();
        }
    });

})
