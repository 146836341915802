import validate from './jquery.validate'

const MAX_LENGTH = 255

let RealisticThinkingQuestion = (function () {
    let modules = {};

    modules.cloneAnswer = function () {
        $('.realistic-thinking-answer').append(`
            <div class="row m0 container-answer">
                <div class="label-answer pointer">
                    <input type="radio" class="pointer" value="" name="is_correct">
                    <input type="hidden" class="correct" value="0" name="correct[]">
                  </div>
                <div class="content-answer">
                    <input type="text" name='answer[]' id="${Math.random()}" class="answer-question lang-vn answer-vn" placeholder="Please enter a answer">
                    <textarea name="explain_answer[]" id="${Math.random()}" class="mt-2 textarea-custom lang-vn w-100 lang-sp-vietnamese" rows="5" placeholder="Please enter a content of explain the answer"></textarea>
                </div>
                <div class="content-answer">
                    <input type="text" name='answer[]' id="${Math.random()}" class="answer-question lang-en answer-en" placeholder="Please enter a answer">
                    <textarea name="explain_answer[]" id="${Math.random()}" class="mt-2 textarea-custom lang-en w-100 lang-sp-english" rows="5" placeholder="Please enter a content of explain the answer"></textarea>
                  </div>
                <div class="content-answer">
                    <input type="text" name='answer[]' id="${Math.random()}" class="answer-question lang-sp answer-sp" placeholder="Please enter a answer">
                    <textarea name="explain_answer[]" id="${Math.random()}" class="mt-2 textarea-custom lang-sp w-100 lang-sp-spanish" rows="5" placeholder="Please enter a content of explain the answer"></textarea>
                </div>
            </div>
        `);
        RealisticThinkingQuestion.displayIconDelete();
    };

    modules.displayIconDelete = function () {
        let parent = $('.container-answer');
        for (let child of parent) {
            if (!$(child).find('.delete-answer').hasClass('delete-answer')) {
                $(child).append(`<div class="delete-answer"><button type="button" class="btn-delete-answer fas fa-trash pointer"></button></div>`);
            }
        }
    };

    modules.blockIconDelete = function () {
        if ($('.container-answer').length <= 4) {
            $('.delete-answer').remove();
        }
    };

    modules.checkRadioChecked = function (elementRemove) {
        let parent = $(elementRemove).parents('.container-answer');

        $(elementRemove).parents('.container-answer').remove();

        if (parent.find('input:radio').is(':checked')) {
            $('.container-answer .correct').attr('value', '0');
            $('.container-answer').first().find('.correct').attr('value', '1');
            $('.container-answer').first().find('input:radio').prop('checked', true);
        }
        RealisticThinkingQuestion.blockIconDelete();
    }

    modules.changeLanguage = function () {
        $('.checkbox-language-support').click(function () {
            if ($(this).prop("checked") === true) {
                $('.' + $(this).data('item')).prop("readonly", false);
                $('.' + $(this).data('item')).css('background', '#FFFFFF');
            } else if ($(this).prop("checked") === false) {
                $('.' + $(this).data('item')).prop("readonly", true);
                $('.' + $(this).data('item')).css('background', '#EBEBE4');
            }

            if ($(this + ':checked').length === 1) {
                $(this + ':checked').prop('disabled', true);
            } else {
                $(this).prop('disabled', false);
            }
        });
    };

    modules.deleteRealisticThinkingQuestion = function () {
        let id = $('#question-id').val();

        if (isNaN(id)) {
            return false;
        }

        $.ajax({
            type: 'DELETE',
            url: '/management/master/realistic-thinking-questions/' + id,
            data: {
                id: id
            },
        });
    }

    return modules;
}(window.jQuery, window, document));

$(document).on("turbolinks:load", function () {
    RealisticThinkingQuestion.changeLanguage();

    $(document).off('click', '.btn-add-answer');
    $(document).on('click', '.btn-add-answer', function () {
        RealisticThinkingQuestion.cloneAnswer();
    });

    let arrayDelete = [];
    $(document).on('click', '.btn-delete-answer', function () {
        RealisticThinkingQuestion.checkRadioChecked(this);
        arrayDelete.push($(this).parents('.container-answer').find('.realistic_answers').val());
        $('.delete-answer-translation').val(arrayDelete);
    });

    $(document).on('click', 'input:radio', function () {
        $('.container-answer .correct').attr('value', '0');
        $(this).next().attr('value', '1');
    });

    $(document).on('click', '.btn-confirm-delete-question', function () {
        RealisticThinkingQuestion.deleteRealisticThinkingQuestion();
    });

    // start validate for input elements into form create/edit realistic thinking question
    validate_form('#form_create_realistic');
    validate_form('#form_edit_realistic');

    function validate_form(form_id) {
        $(form_id).validate({
            onfocusout: function (element) {
                this.element(element);
            },
            normalizer: function (value) {
                return $.trim(value);
            },
            errorElement: "span",
            rules: {
                'question_name[]': {
                    required: true,
                    maxlength: MAX_LENGTH
                },
                'answer[]': {
                    required: true,
                    maxlength: MAX_LENGTH
                },
                'explain_answer[]': {
                    required: true,
                    maxlength: MAX_LENGTH
                },
            },
            submitHandler: function (form) {
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }
});
