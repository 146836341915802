let consultingPackage = (function () {
    let modules = {};

    modules.createConsultingPackage = function () {
        let numberConsulting = $('#number-consulting').val();
        numberConsulting++
        $('.consulting-package').append(
            `<div class="m0 row consulting-container">
                <div class="p0 col-10 col-xl-11">
                    <div class="consulting-box">
                        <div class="consulting-item text-left row m0">
                            <div class="col-4 p0">
                                <div class="consulting-input">
                                    <p>Number of sessions<span class="requirement-sign">*</span></p>
                                    <input class="input-custom input-number-session-consulting" id="number-session-new-${numberConsulting}" name="number_session_new[]" type="text" maxlength="9" placeholder="Please enter number of sessions">
                                </div>
                            </div>
                            <div class="col-4 ml-5 p0">
                                <div class="consulting-input">
                                    <p>Score<span class="requirement-sign">*</span></p>
                                    <input class="input-custom input-score-consulting" id="point-new-${numberConsulting}" name="point_new[]" type="text" maxlength="9" placeholder="Please enter score">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2 col-xl-1 centered-vertical">
                    <a href="javascript:void(0)" class="delete-consulting"><i class="fas fa-trash pointer"></i></a>
                </div>
            </div>`
        )
    }

    modules.validateNumberSessionAndPoint = function validate_form_consulting_package(form_id) {
        $(form_id).validate({
            onfocusout: function (element) {
                this.element(element);
            },
            normalizer: function (value) {
                return $.trim(value);
            },
            errorElement: "p",
            rules: {
                'number_session[]': {
                    required: true,
                    number: true,
                    min: 1,
                    unique: true,
                    maxlength: 9,
                    digits: true
                },
                'point[]': {
                    required: true,
                    number: true,
                    min: 0,
                    maxlength: 9,
                },
                'number_session_new[]': {
                    required: true,
                    number: true,
                    unique: true,
                    min: 1,
                    maxlength: 9,
                    digits: true
                },
                'point_new[]': {
                    required: true,
                    number: true,
                    min: 0,
                    maxlength: 9
                },
            },
            submitHandler: function (form) {
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }

    return modules;
}(window.jQuery, window, document));

$(document).ready(function () {
    $.validator.addMethod("unique", function(value, element) {
        let parentForm = $(element).closest('form');
        let timeRepeated = 0;
        if (value.replace(/^0+/, '') != '') {
            $(parentForm.find('input[name^="number_session"]')).each(function () {
                if ($(this).val().replace(/^0+/, '') === value.replace(/^0+/, '')) {
                    timeRepeated++;
                }
            });
        }
        return timeRepeated === 1 || timeRepeated === 0;

    }, "Please enter a non-duplicate value.");

    $(document).on('click', '.create-consulting', function () {
        consultingPackage.createConsultingPackage();
    });

    $(document).on('click', '.delete-consulting', function () {
        $(this).parent().parent().remove()
    });

    // validate form consulting package
    $(document).on('change', 'input', function () {
        consultingPackage.validateNumberSessionAndPoint("#form-logic-consulting-package");
    });

    $(document).on('click', '.submit', function () {
        $('.input-number-session-consulting').removeClass('error');
    });
});