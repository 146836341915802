function setActiveStatusOnSidebar () {
    $("#sidebar a").each(function () {
        let url = window.location.href;
        let href = url.replace(/^.*\/\/[^\/]+/, '');

        if ($(this).attr("href") === href.substr(0, $(this).attr("href").length)) {
            $(this).addClass('item-active');
            if ($(this).parent().parent().siblings().hasClass('children-sidebar')) {
                $(this).parent().parent().siblings().addClass('text-black');
            }

            $(this).parent().parent().addClass('show');
            $(this).parent().parent().parent().addClass('active');

            if (($(this).parent().parent().parent().parent()).hasClass('ul-first')) {
                ($(this).parent().parent().parent().parent().parent()).addClass('active');
                ($(this).parent().parent().parent().parent()).addClass('show');
            }
        }
    });
}

function showOrHideSidebar () {
    let body = $('body');
    if ($(body).hasClass('hide-sidebar')) {
        $(body).removeClass('hide-sidebar');
        $(body).addClass('show-sidebar');
    } else {
        $(body).addClass('hide-sidebar');
        $(body).removeClass('show-sidebar');
    }
}

function closeItemWhenClickOtherItem (active) {
    if ($(active).parent().siblings().hasClass('active')) {
        $(active).parent().siblings().removeClass('active');
        $(active).parent().siblings().find('.ul-first').removeClass('show');
    }
}

function setActiveStatusWhenClickItem (active) {
    if ($(active).parent().hasClass('active')) {
        $(active).parent().removeClass('active');
    } else {
        $(active).parent().addClass('active');
    }
}

function checkWindowWidth () {
    let body = $('body');
    let width = window.innerWidth;
    if (width <= 767.98 ) {
        $(body).addClass('hide-sidebar');
        $(body).removeClass('show-sidebar');
    } else {
        $(body).addClass('show-sidebar');
        $(body).removeClass('hide-sidebar');
    }
}

$(document).on("turbolinks:load", function () {
    checkWindowWidth();
    $(window).resize(function(){
        checkWindowWidth();
    });

    $('.parent-sidebar, .children-sidebar').on('click', function() {
        setActiveStatusWhenClickItem(this);
    });

    $('.button-menu-mobile').on('click', function () {
        showOrHideSidebar();
    })

    setActiveStatusOnSidebar();
});

