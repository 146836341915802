import toastr from "./toastr.min";

let options = {
    closeButton: true,
    debug: false,
    positionClass: "toast-top-right",
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "5000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    progressBar: true
}
let text_language = { 1: 'vn', 2: 'en', 3: 'sp'};
let list_language = ['vn', 'en', 'sp'];

let difficultProblemQuestion = (function () {
    let modules = {};
    modules.htmlTab = [];
    modules.thisTabItem = '';

    modules.saveOldDataTab = function () {
        let this_element = $('.container-difficult-problem-question .tab-content .tab-pane.active');

        difficultProblemQuestion.htmlTab = this_element.html();
        difficultProblemQuestion.thisTabItem = this_element.attr('id');
    };

    modules.defaultSubFormQuestion = function (language, index) {

        return `<div class="sub-form-data padding-sub-form flex-column mb-3" data-id="${index}">
            <div class="w-100 d-flex flex-wrap form-input question-input-field">
                <div class="w-75">
                    <div class="w-90 mb-3 parent-errors">
                        <label class="title-content-question">Question 1:</label>
                        <input type="text" class="content-question" disabled>
                        <span class="error-questions text-danger d-none"></span>
                    </div>
                    <div class="d-flex flex-column mb-3 mr-2">
                        <input type="text" class="content-answer" placeholder="Answer text" readonly disabled>
                        <input type="text" class="content-answer" readonly disabled>
                        <input type="text" class="content-answer" readonly disabled>
                    </div>
                    <div class="d-flex align-items-start">
                        <input type="checkbox" class="checkbox-other-answer checkbox-new-request">
                        <div class="w-100">
                            <div class="parent-errors">
                                <div class="d-flex">
                                    <div class="mr-1 event-click-checkbox">Allow users to add up to</div>
                                    <input type="number" class="number-other-answer" min="0" max="999" maxlength="3" name="question[${index}[${language}[number_answer]]]">
                                    <div class="ml-1 event-click-checkbox">more answers</div>
                                </div>
                                <span class="error-questions text-danger d-none"></span>
                            </div>
                            <div class="is-show-sub-title d-none">
                                <div class="mt-2">Questions for asking for more answers:</div>
                                <div class="w-100 mt-3 parent-errors d-flex flex-column">
                                    <input type="text" class="content-sub-other w-90" placeholder="Question" name="question[${index}[${language}[sub_content_question]]]">
                                    <span class="error-questions text-danger d-none"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-25">
                    <select class="w-100 select-option-type" name="question[${index}[${language}[question_type]]]">
                        <option selected value="1">Input field</option>
                        <option value="2">Checkbox</option>
                        <option value="3">Radio Button</option>
                    </select>
                </div>
                <hr class="w-100">
            </div>
        </div>`;
    };

    modules.defaultBtnAddSubForm = function () {
        return `
            <div class="btn-add-question-form sub-form-data">
                <div class="icon-add mb-3"><i class="fas fa-thin fa-plus"></i></div>
                <div>Click to add a question</div>
            </div>`;
    };

    // 71324234723 is number random
    modules.subOptionForm = function (index_sub_form, number_question, number_answer, language) {
        return `<div class="sub-option ml-4" data-sub-answer="${index_sub_form}71324234723${number_answer + 1}">
            <div class="d-flex align-items-center">
                <div class="mr-4">Follow-up answers</div>
                <div class="d-flex flex-wrap">
                    <div>
                        <label class="line-height d-flex">
                            <input type="radio" value="1" name="question[${index_sub_form}[${language}[option[${number_answer + 1}[type_child]]]]]]" data-number="${number_question}" data-number-answer="${number_answer}" class="custom-sub-radio" checked>
                            Radio button
                        </label>
                    </div>
                    <div>
                        <label class="line-height d-flex">
                            <input type="radio" value="2" name="question[${index_sub_form}[${language}[option[${number_answer + 1}[type_child]]]]]]" data-number="${number_question}" data-number-answer="${number_answer}" class="custom-sub-radio">
                            Checkbox
                        </label>
                    </div>
                    <div>
                        <label class="line-height d-flex">
                            <input type="radio" value="3" name="question[${index_sub_form}[${language}[option[${number_answer + 1}[type_child]]]]]]" data-number="${number_question}" data-number-answer="${number_answer}" class="custom-sub-radio">
                            Input field
                        </label>
                    </div>
                </div>
            </div>
            <div>
                <div class="option-default-sub">
                    <div class="d-flex flex-column parent-errors mb-2">
                        <div class="d-flex align-items-center">
                            <div class="circle mr-4"></div>
                            <input type="text" class="border-input-form w-70 option-child" name="question[${index_sub_form}[${language}[option[${number_answer + 1}[option_child[0]]]]]]">
                        </div>
                        <span class="error-questions text-danger d-none ml-45"></span>
                    </div>
                </div>
            </div>
            <div class="group-btn-action">
                <div class="circle icon-radio mr-4"></div>
                <div class="d-flex">
                    <button type="button" class="mr-2 default-btn-sub-form btn-add-option-default btn-sub-add-option" data-number="${number_question}" data-number-answer="${number_answer + 1}">Add option</button>
                </div>
            </div>
        </div>`;
    };

    modules.optionForm1 = function (checkbox, number_question, number_answer, number_option_child, language) {
        return `
        <div class="d-flex flex-column parent-errors mb-2">
            <div class="d-flex align-items-center">
                <div class="${checkbox} mr-4"></div>
                <input type="text" class="border-input-form option-child w-70" name="question[${number_question}[${language}[option[${number_answer}[option_child[${number_option_child}]]]]]]">
                <i class="fa fa-times ml-2 pointer remove-child-element text-danger" aria-hidden="true"></i>
            </div>
            <span class="error-questions text-danger d-none ml-45"></span>
        </div>`;
    };

    modules.optionForm2 = function (checkbox, number_question, index_option, length_option, type, language, is_first = false) {
        let btn_delete = is_first ? '' : '<i class="fa fa-times ml-2 pointer remove-adv-element text-danger" aria-hidden="true"></i>';

        return `
        <div class="d-flex flex-column parent-errors mb-2 parent-answer-adv">
            <div class="d-flex align-items-center">
                <div class="${checkbox} mr-4"></div>
                <input type="text" class="border-input-form option-child w-70" name="question[${number_question}[${language}[option[${index_option}[${type}[${length_option}]]]]]]">
                ${btn_delete}
            </div>
            <span class="error-questions text-danger d-none ml-45"></span>
        </div>`;
    };

    modules.optionFormRadio = function (language, data_index) {
        let number_answer = 0;
        let random_id = Math.floor(Math.random() * 1000000);

        return `
        <div class="sub-form-data padding-sub-form flex-column mb-3">
            <div class="w-100 d-flex flex-wrap form-radio" data-id="${random_id}" data-number-question="${data_index}">
            <input type="hidden" value="${random_id}" name="question[${data_index}[${language}[id_fake]]]">
                <div class="w-75">
                    <div class="w-90 mb-3 parent-errors">
                        <label class="title-content-question">Question 1:</label>
                        <input type="text" class="content-question" disabled>
                        <span class="error-questions text-danger d-none"></span>
                    </div>
                </div>
                <div class="w-25">
                    <select class="w-100 select-option-type" name="question[${data_index}[${language}[question_type]]]">
                        <option value="1">Input field</option>
                        <option value="2">Checkbox</option>
                        <option selected value="3">Radio Button</option>
                    </select>
                </div>
                <div class="d-flex flex-column w-100 ml-3 group-answer-question">
                    <div class="group-option group-option-answer-radio">
                        <div class="default-option">
                            <div class="row-option answer-group">
                                <div class="parent-errors d-flex flex-column mb-2">
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="circle mr-4"></div>
                                        <input type="text" class="border-input-form input-form-question w-70" name="question[${data_index}[${language}[option[${number_answer + 1}[content]]]]]">
                                    </div>
                                    <span class="error-questions text-danger d-none ml-45"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="circle mr-4"></div>
                        <div class="d-flex">
                            <button type="button" class="mr-2 default-btn-sub-form btn-add-option-default btn-add-option-radio" data-number="${data_index}" data-number-answer="${number_answer + 1}">Add option</button>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center w-100"><hr style="width: 60%"></div>
                <div class="w-100 ml-3">
                    <div class="d-flex align-items-start">
                        <label class="d-flex w-auto">
                            <input type="checkbox" class="checkbox-new-request checkbox-follow-question-radio" value="${data_index}">
                            <div>Follow up question for each answer</div>
                        </label>
                    </div>
                </div>
            </div>
            <hr>
        </div>`;
    };

    modules.optionFormCheckBox = function (language, data_index) {
        let number_answer = 0;
        let random_id = Math.floor(Math.random() * 1000000);

        return `
        <div class="sub-form-data padding-sub-form flex-column mb-3">
            <div class="w-100 d-flex flex-wrap form-checkbox question-type-checkbok" data-id="${random_id}" data-number-question="${data_index}">
                <input type="hidden" value="${random_id}" name="question[${data_index}[id_fake]]">
                <div class="w-75">
                    <div class="w-90 mb-3 parent-errors">
                        <label class="title-content-question">Question 1:</label>
                        <input type="text" class="content-question" disabled>
                        <span class="error-questions text-danger d-none"></span>
                    </div>
                </div>
                <div class="w-25">
                    <select class="w-100 select-option-type" name="question[${data_index}[${language}[question_type]]]">
                        <option value="1">Input field</option>
                        <option selected value="2">Checkbox</option>
                        <option value="3">Radio Button</option>
                    </select>
                </div>
                <div class="d-flex flex-column w-100 ml-3 group-answer-question">
                    <div class="group-option">
                        <div class="default-option">
                            <div class="row-option answer-group">
                                <div class="parent-errors d-flex flex-column mb-2">
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="square mr-4"></div>
                                        <input type="text" class="border-input-form input-form-question w-70" name="question[${data_index}[${language}[option[${number_answer + 1}[content]]]]]">
                                    </div>
                                    <span class="error-questions text-danger d-none ml-45"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="square mr-4"></div>
                        <div class="d-flex">
                            <button type="button" class="mr-2 default-btn-sub-form btn-add-option-default btn-add-option-checkbox" data-number="${data_index}" data-number-answer="${number_answer + 1}">Add option</button>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center w-100"><hr style="width: 60%"></div>
                <div class="w-100 ml-3">
                    <div class="d-flex align-items-start">
                        <label class="d-flex w-auto">
                            <input type="checkbox" class="checkbox-new-request checkbox-follow-question-radio" value="${data_index}">
                            <div>Follow up question for each answer</div>
                        </label>
                    </div>
                </div>
            </div>
            <hr>
        </div>`;
    };

    modules.btnSubOther = function (checkbox, class_add = 'btn-sub-add-option', class_other = 'btn-sub-other', number_question, number_answer) {
        return `
            <div class="group-btn-action">
                <div class="icon-radio mr-4 ${checkbox}"></div>
                <div class="d-flex">
                    <button type="button" class="mr-2 default-btn-sub-form btn-add-option-default ${class_add}" data-number="${number_question}" data-number-answer="${number_answer}">Add option</button>
                </div>
            </div>`;
    };

    modules.btnSubOther2 = function (checkbox, class_add = 'btn-sub-add-option', class_other = 'btn-sub-other', option, other) {
        return `
            <div class="group-btn-action">
                <div class="icon-radio mr-4 ${checkbox}"></div>
                <div class="d-flex">
                    <button type="button" class="mr-2 default-btn-sub-form btn-add-option-default ${class_add}" 
                    data-number-question="${option.attr('data-number-question')}" data-index="${option.attr('data-index')}" 
                    data-option="0" data-type="${option.attr('data-type')}">Add option</button>
                </div>
            </div>`;
    };

    // 71374364723 is number random
    modules.setAssessmentItem = function (index_form, content, random_id, is_disable = false, index, language) {
        return `
        <div class="option-question mb-4 ${ is_disable ? 'd-none' : '' }">
            <div class="mb-2">Assessment item:<span class="ml-2" data-sub-id="${random_id}">${difficultProblemQuestion.htmlEscape(content)}</span></div>
            <input type="hidden" class="content-hidden" name="question[${index_form}[${language}[option[${index}[content_child]]]]]" value="${difficultProblemQuestion.htmlEscape(content)}">
            <div class="ml-3">
                <div class="sub-question" data-sub-adv="${index_form}71374364723${index}-advantages">
                    <div>Advantages:</div>

                    <div class="list-option-question">
                        <div class="default-option" data-index="${index}" data-type="advantages">
                            <div class="parent-errors d-flex flex-column mb-2 parent-answer-adv">
                                <div class="d-flex align-items-center">
                                    <div class="circle mr-4"></div>
                                    <input type="text" class="border-input-form w-70" ${ is_disable ? 'disabled' : '' } name="question[${index_form}[${language}[option[${index}[advantages[0]]]]]]">
                                </div>
                                <span class="error-questions text-danger d-none ml-45"></span>
                            </div>
                        </div>
                    </div>

                    <div class="group-btn-action">
                        <div class="circle mr-4"></div>
                        <div class="d-flex">
                            <button 
                                type="button" class="mr-2 default-btn-sub-form btn-add-option-default btn-add-option-adv" 
                                data-number-question="${index_form}" data-index="${index}" data-option="0" 
                                data-type="advantages">
                                    Add option
                            </button>
                        </div>
                    </div>
                </div>

                <div class="sub-question" data-sub-adv="${index_form}71374364723${index}-disadvantages">
                    <div>Disadvantages:</div>
                    <div class="list-option-question">
                        <div class="default-option" data-index="${index}" data-type="disadvantages">
                            <div class="parent-errors d-flex flex-column mb-2 parent-answer-adv">
                                <div class="d-flex align-items-center">
                                    <div class="circle mr-4"></div>
                                    <input type="text" class="border-input-form w-70" ${ is_disable ? 'disabled' : '' } name="question[${index_form}[${language}[option[${index}[disadvantages[0]]]]]">
                                </div>
                                <span class="error-questions text-danger d-none ml-45"></span>
                            </div>
                        </div>
                    </div>

                    <div class="group-btn-action">
                        <div class="circle mr-4"></div>
                        <div class="d-flex">
                            <button type="button" class="mr-2 default-btn-sub-form btn-add-option-default btn-add-option-adv" 
                                data-number-question="${index_form}" data-index="${index}" data-option="0" 
                                data-type="disadvantages">
                                    Add option
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;
    };

    modules.htmlEscape = function (str) {
        return str.trim();
        // return str.trim().replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/'/g, '&#39;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    };

    modules.clearDataIdInput = function (parent) {
        $.each(parent.find('span[data-sub-id]'), (index, element) => {
            $(`.container-difficult-problem-question .container-question input[data-sub-id="${$(element).attr('data-sub-id')}"]`).removeAttr('data-sub-id');
        });
        parent.find('.group-sub-question').empty();
    };

    modules.validationInputForm = function (element) {
        let parent_form = element.parents('form').find('.group-container-form');
        let data_return = true;

        if (element.parents('form').find('.container-last-question').hasClass('d-none')) { return data_return; }

        $.each(parent_form.find('input[type="text"]:not([readonly])'), (index, element) => {
            if ($(element).hasClass('number-other-answer') || $(element).hasClass('content-sub-other')) {
                if (!$(element).parents('.sub-form-data').find('.checkbox-other-answer').is(":checked")) {
                    return;
                }
            }

            if (difficultProblemQuestion.htmlEscape($(element).val()).length === 0) {
                $(element).addClass('err-input');
                $(element).parents('.parent-errors').find('.error-questions').removeClass('d-none').text('This field is required.');
                data_return = false;
                return;
            }

            if ($(element).hasClass('number-other-answer') && parseInt($(element).val()).toString().length !== $(element).val().length) {
                $(element).addClass('err-input');
                $(element).parents('.parent-errors').find('.error-questions').removeClass('d-none').text('This field is integer.');
                data_return = false;
                return;
            }

            if (difficultProblemQuestion.htmlEscape($(element).val()).length > 255) {
                $(element).addClass('err-input');
                $(element).parents('.parent-errors').find('.error-questions').removeClass('d-none').text('Please enter no more than 255 characters.');
                data_return = false;
            }
        });

        return data_return;
    };

    modules.scrollToError = function (thisElement) {
        let topPos = thisElement[0].offsetTop;
        thisElement.focus();
        window.scrollTo(0, topPos - 170);
    };

    modules.scrollToStep = function (top, left) {
        $('html, body').animate({
            scrollTop: top,
            scrollLeft: left
        });
    };

    modules.validationAudio = function (element) {
        let types = /(\.|\/)(mp3)$/i;
        let data_return = true;

        $.each(element.parents('form').find('.validate-audio'), (index, audio) => {
            let file = $(audio).prop('files');
            let error = $(audio).parent().find('.error-audio');

            if ($(audio).parent().find('.file-name').length !== 0) {
                return;
            }

            if ($(audio).val() === '') {
                error.removeClass('d-none').text('This field is required.');
                data_return = false;
            } else if (!types.test(file[0].name)) {
                error.removeClass('d-none').text('File is invalid.');
                data_return = false;
            } else {
                error.addClass('d-none').text('');
            }
        });

        return data_return;
    };

    modules.validationTextArea = function (element) {
        let data_return = true;

        $.each(element.parents('form').find('textarea'), (index, text) => {
            let error = $(text).parent().parent().find('.error-textarea');

            if ($(text).val().length === 0) {
                error.removeClass('d-none').text('This field is required.');
                data_return = false;
            } else if ($(text).val().length > 255) {
                error.removeClass('d-none').text('Please enter no more than 255 characters.');
                data_return = false;
            } else {
                error.addClass('d-none').text('');
            }
        });

        return data_return;
    };

    modules.validationNumberAddQuestion = function (element) {
        let data_return = true;
        let regex = /^\d*$/;

        $.each(element.parents('form').find('.form-input .number-other-answer'), (index, number) => {
            let parent = $(number).parents('.form-input');
            let error = $(number).parent().parent().find('.error-questions');
            let value = $(number).val();

            if (!parent.find('.checkbox-other-answer').is(":checked")) {
                return;
            }

            if (value === '') {
                error.removeClass('d-none').text('This field is required.');
                $(number).addClass('err-input');
                data_return = false;
            } else if (!regex.test(value)) {
                error.removeClass('d-none').text('Please enter realistic thinking numbers.');
                $(number).addClass('err-input');
                data_return = false;
            } else if (value > 30) {
                error.removeClass('d-none').text('Please enter no more than 30.');
                $(number).addClass('err-input');
                data_return = false;
            } else {
                $(number).removeClass('err-input');
                error.addClass('d-none').text('');
            }
        });

        return data_return;
    }

    modules.validationFormAdv = function (parent) {
        let data_return = true;

        $.each(parent.find('.form-adv .select-question'), (index, element) => {
            if ($(element).val() === '') {
                data_return = false;
            }
        });

        return data_return;
    };

    modules.submitForm = function (element) {
        let data_return = { index: 0, value: true};
        let first_error = null;
        let parent_form = element.parents('form').find('.group-container-form');

        parent_form.find('.err-input').removeClass('err-input');
        parent_form.find('.error-questions').addClass('d-none').text('');

        if (!difficultProblemQuestion.validationInputForm(element)) {
            data_return.value = false;
            data_return.index = 1;
        }

        if (!difficultProblemQuestion.validationNumberAddQuestion(element)) {
            data_return.value = false;
            data_return.index = 1;
        }

        if (!difficultProblemQuestion.validationFormAdv(parent_form)) {
            data_return.value = false;
            toastr.error('Fail', 'The form to solve the problem must choose the source question.', options);
        }

        if (!difficultProblemQuestion.validationAudio(element)) {
            data_return.value = false;
            data_return.index = 3;
        }

        if (element.parents('form').find('.btn-add-question-form').length === 1) {
            toastr.error('Fail', 'Must have at least 1 question.', options)
            data_return.value = false;
        }

        switch(data_return.index) {
            case 1:
                first_error = element.parents('form').find('.group-container-form .err-input:first');
                difficultProblemQuestion.showErrorCollapse(first_error, 200);
                break;
            case 2:
                first_error = element.parents('form').find('.error-textarea:not(.d-none):first');
                difficultProblemQuestion.showErrorCollapse(first_error, 500);
                break;
            case 3:
                first_error = element.parents('form').find('.error-audio:not(.d-none):first');
                difficultProblemQuestion.showErrorCollapse(first_error, 200);
                break;
        }

        return data_return.value;
    };

    modules.trimParamForm = function (this_element) {
        let parent = this_element.parents('form');

        $.each(parent.find('textarea'), async (index, element) => {
            $(element).val($(element).val().trim());
        });

        $.each(parent.find('input'), async (index, element) => {
            $(element).val(difficultProblemQuestion.htmlEscape($(element).val().trim()));
        });
    }

    modules.showErrorCollapse = async function (element, top = 0) {
        let parent = element.parents('.group-form-submit.collapse');

        parent.collapse('show');
        setTimeout(() => {
            difficultProblemQuestion.scrollToStep(element.offset().top - top, element.offset().left);
            element.focus();
        }, 400);
    }

    return modules;
}(window.jQuery, window, document));

$(document).ready(function () {
    difficultProblemQuestion.saveOldDataTab();
    // Event checked form text
    $(document).on('change', '.container-difficult-problem-question .sub-form-data .checkbox-other-answer', function () {
        let checkbox = $(this).parents('.group-container-form').find('.checkbox-other-answer');
        checkbox.prop('checked', $(this).is(':checked'));

        $.each(checkbox, (i, element) => {
            if ($(element).is(":checked")) {
                $(element).parents('.sub-form-data').find('.is-show-sub-title').removeClass('d-none');
                $(element).parents('.sub-form-data').find('.content-sub-other').val('');
                $(element).parent().find('input[type="text"], input[type="number"]').prop('disabled', false);
            } else {
                $(element).parents('.sub-form-data').find('.is-show-sub-title').addClass('d-none');
                $(element).parent().find('.err-input').removeClass('err-input');
                $(element).parent().find('.error-questions').addClass('d-none').text('');
                $(element).parent().find('input[type="text"], input[type="number"]').val(null).prop('disabled', true);
            }
        });
    });

    // Event change number form text
    $(document).on('keyup', '.container-difficult-problem-question .sub-form-data .number-other-answer', function() {
        let number = $(this).parents('.group-container-form').find('.number-other-answer');

        $.each(number, (i, element) => {
            if ($(element).parents('.sub-form-data').find('.checkbox-other-answer').is(":checked")) {
                $(element).val($(this).val());
            }
        });
    });

    $(document).on('click', '.container-difficult-problem-question .container-question .btn-add-option-radio', function () {
        let sub_form = $(this).parents('.sub-form-data');
        let index_sub_form = sub_form.attr('data-index');
        let form_language = $(this).parents('.group-container-form').find(`.sub-form-data[data-index="${sub_form.attr('data-index')}"]`);

        $(this).parent().find('.btn-other-checkbox').attr('data-number-answer', parseInt($(this).attr('data-number-answer')));
        let number_answer = parseInt($(this).attr('data-number-answer')) + 1;
        let number_question = $(this).attr('data-number');

        $.each(form_language, (i, element) => {
            let sub_option = '';
            let language = $(element).parents('.group-form-submit').attr('data-language');
            let random_id = 'sub-' + Math.floor(Math.random() * 1000000);

            $(element).find('.btn-add-option-radio').attr('data-number-answer', number_answer);

            if (sub_form.find('.checkbox-follow-question-radio').is(":checked")) {
                sub_option = difficultProblemQuestion.subOptionForm(index_sub_form, number_question, number_answer - 1, language);
            }

            $(element).find('.group-option .default-option').append(
                `<div class="row-option answer-group">
                    <div class="parent-errors d-flex flex-column mb-2">
                        <div class="d-flex align-items-center mb-2">
                            <div class="circle mr-4"></div>
                            <input type="text" class="border-input-form input-form-question w-70" data-sub-id="${random_id}" name="question[${number_question}[${language}[option[${number_answer}[content]]]]]">
                            <i class="fa fa-times ml-2 pointer remove-element text-danger" aria-hidden="true"></i>
                        </div>
                        <span class="error-questions text-danger d-none ml-45"></span>
                    </div>
                    ${sub_option}
                </div>`
            );
        });
    });

    $(document).on('click', '.container-difficult-problem-question .container-question .btn-add-option-checkbox', function () {
        let sub_form = $(this).parents('.sub-form-data');
        let index_sub_form = sub_form.attr('data-index');
        let form_language = $(this).parents('.group-container-form').find(`.sub-form-data[data-index="${sub_form.attr('data-index')}"]`);

        $(this).parent().find('.btn-other-checkbox').attr('data-number-answer', parseInt($(this).attr('data-number-answer')));
        let number_answer = parseInt($(this).attr('data-number-answer')) + 1;

        $.each(form_language, (i, element) => {
            let id_form_adv = $(element).find('.form-checkbox').attr('data-id');
            let form_adv = $(element).parents('.group-form-submit').find(`.form-adv[data-id="${id_form_adv}"]`);
            let sub_option = '';
            let language = $(element).parents('.group-form-submit').attr('data-language');
            let random_id = 'sub-' + Math.floor(Math.random() * 1000000);

            $(element).find('.btn-add-option-checkbox').attr('data-number-answer', number_answer);

            if (sub_form.find('.checkbox-follow-question-radio').is(":checked")) {
                sub_option = difficultProblemQuestion.subOptionForm(index_sub_form, number_answer, number_answer - 1, language);
            }

            $(element).find('.group-option .default-option').append(
                `<div class="row-option answer-group">
                    <div class="parent-errors d-flex flex-column mb-2">
                        <div class="d-flex align-items-center mb-2">
                            <div class="square mr-4"></div>
                            <input type="text" class="border-input-form input-form-question w-70" data-sub-id="${random_id}" name="question[${index_sub_form}[${text_language[i + 1]}[option[${number_answer}[content]]]]]">
                            <i class="fa fa-times ml-2 pointer remove-element text-danger" aria-hidden="true"></i>
                        </div>
                        <span class="error-questions text-danger d-none ml-45"></span>
                    </div>
                    ${sub_option}
                </div>`
            );

            if (form_adv.length !== 0) {
                let count_content = form_adv.find('.option-question').length;
                let index_form_adv = form_adv.parents('.sub-form-data').attr('data-index');
                form_adv.find('.group-sub-question').append(difficultProblemQuestion.setAssessmentItem(index_form_adv, '', random_id, true, count_content, language));
            }
        });
    });

    $(document).on('click', '.container-difficult-problem-question .container-question .sub-option .btn-sub-add-option', function () {
        let number_question = $(this).attr('data-number');
        let number_answer = $(this).attr('data-number-answer');
        let index_sub_form = $(this).parents('.sub-form-data').attr('data-index');
        let parent_sub_form = $(this).parents('.sub-option');
        let sub_form_language = $(this).parents('.group-container-form').find(`.sub-option[data-sub-answer="${parent_sub_form.attr('data-sub-answer')}"]`);

        $.each(sub_form_language, (i, sub_option) => {
            let parent = $(sub_option);
            let number_option_child = parent.find('.option-child').length;
            let value = parent.find('input[type="radio"]:checked').val();
            let language = parent.parents('.group-form-submit').attr('data-language');

            switch(value) {
                case '1':
                    parent.find('.option-default-sub').append(difficultProblemQuestion.optionForm1('circle', number_question, number_answer, number_option_child, language));
                    break;
                case '2':
                    parent.find('.option-default-sub').append(difficultProblemQuestion.optionForm1('square', number_question, number_answer, number_option_child, language));
                    break;
            }

            $.each(parent.find('.option-child'), (index, element) => {
                $(element).attr('name', `question[${index_sub_form}[${language}[option[${number_answer}[option_child[${index}]]]]]]`)
            });
        });
    });

    // Update checked language form
    $(document).on('change', '.container-difficult-problem-question .sub-form-data .checkbox-follow-question-radio', function () {
        let parent = $(this).parents('.sub-form-data');
        let form_language = $(this).parents('.group-container-form').find(`.sub-form-data[data-index="${parent.attr('data-index')}"]`);
        let number_question = $(this).val()
        let index_sub_form = parent.attr('data-index');

        $.each(form_language, (i, element) => {
            let language = $(element).parents('.group-form-submit').attr('data-language');

            $(element).find('.checkbox-follow-question-radio').prop("checked", $(this).is(":checked"));

            if ($(this).is(":checked")) {
                $.each($(element).find('.group-option .row-option'), function (index, value) {
                    $(value).append(difficultProblemQuestion.subOptionForm(index_sub_form, number_question, index, language));
                })
            } else {
                $(element).find('.group-option .sub-option').remove();
            }
        });
    });

    $(document).on('change', '.container-difficult-problem-question .sub-form-data .sub-option input[type="radio"]', function () {
        let number_question = $(this).attr('data-number');
        let group_answer = $(this).parents('.sub-form-data').find('.row-option.answer-group');
        let number_answer = group_answer.index($(this).parents('.row-option.answer-group')) + 1;
        let parent_sub_form = $(this).parents('.sub-option');
        let sub_form_language = $(this).parents('.group-container-form').find(`.sub-option[data-sub-answer="${parent_sub_form.attr('data-sub-answer')}"]`);

        $.each(sub_form_language, (i, parent) => {
            $(parent).find('.option-default-sub').empty();
            $(parent).find('.other-option-sub').empty();
            let language = $(parent).parents('.group-form-submit').attr('data-language');

            $(parent).find(`input[type="radio"][value="${$(this).val()}"]`).prop('checked', true);
            switch($(this).val()) {
                case '1':
                    $(parent).find('.group-btn-action').removeClass('d-none');
                    $(parent).find('.option-default-sub').append(difficultProblemQuestion.optionForm1('circle', number_question, number_answer, 0, language));
                    $(parent).find('.group-btn-action').html(difficultProblemQuestion.btnSubOther('circle', 'btn-sub-add-option', 'btn-sub-other', number_question, number_answer));
                    break;
                case '2':
                    $(parent).find('.group-btn-action').removeClass('d-none');
                    $(parent).find('.option-default-sub').append(difficultProblemQuestion.optionForm1('square', number_question, number_answer, 0, language));
                    $(parent).find('.group-btn-action').html(difficultProblemQuestion.btnSubOther('square', 'btn-sub-add-option', 'btn-sub-other', number_question, number_answer));
                    break;
                case '3':
                    $(parent).find('.group-btn-action').addClass('d-none');
                    break;
            }
        });
    });

    // Change type question
    $(document).on('change', '.container-difficult-problem-question .sub-form-data .select-option-type', function () {
        let parent = $(this).parents('.sub-form-data');
        let temp_form = $('#question-form');
        let data_index = parent.attr('data-index');
        let form_language = $(this).parents('.group-container-form').find(`.sub-form-data[data-index="${parent.attr('data-index')}"]`);

        $.each(form_language, (i, element) => {
            let language = $(element).parents('.group-form-submit').attr('data-language');
            let content_question = $(element).find('.content-question').val().trim();

            switch($(this).val()) {
                case '1':
                    temp_form.html(difficultProblemQuestion.defaultSubFormQuestion(language, data_index));
                    $(element).html($('#question-form .sub-form-data').html());
                    temp_form.empty();
                    break;
                case '2':
                    temp_form.html(difficultProblemQuestion.optionFormCheckBox(language, data_index));
                    $(element).html($('#question-form .sub-form-data').html());
                    temp_form.empty();
                    break;
                case '3':
                    temp_form.html(difficultProblemQuestion.optionFormRadio(language, data_index));
                    $(element).html($('#question-form .sub-form-data').html());
                    temp_form.empty();
                    break;
            }

            $(element).find('.content-question').val(content_question);
        });
    });

    $(document).on('click', '.container-difficult-problem-question .container-question .btn-add-option-adv', function () {
        let index_option = $(this).attr('data-index');
        let type = $(this).attr('data-type');
        let number_question = $(this).attr('data-number-question');
        let checkbox = ($(this).parents('.sub-form-data').find('input[type="radio"]:checked').val() === '2') ? 'square' : 'circle';
        let parent_sub_form = $(this).parents('.sub-question');
        let sub_form_language = $(this).parents('.group-container-form').find(`.sub-question[data-sub-adv="${parent_sub_form.attr('data-sub-adv')}"]`);

        $.each(sub_form_language, (i, sub_question) => {
            let language = $(sub_question).parents('.group-form-submit').attr('data-language');
            let element = $(sub_question).find('.btn-add-option-adv');
            let length_option = parseInt($(element).attr('data-option')) + 1;

            $(element).attr('data-option', length_option);
            $(sub_question).find('.list-option-question .default-option').append(difficultProblemQuestion.optionForm2(checkbox, number_question, index_option, length_option, type, language));
        });

    });

    $(document).on('change', '.container-difficult-problem-question .sub-form-data .form-adv-radio input[type="radio"]', function () {
        let number_question = $(this).attr('data-number');
        let form_language = $(this).parents('.group-container-form').find(`.sub-form-data[data-index="${$(this).parents('.sub-form-data').attr('data-index')}"]`);

        $.each(form_language, (i, element_parent) => {
            let language = $(element_parent).parents('.group-form-submit').attr('data-language');

            $(element_parent).find('.default-option').empty();
            $(element_parent).find('.other-option').empty();
            $(element_parent).find(`.form-adv-radio input[type="radio"][value="${$(this).val()}"]`).prop('checked', true);

            switch($(this).val()) {
                case '1':
                    $(element_parent).find('.group-sub-question').removeClass('d-none');
                    $.each($(element_parent).find('.list-option-question .default-option'), function (i, element) {
                        $(element).append(difficultProblemQuestion.optionForm2('circle', number_question, $(element).attr('data-index'), 0, $(element).attr('data-type'), language, true));
                    });
                    $.each($(element_parent).find('.group-btn-action'), function (i, element) {
                        let option = $(element).find('.btn-add-option-adv');
                        let other = $(element).find('.btn-other-adv');
                        $(element).html(difficultProblemQuestion.btnSubOther2('circle', 'btn-add-option-adv', 'btn-other-adv', option, other));
                    });
                    break;
                case '2':
                    $(element_parent).find('.group-sub-question').removeClass('d-none');
                    $.each($(element_parent).find('.list-option-question .default-option'), function (i, element) {
                        $(element).append(difficultProblemQuestion.optionForm2('square', number_question, $(element).attr('data-index'), 0, $(element).attr('data-type'), language, true));
                    });
                    $.each($(element_parent).find('.group-btn-action'), function (i, element) {
                        let option = $(element).find('.btn-add-option-adv');
                        let other = $(element).find('.btn-other-adv');
                        $(element).html(difficultProblemQuestion.btnSubOther2('square', 'btn-add-option-adv', 'btn-other-adv', option, other));
                    });
                    break;
                case '3':
                    $(element_parent).find('.group-sub-question').addClass('d-none');
                    break;
            }
        });
    });

    // Event key up input answer form checkbox and radio
    $(document).on('keyup', '.container-difficult-problem-question .form-checkbox input.input-form-question[data-sub-id], .container-difficult-problem-question .form-radio input.input-form-question[data-sub-id]', function() {
        let title = $(`.container-difficult-problem-question .form-adv span[data-sub-id="${$(this).attr('data-sub-id')}"]`);
        let parent = title.parents('.option-question');

        if (title.length !== 0) {
            if (difficultProblemQuestion.htmlEscape($(this).val()).length === 0) {
                parent.addClass('d-none');
                parent.find('input[type="text"]').prop('disabled', true);
            } else {
                parent.removeClass('d-none');
                parent.find('input[type="text"]').prop('disabled', false);
                title.text(difficultProblemQuestion.htmlEscape($(this).val()));
                title.parents('.option-question').find('.content-hidden').val(difficultProblemQuestion.htmlEscape($(this).val()));
            }
        }
    });

    // Submit form data
    $(document).on('click', '.container-difficult-problem-question .btn-submit-form', async function () {
        if (difficultProblemQuestion.submitForm($(this))) {
            await difficultProblemQuestion.trimParamForm($(this));
            await $(this).parents('form').submit();
        }
    });

    $(document).on('click', '.container-difficult-problem-question .form-input .event-click-checkbox', function () {
        let checkbox = $(this).parents('.form-input').find('.checkbox-other-answer');

        checkbox.prop('checked', !checkbox.is(":checked")).change();
    });

    // Reset data when change tab
    $(document).on('shown.bs.tab', '.container-difficult-problem-question .nav-tabs .nav-item a[data-toggle="tab"]', function () {
        $('#' + difficultProblemQuestion.thisTabItem).html(difficultProblemQuestion.htmlTab);
        difficultProblemQuestion.saveOldDataTab();
    });

    $(document).on('change', '.container-difficult-problem-question .form-sub input[type="file"]', function () {
        $(this).parent().find('.file-name').remove();
        $(this).parent().find('.error-audio').addClass('d-none').text('');
    });

    // Event checked form time
    $(document).on('change', '.container-difficult-problem-question .sub-form-data .form-schedule-activities input[type="checkbox"]', function () {
        let checkbox_time = $(this).parents('.group-container-form').find(`.form-schedule-activities input[value="${$(this).val()}"]`);

        $.each(checkbox_time, (i, element) => {
            $(element).prop("checked", $(this).is(":checked"));
        });
    });

    $(document).on('click', '.container-difficult-problem-question .sub-form-data .remove-element', function () {
        let answer = $(this).parents('.group-option');

        if (answer.length === 1) {
            let input_element = $(this).parent().find('input');
            let array_name = input_element.attr('name').split("[");

            $.each(list_language, (i, lan) => {
                array_name[2] = lan;
                let this_element = $(`.tab-pane.active .sub-form-data input[name="${array_name.join('[')}"]`).parents('.row-option');
                let list_question = this_element.parents('.group-answer-question');
                let index_sub_form = this_element.parents('.sub-form-data').attr('data-index');
                let sub_id = this_element.find('.input-form-question').attr('data-sub-id');
                this_element.remove();

                list_question.find('.btn-add-option-radio').attr('data-number-answer', list_question.find('.input-form-question').length);
                list_question.find('.btn-add-option-checkbox').attr('data-number-answer', list_question.find('.input-form-question').length);
                $.each(list_question.find('.row-option'), (index_question, question) => {
                    $(question).find('.input-form-question').attr('name', `question[${index_sub_form}[${lan}[option[${index_question + 1}[content]]]]]`);
                    $(question).find('input[type="radio"]').attr('name', `question[${index_sub_form}[${lan}[option[${index_question + 1}[type_child]]]]]]`);

                    $.each($(question).find('.option-default-sub input'), (j, sub_answer) => {
                        $(sub_answer).attr('name', `question[${index_sub_form}[${lan}[option[${index_question + 1}[option_child[${j}]]]]]]`);
                    });
                });

                let element_select = $(`.tab-pane.active .form-adv span[data-sub-id="${sub_id}"]`);
                let group_question_adv = element_select.parents('.group-sub-question');
                element_select.parents('.option-question').remove();
                $.each(group_question_adv.find('.option-question'), (index_question, element) => {
                    $.each($(element).find('input'), (j, sub_question) => {
                        let old_name = $(sub_question).attr('name').split("[");
                        old_name[4] = index_question;
                        $(sub_question).attr('name', old_name.join('['));
                    });
                });
            });
        }
    });

    $(document).on('click', '.container-difficult-problem-question .sub-form-data .remove-child-element', function () {
        let answer = $(this).parents('.group-option');

        if (answer.length === 1) {
            let input_element = $(this).parent().find('input');
            let array_name = input_element.attr('name').split("[");

            $.each(list_language, (i, lan) => {
                array_name[2] = lan;
                let this_element = $(`.tab-pane.active .sub-form-data .sub-option input[name="${array_name.join('[')}"]`).parents('.parent-errors');
                let list_question = this_element.parents('.sub-option');
                let index_sub_form = this_element.parents('.sub-form-data').attr('data-index');
                let number_answer = array_name[4];
                this_element.remove();

                $.each(list_question.find('.option-child'), (index_question, question) => {
                    $(question).attr('name', `question[${index_sub_form}[${lan}[option[${number_answer}[option_child[${index_question}]]]]]]`);
                });
            });
        }
    });

    $(document).on('click', '.container-difficult-problem-question .sub-form-data .remove-adv-element', function () {
        let answer = $(this).parents('.parent-answer-adv');

        if (answer.length === 1) {
            let input_element = $(this).parent().find('input');
            let array_name = input_element.attr('name').split("[");

            $.each(list_language, (i, lan) => {
                array_name[2] = lan;
                let this_element = $(`.tab-pane.active .sub-form-data .sub-question input[name="${array_name.join('[')}"]`).parents('.parent-answer-adv');
                let list_question = this_element.parents('.sub-question');
                let index_sub_form = this_element.parents('.sub-form-data').attr('data-index');
                let number_answer = array_name[4];
                let type = array_name[5];
                this_element.remove();

                list_question.find('.btn-add-option-adv').attr('data-option', list_question.find('input').length - 1);
                $.each(list_question.find('input'), (index_question, question) => {
                    $(question).attr('name', `question[${index_sub_form}[${lan}[option[${number_answer}[${type}[${index_question}]]]]]]`);
                });
            });
        }
    });
});
