export function compareTwoDates(startDateId, endDateId) {
    let startDate = $(startDateId).val();
    let endDate = $(endDateId).val();
    let html = "<p class='error' id='invalid_date'>Date created to must be greater than date created from.</p>"

    if (startDate !== '' && endDate !== '') {
        if (html) {
            $('#invalid_date').remove();
        }
        if (Date.parse(startDate) > Date.parse(endDate)) {
            // $(endDateId).val('');
            $(endDateId).after(html);
        }
    }
    return false;
}

export function togglePassword(iconClass, inputId) {
    $(iconClass).toggleClass("fa-eye fa-eye-slash");
    let inputElement = $(inputId)[0];
    if (inputElement.type === "password") {
        inputElement.type = "text";
    } else {
        inputElement.type = "password";
    }
}

export function previewImage(inputFile, inputPreview) {
    if (inputFile.files && inputFile.files[0]) {
        let reader = new FileReader();
        reader.onload = function (e) {
            $(inputPreview).attr('src', e.target.result);
        };
        reader.readAsDataURL(inputFile.files[0]); // convert to base64 string
    }
}

export function triggerInputFile(input) {
    $(input).trigger('click');
}

export function handleActiveRecordValidation(input, errorElement) {

    if ($(errorElement).text() !== '') {
        $(input).addClass('error')
    }

    $(input).on('keyup', function () {
        $(errorElement).text('')
        $(this).removeClass('error')
    })

    $(input).on('blur', function () {
        if ($(input + '-error')) {
            $(errorElement).text('')
        }
    })
}

$(document).on("turbolinks:load", function () {

    // Disable to enter date using keyboard
    $('#from_date, #to_date').on('keydown', false);

    // validate for input date into form search
    $("#from_date, #to_date").on('change', function () {
        $('form .btn-success-custom').prop("disabled", false);
        compareTwoDates('#from_date', '#to_date');
    });

    // add rule validate for password field
    $.validator.addMethod("validPassword", function (value, element) {
        return this.optional(element) ||
            /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\W_]).*$/.test(value);
    }, "The password must contain at least one Latin lowercase, one Latin uppercase, one digit and one special character");

    // add rule validate for username field
    $.validator.addMethod("validUsername", function (value, element) {
        return this.optional(element) || /^[a-zA-Z0-9]+$/.test(value);
    }, "Latin letters and numbers only please");

    // add rule validate for select-option tag
    $.validator.addMethod("selectRequired", function (value, element, arg) {
        return arg !== element.value;
    }, "Please select an item in the list.");

    // trigger input file upload
    $('.img_preview').on('click', function () {
        triggerInputFile('.upload')
    })

    window.onbeforeunload = function (event) {
        $('.gif-loader').show();
    };

    let loadingIcon = $('.gif-loader');

    // hide icon loading after download csv
    $('#phq9_download_csv').validate({
        submitHandler: function (form) {
            $(".submit").attr("disabled", true);
            loadingIcon.remove();
            form.submit();
        }
    })

    $('#form-export-csv').validate({
        submitHandler: function (form) {
            $(".submit").attr("disabled", true);
            loadingIcon.remove();
            form.submit();
        }
    })
    // end

    // add datepicker into input
    $('.input-group.date').datepicker({
        todayHighlight: false,
        autoclose: true,
        format: 'yyyy/mm/dd'
    })

    $(document).on("submit", "form", function(){
        if ($('#invalid_date').length) return false;
    });
})