const MAX_LENGTH = 255

let product = (function () {
    let modules = {};

    modules.readURL = function (input) {
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            reader.onload = function (e) {
                $('#img-preview').attr('src', e.target.result);
            };
            reader.readAsDataURL(input.files[0]); // convert to base64 string
        }
    };

    modules.destroyProduct = function () {
        let id = $('#product-id').val();
        if ( !($.isNumeric(id)) || (id < 0) ) {
            id = 0;
        }
        $.ajax({
            type: 'DELETE',
            url: '/management/master/products/' + id,
            data: {
                id: id
            }
        });
    };

    return modules;
}(window.jQuery, window, document));

$(document).on("turbolinks:load", function () {
    let form_update = '#form_update_product'
    let form_create = '#form_create_product'
    $('#img-preview').click(function () {
        $('#img-product').trigger('click');
    })

    $('#img-product').on('change', function () {
        previewImage($(this)[0], $('.img-preview'));
        if ($(this).valid()) {
            $("#img-product .error").removeClass('error')
        }
    })

    $('.checkbox-language-support').click(function () {
        if ($(this).prop("checked") === true) {
            $('.' + $(this).data('item')).prop("readonly", false);
        } else if ($(this).prop("checked") === false) {
            $('.' + $(this).data('item')).prop("readonly", true);
        }
    });

    $('input[type=checkbox]').on('click', function () {
        let checkbox_language_support_checked = $(".checkbox-language-support:checked")
        if (checkbox_language_support_checked.length === 1) {
            $('#' + checkbox_language_support_checked.get(0).id).prop('disabled', true);
        } else {
            $('input[type=checkbox]').prop('disabled', false);
        }
    })

    $('.input-lang').on('keyup', function () {
        $('.btn-submit').prop("disabled", false)
    })

    validate_form(form_update)
    validate_form(form_create)
    // validate for input elements into form create/edit activity types
    function validate_form(form_id) {
        $(form_id).validate({
            onfocusout: function (element) {
                this.element(element);
            },
            normalizer: function (value) {
                return $.trim(value);
            },
            errorElement: "p",
            rules: {
                'product_name[]': {
                    required: true,
                    maxlength: 50
                },
                'product_type': {
                    required: true,
                },
                'supplier[]': {
                    required: true,
                    maxlength: 100
                },
                'price_product': {
                    required: true,
                    number: true,
                    min: 0,
                    max: 100000000000
                },
                'product_image': {
                    extension: "png|jpg|jpeg|gif",
                    maxsize: 5242880
                },
                'product_url': {
                    required: true,
                    url: true
                },
            },
            messages: {
                "product_image": {
                    extension: "Image must be a PNG or JPG or JPEG or GIF",
                    maxsize: "File size must not exceed 5MB"
                },
                'price_product': {
                    min: "The price is not less than 0.",
                    max: "The price you enter is too large."
                }
            },
            submitHandler: function (form) {
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }

    $(document).on('click', '.btn-confirm-delete-product', function () {
        product.destroyProduct();
    });
});