let page = 0;
let lastPage = '';
var Conversations = (function () {
    let modules = {};

    modules.getDataMessages = function (id, noData) {
        $.ajax({
            type: 'GET',
            url: '/management/data/conversations/message/' + id,
            data: {
                id: id,
                page: page = page + 1,
            },
            dataType: 'json',
            success: function (response) {
                let messages = $('#detailModal'+id).find('.messages');
                lastPage = response.data.last_page
                if (response.data.messages.length > 0) {
                    $.each(response.data.messages, function (key, value) {
                        let className = value.sender_type === 'user' ? 'right' : 'left';
                        let srcAvatar = value.sender_type === 'user' ? $('#user-avatar-' + id).attr('src') : $('#expert-avatar-' + id).attr('src');
                        let content = value.content;
                        let displayContent = `<div class="text">`+content+`</div>`
                        if (value.message_type === 'file') {
                            displayContent = '';
                            content = '';
                            $.each(value.message_file_url, function (index, files) {
                                content = files.url;
                                if (files.file_type.indexOf('image') >= 0) {
                                    displayContent += `
                                        <div class="m10b">
                                            <a href="`+content+`" download='true' target="_blank">
                                                <img src="`+content+`">
                                            </a>
                                        </div>
                                        `
                                } else if (files.file_type.indexOf('video') >= 0) {
                                    displayContent += `
                                        <a href="`+content+`" download='true' target="_blank">
                                            <video controls>
                                                <source src="`+content+`" type="video/mp4">
                                            </video>
                                        </a>
                                        `
                                } else {
                                    displayContent += `
                                        <a href="`+content+`" download='true' target="_blank">
                                            <i class="fas fa-file-alt" style="font-size: 20px"></i> `+files.file_name+`
                                        </a>
                                        `
                                }
                            });
                        }
                        messages.append(`
                            <li class="message `+className+` appeared" data-id="`+value.id+`">
                              <div class="avatar">
                                <img src="`+srcAvatar+`" class="img-circle">
                              </div>
                              <div class="text_wrapper">
                                `+displayContent+`
                              </div>
                              <p>`+value.created_time+`</p>
                            </li>`)
                    })

                } else if (noData) {
                    messages.append(`<li class="text-center">
                        <p class="text-danger">No data</p>
                    </li>`)
                }
            }
        });
    }

    return modules;
}(window.jQuery, window, document));

$(document).on("turbolinks:load", function () {
    $(document).off('click', '.conversation-detail');
    $(document).on('click', '.conversation-detail', function () {
        Conversations.getDataMessages($(this).attr("data-id"), true);
    });

    $('.chat-box').scroll(function () {
        //If scroll chat box in bottom and current page is not last page
        if (($(this).scrollTop() + $(this).height() === Math.round($(this).find('.messages').height() + 40)) && lastPage === false ) {
            Conversations.getDataMessages($(this).find('.conversation-id').val(), false);
        }
    });

    $(document).on('hidden.bs.modal', '.modal-detail', function () {
        $(this).find('.messages').html('');
        page = 0;
    });
});
