let Permission = (function () {
    let modules = {};

    modules.checkParent = function (obj) {
        let checked = false;
        $.each(obj.parent().parent().find('input[type=checkbox]'), function (key, value) {
            if ($(value).prop('checked')) {
                checked = true;
                return false;
            }
        });
        obj.parent().parent().siblings('.role-style').find('input[type=checkbox]').prop('checked', checked);
    }

    modules.validate_form = function (form_id) {
        $(form_id).validate({
            onfocusout: function (element) {
                this.element(element);
            },
            errorPlacement: function(error, element) {
                if (element.attr('name') === 'permission_id[]') {
                    error.insertAfter("#permission_list");
                } else {
                    error.insertAfter(element);
                }
            },
            errorElement: "p",
            normalizer: function (value) {
                return $.trim(value);
            },
            rules: {
                'name': {
                    required: true,
                    maxlength: 50,
                },
                'permission_id[]': {
                    required: true,
                },
            },
            messages: {
                'permission_id[]': {
                    required: 'Checkbox is required',
                },
            },
            submitHandler: function (form) {
                $('.submit-create-permission').attr('disabled', true);
                form.submit();
            }
        });
    }

    return modules
}(window.jQuery, window, document));

$(document).on("turbolinks:load", function () {
    $('.checkbox-event').on('click', function () {
        $(this).parent().parent().siblings('.content-outline-style').find('input[type=checkbox]').prop('checked', $(this).prop('checked'));
    });

    if ($('.checkbox-sub-event').is(':checked')) {
        Permission.checkParent($(document).find($(".checkbox-sub-event:checked")));
    }

    $('label:not(.enable-label)').on('click', function () {
        $(this).siblings('input[type=checkbox]').click();
    })

    $('.checkbox-sub-event').on('click', function () {
        Permission.checkParent($(this));
    })

    $('input[name=name]').on('focusout', function () {
        $('#flash-danger').attr('hidden', true);
    })

    Permission.validate_form('#form_create_permission');
    Permission.validate_form('#form_update_permission');

    $('.btn-disable').on('click', function () {
        $('.status-classification').removeClass('status-active');
        $('.status-classification').text('Disable');
        $('input[name=status]').val('Disable');
        $('.status-classification').addClass('status-disable');
        $('.btn-show-modal-disable').attr('hidden', true);
        $('.btn-show-modal-active').attr('hidden', false);
    });

    $('.btn-active').on('click', function () {
        $('.status-classification').removeClass('status-disable');
        $('.status-classification').text('Active');
        $('input[name=status]').val('Active');
        $('.status-classification').addClass('status-active');
        $('.btn-show-modal-disable').attr('hidden', false);
        $('.btn-show-modal-active').attr('hidden', true);
    });
})
