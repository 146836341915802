import select2 from 'select2';
// import 'select2/dist/css/select2.css';
import toastr from "./toastr.min";
import 'bootstrap';

let options = {
    closeButton: true,
    debug: false,
    positionClass: "toast-top-right",
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "5000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    progressBar: true
}

const MEMBER_TYPE_ALL = 'all'
const TYPE_MEMBER = '1'
const TYPE_NONMEMBER = '2'
const TYPE_LATE = '3'

let settingUser = (function () {
    let modules = {};
    modules.isDisable = false;
    modules.isDisableForm = false;
    modules.ward_old_0 = [];
    modules.ward_old_1 = [];
    modules.ward_old_2 = [];

    modules.init = function () {
        let container_trial = $('.setting-user-container');

        if (container_trial.length && !JSON.parse(container_trial.find('input[name="clinical_trial"]').prop('checked'))) {
            settingUser.isDisable = true;
        }

        $(".select-city-0").select2({
            templateResult: settingUser.formatStateCountry,
            templateSelection: settingUser.formatStateCountry,
            dropdownParent: $('.form-select-province-container-0'),
            selectionCssClass: 'form-select-country',
            width: '100%',
            dropdownAutoWidth: true,
            containerCss: 'form-select-province-container',
            placeholder: "Please choose",
            closeOnSelect: false,
        });

        $(".select-city-1").select2({
            templateResult: settingUser.formatStateCountry,
            templateSelection: settingUser.formatStateCountry,
            dropdownParent: $('.form-select-province-container'),
            selectionCssClass: 'form-select-country',
            width: '100%',
            dropdownAutoWidth: true,
            containerCss: 'form-select-province-container',
            placeholder: "Please choose",
            closeOnSelect: false,
        });

        $(".select-city-2").select2({
            templateResult: settingUser.formatStateCountry,
            templateSelection: settingUser.formatStateCountry,
            dropdownParent: $('.form-select-province-container-2'),
            selectionCssClass: 'form-select-country',
            width: '100%',
            dropdownAutoWidth: true,
            containerCss: 'form-select-province-container',
            placeholder: "Please choose",
            closeOnSelect: false,
        });

        $(".select-district-0").select2({
            templateResult: settingUser.formatStateCountry,
            templateSelection: settingUser.formatStateCountry,
            dropdownParent: $('.form-select-district-container-0'),
            selectionCssClass: 'form-select-country',
            width: '100%',
            dropdownAutoWidth: true,
            containerCss: 'form-select-district-container',
            placeholder: "Please choose",
            closeOnSelect: false,
        });

        $(".select-district-1").select2({
            templateResult: settingUser.formatStateCountry,
            templateSelection: settingUser.formatStateCountry,
            dropdownParent: $('.form-select-district-container'),
            selectionCssClass: 'form-select-country',
            width: '100%',
            dropdownAutoWidth: true,
            containerCss: 'form-select-district-container',
            placeholder: "Please choose",
            closeOnSelect: false,
        });

        $(".select-district-2").select2({
            templateResult: settingUser.formatStateCountry,
            templateSelection: settingUser.formatStateCountry,
            dropdownParent: $('.form-select-district-container-2'),
            selectionCssClass: 'form-select-country',
            width: '100%',
            dropdownAutoWidth: true,
            containerCss: 'form-select-district-container',
            placeholder: "Please choose",
            closeOnSelect: false,
        });

        $(".select-ward-0").select2({
            templateResult: settingUser.formatStateCountry,
            templateSelection: settingUser.formatStateCountry,
            dropdownParent: $('.form-select-ward-container-0'),
            selectionCssClass: 'form-select-country',
            width: '100%',
            dropdownAutoWidth: true,
            containerCss: 'form-select-ward-container',
            placeholder: "Please choose",
            closeOnSelect: false,
        });

        $(".select-ward-1").select2({
            templateResult: settingUser.formatStateCountry,
            templateSelection: settingUser.formatStateCountry,
            dropdownParent: $('.form-select-ward-container'),
            selectionCssClass: 'form-select-country',
            width: '100%',
            dropdownAutoWidth: true,
            containerCss: 'form-select-ward-container',
            placeholder: "Please choose",
            closeOnSelect: false,
        });

        $(".select-ward-2").select2({
            templateResult: settingUser.formatStateCountry,
            templateSelection: settingUser.formatStateCountry,
            dropdownParent: $('.form-select-ward-container-2'),
            selectionCssClass: 'form-select-country',
            width: '100%',
            dropdownAutoWidth: true,
            containerCss: 'form-select-ward-container',
            placeholder: "Please choose",
            closeOnSelect: false,
        });

        if (container_trial.length) {
            settingUser.changeMember($('.setting-user-container .select-member'));
        }
    };

    modules.formatStateCountry = function (state) {
        if (settingUser.isDisable) {
            return false;
        }
        if (!state.id) return state.text;
        const image_path = $(state.element).attr('data-path');
        const imageHtml = image_path ? '<div class="flag-icon d-flex justify-content-center align-items-center">' +
            '<img src="' + image_path + '" class="img-flag"  alt=""/>' +
            '</div>' : '';

        return $(
            '<div class="full-width d-flex align-items-center">' +
            imageHtml +
            '<div class="font-weight-500 font-size-16 ml-2 center-select2">' + state.text + '</div>' +
            '</div>'
        );
    };

    modules.changeDistrict = function (parent, city_id, selected = [], selected_ward = [], is_done = true) {
        if (settingUser.isDisable) {
            return false;
        }

        let district_old = parent.find('.select-district').val().map((v) => parseInt(v));
        parent.find('.select-district').empty();
        if ($('#disable-onchange').val() === '') {
            if (parent.hasClass('table-list-district-1')) {
                settingUser.ward_old_1 = parent.find('.select-ward').val().map((v) => parseInt(v));
            } else if (parent.hasClass('table-list-district-2')) {
                settingUser.ward_old_2 = parent.find('.select-ward').val().map((v) => parseInt(v));
            } else if (parent.hasClass('table-list-district-0')) {
                settingUser.ward_old_0 = parent.find('.select-ward').val().map((v) => parseInt(v));
            }
            parent.find('.select-ward').empty();
        }
        if (city_id.length === 0) {
            return false;
        }
        settingUser.loading();

        $.ajax({
            type: 'GET',
            url: '/district',
            data: {city_id: city_id},
            dataType: 'json',
            success: function (res) {
                let option = '';
                $.each(res.district, function (i, index) {
                    option += `<option value="${index.code}" ${district_old.includes(index.code) ? 'selected' : ''}>${index.name}</option>`;
                });
                parent.find('.select-district').append(option);

                if (selected.length !== 0) {
                    parent.find(".select-district").val(selected);
                }

                parent.find(".select-district").trigger('change');
                if ($('#disable-onchange').val() === '') {
                    settingUser.loading(false);
                } else {
                    settingUser.changeWard(parent, selected, selected_ward, is_done);
                }
            },
            error: function (err) {
                console.error(err);
                settingUser.loading(false);
            }
        });
    };

    modules.changeWard = function (parent, district_id, selected = [], is_done = true) {
        if (settingUser.isDisable) {
            return false;
        }

        if (parent.find('.select-ward').val().length !== 0) {
            if (parent.hasClass('table-list-district-1')) {
                settingUser.ward_old_1 = parent.find('.select-ward').val().map((v) => parseInt(v));
            } else if (parent.hasClass('table-list-district-2')) {
                settingUser.ward_old_2 = parent.find('.select-ward').val().map((v) => parseInt(v));
            } else if (parent.hasClass('table-list-district-0')) {
                settingUser.ward_old_0 = parent.find('.select-ward').val().map((v) => parseInt(v));
            }
        }
        parent.find('.select-ward').empty();
        if (district_id.length === 0) {
            return false;
        }
        settingUser.loading();

        if (district_id.length > 0) {
            $.ajax({
                type: 'post',
                url: '/ward_trial',
                data: {district_id: district_id},
                dataType: 'json',
                success: function (res) {
                    let option = '';
                    let array_ward = [];
                    if (parent.hasClass('table-list-district-1')) {
                        array_ward = settingUser.ward_old_1;
                    } else if (parent.hasClass('table-list-district-2')) {
                        array_ward = settingUser.ward_old_2;
                    } else if (parent.hasClass('table-list-district-0')) {
                        array_ward = settingUser.ward_old_0;
                    }
                    parent.find('.select-ward').empty();
                    $.each(res.ward, function (i, index) {
                        option += `<option value="${index.code}" ${array_ward.includes(index.code) ? 'selected' : ''}>${index.name}</option>`;
                    });
                    parent.find('.select-ward').append(option);

                    if (selected.length !== 0) {
                        parent.find(".select-ward").val(selected);
                    }

                    parent.find('.select-ward').trigger('change');
                    if (is_done) {
                        $('#disable-onchange').val('');
                        if ($('#disable-onchange').val() === '') {
                            settingUser.loading(false);
                        }
                    }
                },
                error: function (err) {
                    console.error(err);
                    settingUser.loading(false);
                }
            });
        }
    };

    modules.hiddenElement = function (element) {
        element.forEach((item) => {
            if(!item.hasClass('d-none')) {
                item.addClass('d-none');
            }
        })
    }

    modules.changeMember = function (element) {
        if (settingUser.isDisable) {
            return false;
        }
        let type_member = $('.setting-user-container .select-member');
        let parent = null;
        let element_member_all = $('.hidden-change-member');
        let intervention_zone = $('#intervention-zone');
        let intervention_last = $('#intervention-last');
        let import_zone = $('#import-zone');
        let element_nonmember = $('#hidden-change-nonmember');

        switch (type_member.val()) {
            case '1':
                parent = $('.setting-user-container .table-list-district-1');
                intervention_zone.removeClass('d-none');
                modules.hiddenElement([element_nonmember, intervention_last, import_zone]);
            break;
            case '2':
                parent = $('.setting-user-container .table-list-district-0');
                element_nonmember.removeClass('d-none');
                modules.hiddenElement([intervention_zone, intervention_last, import_zone]);
            break;
            case '3':
                parent = $('.setting-user-container .table-list-district-2');
                intervention_last.removeClass('d-none');
                modules.hiddenElement([element_nonmember, intervention_zone, import_zone]);
                break;
            default:
                parent = $('.setting-user-container .table-list-district-1');
                element_member_all.removeClass('d-none');
        }

        settingUser.loading();
        $('#disable-onchange').val('true');

        if (element.val().length > 0) {
            $.ajax({
                type: 'GET',
                url: '/trial/setting_user/setting_member',
                data: {type_member: element.val()},
                dataType: 'json',
                success: function (res) {
                    let record = res.data;
                    if (record !== null && (record || record.length)) {
                        if (type_member.val() === MEMBER_TYPE_ALL) {
                            if (record[0] === undefined) {
                                settingUser.loading(false);
                                $('#disable-onchange').val('');
                                return false;
                            }
                            parent.find(".select-city").val(record[0].city.split(",")).trigger('change');
                            settingUser.changeDistrict(parent, record[0].city.split(","), record[0].district.split(","), record[0].ward.split(","), !record[1]);

                            let parent_2 = $('.setting-user-container .table-list-district-2');
                            if (record[2]) {
                                $('#disable-onchange').val('true');
                                parent_2.find(".select-city").val(record[2].city.split(",")).trigger('change');
                                settingUser.changeDistrict(parent_2, record[2].city.split(","), record[2].district.split(","), record[2].ward.split(","));
                            } else {
                                parent_2.find(".select-city").val('').trigger('change');
                                parent_2.find(".select-district").val('').trigger('change');
                                parent_2.find(".select-ward").val('').trigger('change');
                            }

                            let parent_3 = $('.setting-user-container .table-list-district-0');
                            if (record[1]) {
                                parent_3.find(".select-city").val(record[1].city.split(",")).trigger('change');
                                settingUser.changeDistrict(parent_3, record[1].city.split(","), record[1].district.split(","), record[1].ward.split(","));
                            } else {
                                parent_3.find(".select-city").val('').trigger('change');
                                parent_3.find(".select-district").val('').trigger('change');
                                parent_3.find(".select-ward").val('').trigger('change');
                            }
                        } else {
                            parent.find(".select-city").val(record.city.split(",")).trigger('change');
                            settingUser.changeDistrict(parent, record.city.split(","), record.district.split(","), record.ward.split(","));
                        }
                    } else {
                        parent.find(".select-city").val('').trigger('change');
                        parent.find(".select-district").val('').trigger('change');
                        parent.find(".select-ward").val('').trigger('change');

                        if (type_member.val() === '1') {
                        }

                        $('#disable-onchange').val('');
                        settingUser.loading(false);
                    }
                    $('.setting-user-container .table-show-trial-user').empty();
                },
                error: function (err) {
                    console.error(err);
                    settingUser.loading(false);
                }
            });
        }
    };

    modules.checkboxTrial = function (element) {
        let value = false;
        if (element.checked) {
            value = true;
        }
        settingUser.loading();

        $.ajax({
            type: 'POST',
            url: '/trial/setting_user/set_trial',
            data: {clinical_trial: value},
            dataType: 'json',
            success: function (res) {
                let phq9 = $('.setting-user-container input[name="phq9_assessment"]');
                if (value) {
                    settingUser.isDisable = false;
                    settingUser.disableForm(false);

                    if (phq9.attr('data-old') !== phq9.prop('checked')) {
                        phq9.prop('checked', JSON.parse(phq9.attr('data-old').toLowerCase()));
                    }
                } else {
                    settingUser.isDisable = true;
                    settingUser.disableForm();
                    $('.setting-user-container .table-show-trial-user').empty();
                }
                settingUser.loading(false);
                toastr.success('Success', 'Set trial success.', options);
            },
            error: function (err) {
                toastr.error('Fail', 'An error occurred', options)
                console.error(err);
            }
        });
    };

    modules.setScorePhq9 = function (data) {
        if(isNaN(data.score_phq9) || !data.score_phq9) {
            toastr.error('Fail', 'Invalid High threshold PHQ9.', options)
            return;
        }
        $.ajax({
            type: 'POST',
            url: '/trial/setting_user/set_score_phq9',
            data,
            dataType: 'json',
            beforeSend: function () {
                settingUser.loading();
            },
            success: function (res) {
                toastr.success('Success', 'Set phq9 assessment success.', options);
            },
            error: function (err) {
                toastr.error('Fail', err.responseJSON.message, options)
            },
            complete: function () {
                settingUser.loading(false);
            }
        });
    };

    modules.setScorePhq9AllowUseSystem = function (data) {
        if(isNaN(data.score_phq9_compare) || !data.score_phq9_compare) {
            toastr.error('Fail', 'Invalid Score PHQ9 Allow Use System.', options)
            return;
        }
        $.ajax({
            type: 'POST',
            url: '/trial/setting_user/set_score_phq9_allow_use_system',
            data,
            dataType: 'json',
            beforeSend: function () {
                settingUser.loading();
            },
            success: function (res) {
                toastr.success('Success', 'Set Score PHQ9 Allow Use System Success.', options);
            },
            error: function (err) {
                toastr.error('Fail', err.responseJSON.message, options)
            },
            complete: function () {
                settingUser.loading(false);
            }
        });
    };

    modules.checkboxPhq9 = function (element) {
        if (settingUser.isDisable) {
            return false;
        }
        let value = false;
        if (element.checked) {
            value = true;
        }

        $.ajax({
            type: 'POST',
            url: '/trial/setting_user/set_phq9',
            data: {is_phq9: value},
            dataType: 'json',
            success: function (res) {
                $('.setting-user-container input[name="phq9_assessment"]').attr('data-old', value.toString());
                toastr.success('Success', 'Set phq9 assessment success.', options);
            },
            error: function (err) {
                toastr.error('Fail', 'An error occurred', options)
                console.error(err);
            }
        });
    };

    modules.disableForm = function (is_disable = true) {
        let phq9 = $('.setting-user-container input[name="phq9_assessment"]');

        if (is_disable) {
            phq9.attr("disabled", true);
            phq9.prop('checked', false);
            phq9.parent().find('.slider').addClass('detail-disable-important');
            $('.setting-user-container .group-hidden').addClass('d-none');
        } else {
            phq9.attr("disabled", false);
            phq9.parent().find('.slider').removeClass('detail-disable-important');
            $('.setting-user-container .group-hidden').removeClass('d-none');
        }

        $('.setting-user-container select').attr("disabled", is_disable).trigger('change');
    };

    modules.submitForm = function () {
        if (settingUser.isDisableForm) {
            return false;
        }

        let data = {};
        let member = $('.setting-user-container .select-member');
        if (member.val() === MEMBER_TYPE_ALL) {
            data = {
                city: $('.setting-user-container .select-city-1').val(),
                district: $('.setting-user-container .select-district-1').val(),
                ward: $('.setting-user-container .select-ward-1').val(),
                member: member.val(),

                city_2: $('.setting-user-container .select-city-2').val(),
                district_2: $('.setting-user-container .select-district-2').val(),
                ward_2: $('.setting-user-container .select-ward-2').val(),

                city_3: $('.setting-user-container .select-city-0').val(),
                district_3: $('.setting-user-container .select-district-0').val(),
                ward_3: $('.setting-user-container .select-ward-0').val(),
            };
        } else if (member.val() === TYPE_MEMBER) {
            data = {
                city: $('.setting-user-container .select-city-1').val(),
                district: $('.setting-user-container .select-district-1').val(),
                ward: $('.setting-user-container .select-ward-1').val(),
                member: member.val(),
            };
        } else if (member.val() === TYPE_LATE) {
            data = {
                member: member.val(),
                city_2: $('.setting-user-container .select-city-2').val(),
                district_2: $('.setting-user-container .select-district-2').val(),
                ward_2: $('.setting-user-container .select-ward-2').val(),
            };
        } else if (member.val() === TYPE_NONMEMBER) {
            data = {
                city_3: $('.setting-user-container .select-city-0').val(),
                district_3: $('.setting-user-container .select-district-0').val(),
                ward_3: $('.setting-user-container .select-ward-0').val(),
                member: member.val(),
            };
        }

        $.ajax({
            type: 'POST',
            url: '/trial/setting_user/set_national',
            data: data,
            dataType: 'json',
            success: function (res) {
                toastr.success('Success', 'Set trial area success.', options);
                settingUser.changeMember($('.setting-user-container .select-member'));
                $('#confirm-set-trial-area').modal('hide');
            },
            error: function (err) {
                toastr.error('Fail', err.responseJSON.message, options)
                console.error(err);
                settingUser.loading(false);
            }
        });
    };

    modules.loading = function (isShow = true) {
        let loadingIcon = $('.gif-loader');

        if (isShow) {
            loadingIcon.css('display', 'block');
        } else {
            loadingIcon.css('display', 'none');
        }
    };

    modules.validationSelect = function (city, district, ward) {
        let is_disable = false;

        if (city.val().length > 15000) {
            is_disable = true;
            city.parent().find('.error-text-area').removeClass('d-none').text('Selected up to 15000 cities.')
        } else {
            city.parent().find('.error-text-area').addClass('d-none').text('')
        }

        if (district.val().length > 15000) {
            is_disable = true;
            district.parent().find('.error-text-area').removeClass('d-none').text('Selected up to 15000 districts.')
        } else {
            district.parent().find('.error-text-area').addClass('d-none').text('')
        }

        if (ward.val().length > 15000) {
            is_disable = true;
            ward.parent().find('.error-text-area').removeClass('d-none').text('Selected up to 15000 wards.')
        } else {
            ward.parent().find('.error-text-area').addClass('d-none').text('')
        }

        if (is_disable) {
            settingUser.isDisableForm = true;
            $('.setting-user-container .btn-show-modal-submit').prop('disabled', true);
        } else {
            settingUser.isDisableForm = false;
            $('.setting-user-container .btn-show-modal-submit').prop('disabled', false);
        }
    };

    modules.showModalUpdateUser = function (element) {
        let parent_element = $(element).parents('tr');
        let parent_modal = $('#confirm-update-member-user');
        parent_modal.attr('data-id', parent_element.attr('data-id'));
        parent_modal.attr('data-type', parent_element.find('.table-set-member-user').val());
        parent_modal.find('.modal-id').text(parent_element.find('.table-user-id').text());
        parent_modal.find('.modal-name').text(parent_element.find('.table-user-name').text());
        parent_modal.find('.modal-city').text(parent_element.find('.table-user-province').text());
        parent_modal.find('.modal-district').text(parent_element.find('.table-user-district').text());
        parent_modal.find('.modal-ward').text(parent_element.find('.table-user-ward').text());
        parent_modal.find('.modal-type-member').text(parent_element.find('.table-set-member-user option:selected').text());
        parent_modal.modal('show');
    };

    modules.updateTypeMemberUser = function () {
        if (settingUser.isDisableForm) {
            return false;
        }
        let parent = $('#confirm-update-member-user');
        settingUser.loading();

        $.ajax({
            type: 'POST',
            url: '/trial/setting_user/setting_member_user',
            data: {
                id: parent.attr('data-id'),
                type_member: parent.attr('data-type'),
            },
            dataType: 'json',
            success: function (res) {
                parent.modal('hide');
                $(`.table-show-trial-user table tbody tr[data-id="${parent.attr('data-id')}"]`).remove();
                if ($('.table-show-trial-user table tbody tr').length === 0) {
                    $('.table-show-trial-user table tbody').append('<tr><td class="text-danger text-center" colspan="8">No data</td></tr>');
                }
                toastr.success('Success', 'Change user role.', options);
                settingUser.loading(false);
            },
            error: function (err) {
                toastr.error('Fail', 'An error occurred', options)
                settingUser.loading(false);
                console.error(err);
            }
        });
    };

    modules.enableBtnUpdateUsers = function (isEnable = true) {
        if (isEnable) {
            if ($('.table-show-trial-user table tbody tr .sub-checkbox').length !== 0) {
                $('.setting-user-container .table-show-trial-user .change-multiple-users').prop('disabled', false);
            }
        } else {
            $('.setting-user-container .table-show-trial-user .change-multiple-users').prop('disabled', true);
        }
    };

    modules.uniq = function (a) {
        return Array.from(new Set(a));
    };

    modules.updateMultipleUsers = function () {
        if (settingUser.isDisableForm) {
            return false;
        }
        let parent = $('#confirm-set-multiple-users');
        let users_id = $('.table-show-trial-user table tbody tr .ipt-has-check:checked').map((index, element) => {
            return $(element).attr('data-id');
        }).get();
        settingUser.loading();

        $.ajax({
            type: 'POST',
            url: '/trial/setting_user/setting_multiple_users',
            data: {
                ids: users_id,
                type_member: $('#member-update-multiple-users').val(),
            },
            dataType: 'json',
            success: function (res) {
                parent.modal('hide');
                if (res.data.length !== 0) {
                    for (const user of res.data) {
                        let element = $(`.table-show-trial-user table tbody tr[data-id="${user.id}"]`);
                        if (user.type_member !== parseInt(element.find('.table-set-member-user').val())) {
                            element.remove();
                        }
                    }
                }
                if ($('.table-show-trial-user table tbody tr').length === 0) {
                    $('.table-show-trial-user table tbody').append('<tr><td class="text-danger text-center" colspan="8">No data</td></tr>');
                }
                toastr.success('Success', 'Change user role.', options);
                settingUser.loading(false);
            },
            error: function (err) {
                toastr.error('Fail', 'An error occurred', options)
                settingUser.loading(false);
                console.error(err);
            }
        });
    };

    modules.filterUser = function () {
        settingUser.loading();
        let member = $('.setting-user-container .select-member');
        let data = {};
        if (member.val() === '1') {
            data = {
                type_member: member.val() || 1,
                city: $('.table-list-district-1 .select-city-1').val().concat($('.table-list-district-2 .select-city-2').val()),
                district: $('.table-list-district-1 .select-district-1').val().concat($('.table-list-district-2 .select-district-2').val()),
                ward: $('.table-list-district-1 .select-ward-1').val().concat($('.table-list-district-2 .select-ward-2').val()),
            }
        } else if (member.val() === '2') {
            data = {
                type_member: member.val() || 1,
                city: $('.table-list-district-0 .select-city').val(),
                district: $('.table-list-district-0 .select-district').val(),
                ward: $('.table-list-district-0 .select-ward').val(),
            }
        }

        $.ajax({
            type: 'GET',
            url: '/trial/setting_user/table_list_user',
            data: data,
            success: function (res) {
                $('.setting-user-container .table-show-trial-user').html(res);
                if ($('.table-show-trial-user table tbody tr td.text-danger').length === 0) {
                    $('#table-show-list-user').DataTable({
                        paging: false,
                        scrollX: false,
                        columnDefs: [
                            {
                                "targets": 0,
                                "orderable": false
                            },
                            {
                                "targets": 6,
                                "orderable": false
                            },
                        ],
                        searching: false,
                        orderMulti: true,
                        processing: false,
                        info: false,
                        language: {
                            zeroRecords: "No Data",
                        },
                    });
                }
                settingUser.loading(false);
            },
            error: function (err) {
                console.error(err);
                toastr.error('Fail', 'An error occurred', options)
                settingUser.loading(false);
            }
        });
    };

    modules.downloadFile = function (url) {
        window.location.href = url;

        setTimeout(() => {
            $('.gif-loader').css('display', 'none');
        }, 4000);
    }

    modules.wardCannotMatch = function (array_1, array_2) {
        let value = array_1.filter(el => array_2.includes(el));

        if (value.length > 0) {
            toastr.error('Fail', 'Wards cannot match', options);
            $('.setting-user-container .btn-show-modal-submit').prop('disabled', true);
        } else {
            $('.setting-user-container .btn-show-modal-submit').prop('disabled', false);
        }
    }

    modules.clearOldDataProvince = function (parent) {
        parent.find('.select-city').val('').trigger('change');
        parent.find('.select-district').empty();
        parent.find('.select-ward').empty();
    }

    return modules;
}(window.jQuery, window, document));

$(document).ready(function () {
    settingUser.init();

    $(document).on('change', '.setting-user-container .select-city', function () {
        if ($('#disable-onchange').val() === '') {
            let parent = $(this).parents('.table-list-district');
            let city = parent.find('.select-city');
            let district = parent.find('.select-district');
            let ward = parent.find('.select-ward');

            settingUser.validationSelect(city, district, ward);
            settingUser.changeDistrict(parent, $(this).val());
        }
    });

    $(document).on('click', '.set-score-phq9', function () {
        settingUser.setScorePhq9({score_phq9: $(this).parents('.align-items-center').find('input').val().trim()})
    });

    $(document).on('click', '.set-score-phq9-allow-use-system', function () {
        settingUser.setScorePhq9AllowUseSystem({score_phq9_compare: $(this).parents('.align-items-center').find('input').val().trim()})
    });

    $(document).on('click', '.btn-download-example', function () {
        $('#form-download-example').submit();
        setTimeout(() => {
            $('.gif-loader').css('display', 'none');
        }, 4000);
    });

    $(document).on('change', '.setting-user-container .select-district', function () {
        if ($('#disable-onchange').val() === '') {
            let parent = $(this).parents('.table-list-district');
            let city = parent.find('.select-city');
            let district = parent.find('.select-district');
            let ward = parent.find('.select-ward');

            settingUser.validationSelect(city, district, ward);
            settingUser.changeWard(parent, $(this).val());
        }
    });

    $(document).on('change', '.setting-user-container .select-ward', function () {
        let parent = $(this).parents('.table-list-district');
        let city = parent.find('.select-city');
        let district = parent.find('.select-district');
        let ward = parent.find('.select-ward');

        settingUser.validationSelect(city, district, ward);

        if ($('.setting-user-container .select-member').val() === '1') {
            let ward_1 = $('.setting-user-container .table-list-district-1 .select-ward').val();
            let ward_2 = $('.setting-user-container .table-list-district-2 .select-ward').val();
            settingUser.wardCannotMatch(ward_1, ward_2);
        }
    });

    $(document).on('click', '#confirm-set-trial-area .btn-submit', function () {
        settingUser.submitForm();
        $('#confirm-set-trial-area').modal('hide');
        settingUser.loading();
    });

    $(document).on('change', '.setting-user-container input[name="clinical_trial"]', function () {
        settingUser.checkboxTrial(this);
    });

    $(document).on('change', '.setting-user-container input[name="phq9_assessment"]', function () {
        settingUser.checkboxPhq9(this);
    });

    $(document).on('change', '.setting-user-container .select-member', function () {
        settingUser.clearOldDataProvince($('.table-list-district'));

        settingUser.changeMember($(this));
    });

    $(document).on('show.bs.modal', '#confirm-set-trial-area', function () {
        let list_city = $('.setting-user-container .select-city option:selected').map((i, element) => {
            return $(element).text();
        }).get();
        let list_district = $('.setting-user-container .select-district option:selected').map((i, element) => {
            return $(element).text();
        }).get();
        let list_ward = $('.setting-user-container .select-ward option:selected').map((i, element) => {
            return $(element).text();
        }).get();

        $(this).find('.modal-city').text(list_city.join(', '));
        $(this).find('.modal-district').text(list_district.join(', '));
        $(this).find('.modal-ward').text(list_ward.join(', '));
        $(this).find('.modal-type-member').text($('.setting-user-container .select-member option:selected').text());
    });

    $(document).on('hide.bs.modal', '#confirm-update-member-user', function () {
        let parent = $(`.table-show-trial-user table tbody tr[data-id="${$(this).attr('data-id')}"] .table-set-member-user`);

        if (parent.val() !== '1') {
            parent.find(`option[value="1"]`).prop('selected', true);
        } else {
            parent.find(`option[value="2"]`).prop('selected', true);
        }
    });

    $(document).on('click', '.setting-user-container #check-all-0', function () {
        if ($('#check-all-0').is(':checked')) {
            $('.custom-checkbox-2 .ipt-has-check').prop('checked', true);
            settingUser.enableBtnUpdateUsers();
        } else {
            $('.custom-checkbox-2 .ipt-has-check').prop('checked', false);
            settingUser.enableBtnUpdateUsers(false);
        }
    });

    $(document).on('click', '.setting-user-container .ipt-has-check', function () {
        if ($(this).is(':checked')) {
            settingUser.enableBtnUpdateUsers();
            if ($('.setting-user-container .sub-checkbox .ipt-has-check').length === $('.setting-user-container .ipt-has-check:checked').length) {
                $('#check-all-0').prop('checked', true);
            }
        } else {
            if ($('.setting-user-container .ipt-has-check:checked').length !== 0) {
                settingUser.enableBtnUpdateUsers();
            } else {
                settingUser.enableBtnUpdateUsers(false);
            }
            $('#check-all-0').prop('checked', false);
        }
    });

    $(document).on('click', '.setting-user-container .btn-filter', function () {
        settingUser.filterUser();
    });

    $(document).on('change', '.setting-user-container .table-set-member-user', function () {
        if ($('.setting-user-container input[name="clinical_trial"]').is(":checked")) {
            settingUser.showModalUpdateUser(this);
        }
    });

    $(document).on('click', '#confirm-update-member-user .btn-submit', function () {
        settingUser.updateTypeMemberUser();
    });

    $(document).on('show.bs.modal', '#confirm-set-multiple-users', async function () {
        let city = [], district = [], ward = [];
        let text_member = 'Member (early)';
        $('#member-update-multiple-users').val('1');

        if ($('.setting-user-container .select-member').val() === '1') {
            text_member = 'Non-Member (delay)';
            $('#member-update-multiple-users').val('2');
        }
        await $('.setting-user-container .ipt-has-check:checked').each((index, element) => {
            let parent = $(element).parents('tr');
            if (parent.find('.table-user-province').text().trim().length !== 0) {
                city.push(parent.find('.table-user-province').text());
            }
            if (parent.find('.table-user-district').text().trim().length !== 0) {
                district.push(parent.find('.table-user-district').text());
            }
            if (parent.find('.table-user-ward').text().trim().length !== 0) {
                ward.push(parent.find('.table-user-ward').text());
            }
        });

        city = settingUser.uniq(city);
        district = settingUser.uniq(district);
        ward = settingUser.uniq(ward);

        $('#confirm-set-multiple-users .modal-number-user').text($('.table-show-trial-user table tbody tr .ipt-has-check:checked').length);
        $('#confirm-set-multiple-users .modal-city').text(city.join(", "));
        $('#confirm-set-multiple-users .modal-district').text(district.join(", "));
        $('#confirm-set-multiple-users .modal-ward').text(ward.join(", "));
        $('#confirm-set-multiple-users .modal-type-member').text(text_member);
    });

    $(document).on('click', '#confirm-set-multiple-users .btn-submit', function () {
        settingUser.updateMultipleUsers();
    });

    $(document).on('click', '.setting-user-container .btn-download', function () {
        settingUser.downloadFile($(this).attr('data-href'));
    });

    $(document).on('change', '.setting-user-container .upload-province', function () {
        $('.setting-user-container .upload-file-province').prop('disabled', false);
        $(this).parent().find('.file-name').remove();
    });

    $(document).on('change', '.setting-user-container .upload-province-member', function () {
        $(this).parents('.group-upload-member').find('.upload-file-province-member').prop('disabled', false);
    });

    $(document).on('click', '.sort_score_total', function (){
        const sortType = $(this).attr('data-sort-type') === 'asc' ? 'desc' : 'asc'
        $(this).attr('data-sort-type', sortType);
        const sortTypeCreatedAt = $('.sort_created_at').attr('data-sort-type');
        $('#form-submit-get-user').append(`<input name="sort_score_total" value="${sortType}" />`);
        $('#form-submit-get-user').append(`<input name="sort_created_at" value="${sortTypeCreatedAt}" />`);
        $('#form-submit-get-user').submit();
    });

    $(document).on('click', '.sort_created_at', function () {
        const sortType = $(this).attr('data-sort-type') === 'asc' ? 'desc' : 'asc'
        $(this).attr('data-sort-type', sortType);
        const sortTypeScoreTotal = $('.sort_score_total').attr('data-sort-type');
        $('#form-submit-get-user').append(`<input name="sort_created_at" value="${sortType}" />`)
        $('#form-submit-get-user').append(`<input name="sort_score_total" value="${sortTypeScoreTotal}" />`)
        $('#form-submit-get-user').submit();
    });

    $('#form-submit-get-user .btn-success-custom').on('click', async function(e) {
        const sortTypeScoreTotal = $('.sort_score_total').attr('data-sort-type');
        const sortTypeCreatedAt = $('.sort_created_at').attr('data-sort-type');
        $('#form-submit-get-user').append(`<input name="sort_created_at" value="${sortTypeCreatedAt}" />`)
        $('#form-submit-get-user').append(`<input name="sort_score_total" value="${sortTypeScoreTotal}" />`)
        $('#form-submit-get-user').submit();
    });
});
