$(document).on("turbolinks:load", function () {
    let formProfileId = "#form-profile"

    // check image before upload
    $('#admin_avatar').on('change', function () {
        previewImage($(this)[0], $('.img_preview'));
        if ($(this).valid()) {
            $("#admin_avatar .error").removeClass('error')
        }
    })

    // validate form edit admin
    validate_form_edit_admin(formProfileId);

    function validate_form_edit_admin(form_id) {
        $(form_id).validate({
            onfocusout: function (element) {
                this.element(element);
            },
            errorElement: "p",
            rules: {
                "admin[username]": {
                    rangelength: [6, 20]
                },

                "admin[full_name]": {
                    required: true,
                    maxlength: 50
                },
                "admin[avatar]": {
                    extension: "png|jpg|jpeg|gif",
                    maxsize: 5242880
                },
                "admin[note]": {
                    maxlength: 1000
                },
            },
            messages: {
                "admin[avatar]": {
                    extension: "Image must be a PNG or JPG or JPEG or GIF",
                    maxsize: "File size must not exceed 5MB"
                }
            },
            submitHandler: function (form) {
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }


    // show or hide password
    $("#verify_old_password").click(function () {
        togglePassword('.old_password', '#old_password')
    });

    $("#new_password").click(function () {
        togglePassword('.new_password', '#password')
    });

    $("#confirm_password").click(function () {
        togglePassword('.password_confirmation', '#password_confirmation')
    });

    // validate form change password admin
    validate_form_change_password("#change_password");

    function validate_form_change_password(form_id) {
        $(form_id).validate({
            onfocusout: function (element) {
                this.element(element);
            },

            errorElement: "span",
            rules: {
                old_password: {
                    required: true,
                },
                password: {
                    required: true,
                    minlength: 6,
                    validPassword: true,
                    maxlength: 255
                },
                password_confirmation: {
                    required: true,
                    equalTo: "#password"
                },
            },
            submitHandler: function (form) {
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }

    //validate for old password field when it has message model errors
    handleActiveRecordValidation('#old_password', '.flash_error')

    $(document).on('click', '#confirm-approval', function () {
        $('#modal-confirm-transfer-to-expert').modal('hide');
        $('#modal-submit-transfer-to-expert').modal('show');
    })
});
