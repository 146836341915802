// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("bootstrap");
// require("@fortawesome/fontawesome-free");
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require('packs/dashboard');
require("packs/jquery.validate");
require("packs/additional-methods")
require('packs/hospital_clinics');
require('packs/conversations');
require('packs/expert');
require("packs/profile");
require('packs/product');
require('packs/reference-readings');
require("packs/permissions");
require("packs/sub_admin");
require("chartkick");
require("chart.js");
require('packs/bootstrap-datepicker.min')
require('packs/consulting-packages');
require('packs/other-assessment');
require('packs/mood_scale')
require('packs/realistic_thinking_questions')
require('packs/setting-user')
require("packs/document_settings");
require("packs/difficult_problems");

require("socket.io-client")
require("packs/connect_socket")
require("packs/datatables.min")
require("packs/difficult_problem_question")
require("packs/notification_setting")
require("packs/modal_setting")

import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all";

import { compareTwoDates, togglePassword, previewImage, triggerInputFile, handleActiveRecordValidation } from "./common";
window.compareTwoDates = compareTwoDates
window.togglePassword = togglePassword
window.previewImage = previewImage
window.triggerInputFile = triggerInputFile
window.handleActiveRecordValidation = handleActiveRecordValidation

import toastr from './toastr.min'
window.toastr = toastr

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// With safari browser, If persisted then it is in the page cache, force a reload of the page.
window.onpageshow = function (evt) {
    if (evt.persisted) {
        document.body.style.display = "none";
        location.reload();
    }
};
