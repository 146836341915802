import io from 'socket.io-client';

$(document).on("turbolinks:load", function () {
    let url = $('#url-server-socket');
    if (url.length !== 0 && url.val().length !== 0) {
        const socket = io.connect(url.val(), { forceNew: true, transports: ['websocket', 'polling'], query: { user_code: 'user_code_admin', type: 'admin' }});

        socket.on('connect', function() {
            console.log('Connected socket');
        });

        socket.on('receive_information', data => {
            let user = [], expert = [];
            $.each(data, (index, value) => {
                if (value.type === 'user' && value.user_code !== '') user.push(value.user_code);
                if (value.type === 'expert' && value.user_code !== '') expert.push(value.user_code);
            });
            localStorage.setItem('list_users', JSON.stringify(uniq(user)));
            localStorage.setItem('list_experts', JSON.stringify(uniq(expert)));
            updateNumberUserOnline();
        });

        socket.on('disconnect_channel', data => {
            if (data.type === 'user') {
                let user = JSON.parse(localStorage.getItem('list_users'));
                localStorage.setItem('list_users', JSON.stringify(removeItemOnce(user, data.user_code)));
                $.ajax({
                    type: 'POST',
                    url: '/management/account/update_time',
                    data: {
                        id: data.user_code
                    },
                });
            }
            if (data.type === 'expert') {
                let expert = JSON.parse(localStorage.getItem('list_experts'));
                localStorage.setItem('list_experts', JSON.stringify(removeItemOnce(expert, data.user_code)));
            }
            updateNumberUserOnline();
        });

        socket.on('disconnect', function() {
            console.log('Disconnected');
            localStorage.clear();
        });

        socket.on('error', function() {
            console.log("Sorry, there seems to be an issue with the connection!");
        });

        socket.on('connect_error', err => {
            console.log("connect failed: " + err);
        });
    }

    function uniq(a) {
        let seen = {};
        return a.filter(function(item) {
            return seen.hasOwnProperty(item) ? false : (seen[item] = true);
        });
    }

    function removeItemOnce(arr, value) {
        let index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }

    function updateNumberUserOnline() {
        let user = JSON.parse(localStorage.getItem('list_users'));
        let expert = JSON.parse(localStorage.getItem('list_experts'));

        $('.number-user-online').text(user.length);
        $('.number-expert-online').text(expert.length);
        setOnlineUser(user);
    }

    function setOnlineUser(listUser) {
        let user = $('#value-user-code');
        let online = $('.status-online .online');
        let offline = $('.status-online .offline');

        online.addClass('d-none').removeClass('d-flex');
        offline.addClass('d-flex').removeClass('d-none');

        if (user.length === 0) return;
        if (user.val().length === 0) return;

        console.log('listUser: ', listUser, listUser.includes(user.val()));
        if (listUser.includes(user.val())) {
            online.addClass('d-flex').removeClass('d-none');
            offline.addClass('d-none').removeClass('d-flex');
        }
    }
});