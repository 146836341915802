$(document).ready(function () {

    function showNotification(text, thisElement, elementError) {
        let valid = true;
        if (text === '') {
            valid = false;
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text('This field is required.');
            return valid;
        } else if (text.length > 255) {
            valid = false;
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text('Please enter no more than 255 characters.');
            return valid;
        } else {
            $(thisElement).removeClass('err-input');
            elementError.removeClass('msg-error').text('');
            return true;
        }
    }

    function showNotificationDay(val, thisElement, elementError) {
        let regex = /^\d*$/;
        let valid = true;

        if (val === '') {
            valid = false;
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text('This field is required.');
            return valid;
        } else if (!regex.test(parseInt(val))) {
            valid = false;
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text('This field is invalid.');
            return valid;
        } else if (!regex.test(val)) {
            valid = false;
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text('Please enter realistic thinking numbers.');
            return valid;
        }  else if (val > 10000) {
            valid = false;
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text('Please enter no more than 10000');
            return valid;
        }
        else {
            $(thisElement).removeClass('err-input');
            elementError.removeClass('msg-error').text('');
            return true;
        }
    }

    function showNotificationTime(val, thisElement, elementError, type) {
        let regex = /^\d*$/;
        let valid = true;
        if (val === '') {
            valid = false;
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text(`The ${type} is required.`);
            return valid;
        } else if (!regex.test(parseInt(val))) {
            valid = false;
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text(`The ${type} is invalid.`);
            return valid;
        } else if (!regex.test(val)) {
            valid = false;
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text(`Please enter realistic thinking numbers ${type}.`);
            return valid;
        } else {
            if ((type == 'hour' && val > 23) || (type == 'minute' && val > 60)){
                valid = false;
                $(thisElement).addClass('err-input');
                elementError.addClass('msg-error').text(`The ${type} is invalid.`);
                return valid;
            } else {
                $(thisElement).removeClass('err-input');
                elementError.removeClass('msg-error').text('');
                return true;
            }

        }
    }

    function trimParamForm() {
        $.each($('.notification-settings-container textarea'), async (index, element) => {
            $(element).val($(element).val().trim());
        });

        $.each($('.notification-settings-container input'), async (index, element) => {
            $(element).val($(element).val().trim());
        });
    }

    function scrollToStep (top, left) {
        $('html, body').animate({
            scrollTop: top,
            scrollLeft: left
        });
    }

    $(document).on('change', '.notification-settings-container .condition-user input[name="user_push"]', function () {
        switch($(this).val()) {
            case '1':
                $('.notification-settings-container .setting-day-limit').empty()
                break;
            case '2':
                let html = `
                    <div class="d-flex flex-column p-4">
                        <div class="d-flex input-form-1">
                          <input type="checkbox" value="1" name="day_off_limit" class="custom-sub-radio" checked>
                          <div class="d-flex">
                            <div class="parent-errors">
                              <div class="d-flex">
                                <div class="mr-1 event-click-checkbox-1">Remain offline for more than</div>
                                <input type="text" class="number-day-off text-center noti_day_limit" min="0" name="number_day_off" max="999" maxlength="3">
                                <div class="ml-1 event-click-checkbox-1">days</div>
                              </div>
                              <span class="error-input text-danger"></span>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex mt-3 input-form-2">
                          <input type="checkbox" value="2" name="day_miss_limit" class="custom-sub-radio" checked>
                          <div class="d-flex">
                            <div class="parent-errors">
                              <div class="d-flex">
                                <div class="mr-1 event-click-checkbox-2">Miss their activities’ schedule for</div>
                                <input type="text" class="number-day-miss text-center noti_day_limit" name="number_day_miss" min="0" max="999" maxlength="3">
                                <div class="ml-1 event-click-checkbox-2">days</div>
                              </div>
                              <span class="error-input text-danger"></span>
                            </div>
                          </div>
                        </div>
                        <span class="errors-option-day-limit"></span>
                    </div>
                `

                $('.notification-settings-container .setting-day-limit').append(html)
                break;
        }
    });

    $(document).on('change', '.notification-settings-container .setting-day input[type="radio"]', function () {
        switch($(this).val()) {
            case '1':
                $('.notification-settings-container .setting-day .container-day').empty()
                break;
            case '2':
                let html = `
                    <div class="mr-5">On</div>
                    <div class="row d-flex flex-column">
                        <div class="d-flex flex-wrap">
                          <label>
                            <input type="checkbox" class="day-in-week" name="day_push_in_week[2]" value="Monday" />
                            <div class="icon-box">
                              <span>M</span>
                            </div>
                          </label>
                          <label>
                            <input type="checkbox" class="day-in-week" name="day_push_in_week[3]" value="Tuesday" />
                            <div class="icon-box">
                              <span>T</span>
                            </div>
                          </label>
                          <label>
                            <input type="checkbox" class="day-in-week" name="day_push_in_week[4]" value="Wednesday" />
                            <div class="icon-box">
                              <span>W</span>
                            </div>
                          </label>
                          <label>
                            <input type="checkbox" class="day-in-week" name="day_push_in_week[5]" value="Thursday" />
                            <div class="icon-box">
                              <span>T</span>
                            </div>
                          </label>
            
                          <label>
                            <input type="checkbox" class="day-in-week" name="day_push_in_week[6]" value="Friday" />
                            <div class="icon-box">
                              <span>F</span>
                            </div>
                          </label>
            
                          <label>
                            <input type="checkbox" class="day-in-week" name="day_push_in_week[7]" value="Saturday" />
                            <div class="icon-box">
                              <span>S</span>
                            </div>
                          </label>
                          <label>
                            <input type="checkbox" class="day-in-week" name="day_push_in_week[8]" value="Sunday" />
                            <div class="icon-box">
                              <span>S</span>
                            </div>
                          </label>
                      </div>
                      <span class="error-day-push-in-week color-red"></span>
                    </div>
                `
                $('.notification-settings-container .setting-day .container-day').append(html)
                break;
        }
    });

    $(document).on('change', '.notification-settings-container .setting-day-limit input[type="checkbox"]', function () {
        if($(this).is(':checked')){
            $(this).parent().find('input[type="text"]').removeClass('err-input').addClass('noti_day_limit').attr("disabled", false);
            $(this).parent().find('.error-input').text('')
        } else {
            $(this).parent().find('input[type="text"]').removeClass('err-input noti_day_limit').val('').attr("disabled", true);
            $(this).parent().find('.error-input').text('')
        }
    });

    $(document).on('click', '#form-notification-setting .notification-settings-container .event-click-checkbox-1', function () {
        let checkbox = $(this).parents('.input-form-1').find('.custom-sub-radio');
        if (checkbox.is(":checked")) {
            $('.condition-user .number-day-off').val('').removeClass('noti_day_limit').attr("disabled", true);
            checkbox.prop('checked', false);
            $('.number-day-off').removeClass('err-input')
            $(this).parent().parent().find('.error-input').text('')
        } else {
            checkbox.prop('checked', true);
            $('.errors-option-day-limit').text('');
            $('.condition-user .number-day-off').val('').addClass('noti_day_limit').attr("disabled", false);
            $('.number-day-off').removeClass('err-input')
            $(this).parent().parent().find('.error-input').text('')
        }

    });

    $(document).on('click', '#form-notification-setting .notification-settings-container .event-click-checkbox-2', function () {
        let checkbox = $(this).parents('.input-form-2').find('.custom-sub-radio');
        if (checkbox.is(":checked")) {
            $('.condition-user .number-day-miss').val('').removeClass('noti_day_limit').attr("disabled", true);
            checkbox.prop('checked', false);
            $('.number-day-miss ').removeClass('err-input')
            $(this).parent().parent().find('.error-input').text('')
        } else {
            checkbox.prop('checked', true);
            $('.errors-option-day-limit').text('')
            $('.condition-user .number-day-miss').val('').addClass('noti_day_limit').attr("disabled", false);
            $('.number-day-miss ').removeClass('err-input')
            $(this).parent().parent().find('.error-input').text('')
        }
    });

    $(document).on('click', '.notification-settings-container .btn-confirm-delete-notification', function () {
        let id = $('#question-id').val();
        if (isNaN(id)) {
            return false;
        }
        $.ajax({
            type: 'DELETE',
            url: '/management/master/notifications/' + id,
            data: {
                id: id
            },
        });
    })

    $(document).on('click', '#form-notification-setting #create-notifi-setting', async function (){
        $('#create-notification-setting').modal('hide')
        let valid = true;
        $('#form-notification-setting .notification-settings-container .noti_text').map( function() {
            let check = showNotification($(this).val().trim(), $(this), $(this).parent().find('.error-answer'));
            if (check == false){
                valid = false;
            }
        })

        $('#form-notification-setting .notification-settings-container .noti_day_limit').map( function() {
            let check_day = showNotificationDay($(this).val().trim(), $(this), $(this).parent().parent().find('.error-input'))
            if (check_day == false){
                valid = false;
            }
        })

        let input_time = $('#form-notification-setting .notification-settings-container .input-time')
        let check_time = showNotificationTime(input_time.val().trim(), input_time, input_time.parent().parent().find('.error-input-time'), 'hour')
        if (check_time == false) {
            valid = false;
        }

        let input_minutes = $('#form-notification-setting .notification-settings-container .input-minutes')
        let check_minutes = showNotificationTime(input_minutes.val().trim(), input_minutes, input_minutes.parent().parent().find('.error-input-minutes'), 'minute')
        if (check_minutes == false) {
            valid = false;
        }

        if ($("#week-day").is(":checked")){
            let arr_day = $('#form-notification-setting .notification-settings-container .day-in-week:checked').map(function () {
                return $(this).val();
            }).get()
            if (arr_day.length == 0){
                valid = false;
                $('.error-day-push-in-week').addClass('msg-error').text('Please select push date.')
            } else {
                $('.error-day-push-in-week').text('')
            }
        }

        if (($('#form-notification-setting .setting-day-limit input[type="checkbox"]:checked').length == 0) && $('#user-that').is(":checked")) {
            $('.errors-option-day-limit').addClass('color-red msg-error').text('Please select options.')
            valid = false;
        } else {
            $('.errors-option-day-limit').removeClass('msg-error').text('')
        }

        if (valid == true) {
            let form = $('#form-notification-setting');
            await trimParamForm();
            await form.submit();
        } else {
            let element = $('.notification-settings-container .msg-error:first');
            scrollToStep(element.offset().top - 300, element.offset().left)
        }
    })
})
