let Expert = (function () {
    let modules = {};
    $(document).on('click', '.btn-addmore-expert-area', function() {
        let lastid = $(".area-expert:last").attr("id");
        let split_id = lastid.split("_");
        let nextindex = Number(split_id[1]) + 1;
        $(".area-expert:last").after("<div class='row area-expert sub-area mt-2' id='area-expert_" + nextindex + "'></div>");

        $("#area-expert_" + nextindex).append("<div class=\"col-md-11 row\">\n" +
            "                  <div class=\"col-12 col-xl-3 p0l p0r-xl\">\n" +
            "                    <select name=\"expert_area[][nationality]\" class=\"form-control input-custom-style\" id=\"countryId\">\n" +
            "                      <option value=\"VN\">Việt Nam</option>\n" +
            "                    </select>\n" +
            "                  </div>\n" +
            "\n" +
            "                  <div class=\"col-12 col-xl-3 p0 m10t-xl province\">\n" +
            "                    <select name=\"expert_area[][province]\" class=\"form-control input-custom-style state-id\">\n" +
            "                      <option value=\"\">Province/City</option>\n" +
            "                    </select>\n" +
            "                  </div>\n" +
            "\n" +
            "                  <div class=\"col-12 col-xl-3 p0r m10t-xl p0l-xl\">\n" +
            "                    <select name=\"expert_area[][district]\" class=\"form-control input-custom-style city-id\">\n" +
            "                      <option value=\"\">District</option>\n" +
            "                    </select>\n" +
            "                  </div>\n" +
            "\n" +
            "                  <div class=\"col-12 col-xl-3 p0r m10t-xl p0l-xl\">\n" +
            "                    <select name=\"expert_area[][ward]\" class=\"form-control input-custom-style ward-id\">\n" +
            "                      <option value=\"\">Ward</option>\n" +
            "                    </select>\n" +
            "                  </div>\n" +
            "                </div>\n" +
            "\n" +
            "                <div class=\"col-md-1\">\n" +
            "                  <button type=\"button\" id=remove_"+ nextindex +" class=\"btn btn-success remove btn-remove\">-</button>\n" +
            "                </div>");
        let grandParentElement = $('#area-expert_' + nextindex);
        let parentElement = grandParentElement.children(".col-md-11").children('.province');
        let provinceElement = parentElement.find('.state-id');
        Expert.getListState(provinceElement);
    });

    // Remove element
    $(document).on('click', '.remove', function() {
        var id = this.id;
        var split_id = id.split("_");
        var deleteindex = split_id[1];

        // Remove <div> with id
        $("#area-expert_" + deleteindex).remove();

    });

    modules.lockAccount = function () {
        let statusAccount = $('.status_account');

        $('#expert-status').val(0);
        statusAccount.removeClass('status-active');
        statusAccount.removeClass('status-none');
        statusAccount.addClass('status-inactive');
        statusAccount.text('Inactive');
        $('.btn-show-modal-lock').attr('hidden', true);
        $('.btn-show-modal-unlock').attr('hidden', false);
    }

    modules.unlockAccount = function () {
        let statusAccount = $('.status_account');

        $('#expert-status').val(1);
        statusAccount.removeClass('status-inactive');
        statusAccount.removeClass('status-none');
        statusAccount.addClass('status-active');
        statusAccount.text('Active');
        $('.btn-show-modal-unlock').attr('hidden', true);
        $('.btn-show-modal-lock').attr('hidden', false);
    }

    modules.getListState = function (countryId, thisClass) {
        $.ajax({
            type: "GET",
            url: "/city",
            dataType: "json",
            success: function (response) {
                const listState = response.city;
                $.each(listState, function (i, val) {
                    $('#stateId').append(`<option value=${val.code}>${val.name}</option>`);
                    $('.state-id').append(`<option value=${val.code}>${val.name}</option>`);
                });

                if(typeof thisClass !== 'undefined') {
                    $.each(listState, function (i, val) {
                        thisClass.append(`<option value=${val.code}>${val.name}</option>`);
                    });
                } else {
                    $.each(listState, function (i, val) {
                        $('#stateId').append(`<option value=${val.code}>${val.name}</option>`);
                        $('.state-id').append(`<option value=${val.code}>${val.name}</option>`);
                    });
                }
            },
        });
    }

    modules.getListCity = function (countryId, stateId, currentAdminDistrictId, thisClass) {
        $.ajax({
            type: "GET",
            url: "/district",
            data: { city_id: [stateId], current_admin_district_id: currentAdminDistrictId },
            dataType: "json",
            success: function (response) {
                const listState = response.district;
                if(typeof thisClass !== 'undefined') {
                    $.each(listState, function (i, val) {
                        thisClass.append(`<option value=${val.code}>${val.name}</option>`);
                    });
                } else {
                    $.each(listState, function (i, val) {
                        $('#cityId').append(`<option value=${val.code}>${val.name}</option>`);
                    });
                }
            },
        });
    }

    modules.getListWard = function (districtId, currentAdminWardId, thisClass) {
        $.ajax({
            type: "GET",
            url: "/ward",
            data: { district_id: [districtId], current_admin_ward_id: currentAdminWardId },
            dataType: "json",
            success: function (response) {
                const listWard = response.ward;
                if(typeof thisClass !== 'undefined') {
                    $.each(listWard, function (i, val) {
                        thisClass.append(`<option value=${val.code}>${val.name}</option>`);
                    });
                }
                $.each(listWard, function (i, val) {
                    $('#wardId').append(`<option value=${val.code}>${val.name}</option>`);
                    $('.ward-id').append(`<option value=${val.code}>${val.name}</option>`);
                });
            },
        });
    }

    modules.selectInState = function (thisClass) {
        let editProvince = '#edit-province';
        let param;
        if ($(editProvince).length) {
            let stateIdDb = parseInt($(editProvince).val());
            let countryIdDb = parseInt($('#edit-nationality').val());
            let currentAdminProvinceId = $('#current_admin_province_id').val();
            let currentAdminId = $('#current_admin_id').val();
            if (currentAdminProvinceId) {
                param = {
                    current_admin_province_id: currentAdminProvinceId,
                    current_admin_id: currentAdminId,
                    current_province: stateIdDb,
                }
            }

            $.ajax({
                type: "GET",
                url: "/city",
                data: param,
                dataType: "json",
                success: function (response) {
                    let stateId = '#stateId';
                    let stateClass = '.state-id';
                    $(stateId).html('');
                    $(stateId).append(`<option value="">All Province/City</option>`);
                    $(stateClass).html('');
                    $(stateClass).append(`<option value="">All Province/City</option>`);

                    const listState = response.city;
                    $.each(listState, function (i, val) {
                        let state = '';
                        if (stateIdDb === val.code) {
                            state = `<option value=${val.code} selected>${val.name}</option>`;
                        } else {
                            state = `<option value=${val.code}>${val.name}</option>`
                        }
                        $(stateId).append(state);
                        $(stateClass).append(state);
                    });
                },
            });
        }
    }

    modules.selectInStateEdit = function () {
        let expertAreaLength = parseInt($('.expert-area-length').val());
        let editProvinceClass = '.edit-province-area-';
        if ( expertAreaLength > 0 ) {
            for (let index = 0; index < expertAreaLength; index++) {
                let classSelector = editProvinceClass + index;
                let stateIdDb = parseInt($(editProvinceClass + index).val());
                let currentAdminProvinceId = $('#current_admin_province_id').val();
                $.ajax({
                    type: "GET",
                    url: "/city",
                    data: { current_admin_province_id: currentAdminProvinceId },
                    dataType: "json",
                    success: function (response) {
                        let classSelectorDisplay = $(classSelector).parent().children('.state-id')
                        $(classSelectorDisplay).html('');
                        $(classSelectorDisplay).append(`<option value="">All Province/City</option>`);

                        const listState = response.city;
                        $.each(listState, function (i, val) {
                            let state = '';
                            if (stateIdDb === val.code) {
                                state = `<option value=${val.code} selected>${val.name}</option>`;
                                modules.selectInCityEdit(val.code, index);
                            } else {
                                state = `<option value=${val.code}>${val.name}</option>`
                            }
                            $(classSelectorDisplay).append(state);
                        });
                    },
                });
            }
        }
    }

    modules.selectInCity = function () {
        let editDistrict = '#edit-district';
        let currentAdminDistrictId = $('#current_admin_district_id').val();
        let param;
        if ($(editDistrict).length) {
            let countryIdDb = $('#edit-nationality').val();
            let stateIdDb = parseInt($('#edit-province').val());
            let cityIdDb = parseInt($(editDistrict).val());
            if (isNaN(stateIdDb) && currentAdminDistrictId) {
                param = {
                    city_id: "",
                    current_admin_district_id: currentAdminDistrictId
                }
            } else {
                param = {
                    city_id: [stateIdDb]
                }
            }

            $.ajax({
                type: "GET",
                url: "/district",
                data: param,
                dataType: "json",
                success: function (response) {
                    let cityId = '#cityId';
                    $(cityId).html('');
                    $(cityId).append(`<option value="">All District</option>`);

                    const listState = response.district;
                    $.each(listState, function (i, val) {
                        let city = '';
                        if (cityIdDb === val.code) {
                            city = `<option value=${val.code} selected>${val.name}</option>`;
                        } else {
                            city = `<option value=${val.code}>${val.name}</option>`;
                        }
                        $(cityId).append(city);
                    });
                },
            });
        }
    }

    modules.selectInCityEdit = function (cityId, index) {
        let editDistrictClass = '.edit-district-area-';
        if (cityId) {
            let param = {
                city_id: [cityId],
            }
            $.ajax({
                type: "GET",
                url: "/district",
                data: param,
                dataType: "json",
                success: function (response) {
                    let districtId = $(editDistrictClass + index).parent().children('.city-id');
                    let districtIdDb = parseInt($(editDistrictClass + index).val())
                    $(districtId).html('');
                    $(districtId).append(`<option value="">All District</option>`);

                    const listDistrict = response.district;
                    $.each(listDistrict, function (i, val) {
                        let district = '';
                        if (districtIdDb === val.code) {
                            district = `<option value=${val.code} selected>${val.name}</option>`;
                            modules.selectInWardEdit(val.id, index)
                        } else {
                            district = `<option value=${val.code}>${val.name}</option>`;
                        }
                        $(districtId).append(district);
                    });
                },
            });
        }
    }

    modules.selectInWard = function () {
        let editWard = '#edit-ward';
        let param;
        if ($(editWard).length) {
            let districtId = parseInt($('#edit-district').val());
            let wardIdDb = parseInt($(editWard).val());
            let currentAdminWardId = $('#current_admin_ward_id').val();
            if (isNaN(districtId) && currentAdminWardId) {
                param = {
                    district_id: "",
                    current_admin_ward_id: currentAdminWardId
                }
            } else {
                param = {
                    district_id: [districtId]
                }
            }
            $.ajax({
                type: "GET",
                url: "/ward",
                data: param,
                dataType: "json",
                success: function (response) {
                    let wardId = '#wardId';
                    $(wardId).html('');
                    $(wardId).append(`<option value="">All District</option>`);

                    const listWard = response.ward;
                    $.each(listWard, function (i, val) {
                        let ward = '';
                        if (wardIdDb === val.code) {
                            ward = `<option value=${val.code} selected>${val.name}</option>`;
                        } else {
                            ward = `<option value=${val.code}>${val.name}</option>`;
                        }
                        $(wardId).append(ward);
                    });
                },
            });
        }
    }

    modules.selectInWardEdit = function (districtId, index) {
        let editWardClass = '.edit-ward-area-';
        let param;

        if (districtId) {
            let wardIdDb = parseInt($(editWardClass + index).val());

            param = {
                district_id: [districtId]
            }
            $.ajax({
                type: "GET",
                url: "/ward",
                data: param,
                dataType: "json",
                success: function (response) {
                    let wardId = $(editWardClass + index).parent().children('.ward-id');
                    $(wardId).html('');
                    $(wardId).append(`<option value="">Ward</option>`);

                    const listWard = response.ward;
                    $.each(listWard, function (i, val) {
                        let ward = '';
                        if (wardIdDb === val.code) {
                            ward = `<option value=${val.code} selected>${val.name}</option>`;
                        } else {
                            ward = `<option value=${val.code}>${val.name}</option>`;
                        }
                        $(wardId).append(ward);
                    });
                },
            });
        }
    }

    modules.setNationProvinceDistrict = function () {
        let nation = $('#countryId').children("option:selected").html();
        if (nation === 'Nation') {
            nation = '';
        }

        let state = $('#stateId').children("option:selected").html() + ', ';
        if (state === 'Province/City, ') {
            state = '';
        }

        let city = $('#cityId').children("option:selected").html() + ', ';
        if (city === 'District, ') {
            city = '';
        }

        $('#expert_nation_province_district').val(city + state + nation);
    }

    modules.setDefaultProvinceDistrict = function () {
        let province = $('#edit-province').val()
        let district = $('#edit-district').val()
        if ((province.length !== 0) || (district.length !== 0)) {
            if(province.length !== 0) {
                $.ajax({
                    type: "GET",
                    url: "/city",
                    dataType: "json",
                    success: function (response) {
                        let stateId = '#stateId';
                        $(stateId).html('');
                        $(stateId).append(`<option value="">All Province/City</option>`);

                        const listState = response.city;
                        $.each(listState, function (i, val) {
                            let state = '';
                            if (province === val.code) {
                                state = `<option value=${val.code} selected>${val.name}</option>`;
                            } else {
                                state = `<option value=${val.code}>${val.name}</option>`
                            }
                            $(stateId).append(state);
                        });
                    },
                });
            }

            if (province.length !== 0 && district.length !== 0) {
                $.ajax({
                    type: "GET",
                    url: "/district",
                    data: { city_id: [district] },
                    dataType: "json",
                    success: function (response) {
                        let cityId = '#cityId';
                        $(cityId).html('');
                        $(cityId).append(`<option value="">All District</option>`);

                        const listState = response.district;
                        $.each(listState, function (i, val) {
                            let city = '';
                            if (district === val.code) {
                                city = `<option value=${val.code} selected>${val.name}</option>`;
                            } else {
                                city = `<option value=${val.code}>${val.name}</option>`;
                            }
                            $(cityId).append(city);
                        });
                    },
                });
            }
        }
    }

    return modules;
}(window.jQuery, window, document));

$(document).ready(function () {
    $(document).on('change', '#countryId', function () {
        let checkPageToCallListState = $('#stateId').val();
        if (typeof checkPageToCallListState !== 'undefined') {
            if ($('#current_admin_province_id').val() == "") {
                let stateId = '#stateId';

                $(stateId).html('');
                $(stateId).append(`<option value="">Province/City</option>`);

                let cityId = '#cityId';
                $(cityId).html('');
                $(cityId).append(`<option value="">District</option>`);

                let wardId = '#wardId';
                $(wardId).html('');
                $(wardId).append(`<option value="">Ward</option>`);

                let countryId = $(this).children("option:selected").val();
                Expert.getListState(countryId);
            }
        }
    });

    $(document).on('change', '.country-id', function () {
        let checkPageToCallListState = $('.state-id').val();
        if (typeof checkPageToCallListState !== 'undefined') {
            if ($('#current_admin_province_id').val() == "") {
                let stateId = '.state-id';

                $(stateId).html('');
                $(stateId).append(`<option value="">Province/City</option>`);

                let cityId = '.city-id';
                $(cityId).html('');
                $(cityId).append(`<option value="">District</option>`);

                let wardId = '.ward-id';
                $(wardId).html('');
                $(wardId).append(`<option value="">Ward</option>`);

                let countryId = $(this).children("option:selected").val();
                Expert.getListState(countryId);
            }
        }
    });

    $(document).on('change', '#stateId', function () {
        let checkPageToCallListCity = $('#cityId').val();
        if (typeof checkPageToCallListCity !== 'undefined') {
            let cityId = '#cityId';
            $(cityId).html('');
            $(cityId).append(`<option value="">District</option>`);

            let wardId = '#wardId';
            $(wardId).html('');
            $(wardId).append(`<option value="">Ward</option>`);

            let countryId = $('#countryId').children("option:selected").val();
            let stateId = $(this).children("option:selected").val();
            let currentAdminDistrictId = $('#current_admin_district_id').val();
            Expert.getListCity(countryId, stateId, currentAdminDistrictId);
        }
    });

    $(document).on('change', '.state-id', function () {
        let checkPageToCallListCity = $('.city-id').val();
        if (typeof checkPageToCallListCity !== 'undefined'){
            let parentElement = $(this).closest('.col-md-11');

            let citySelect = parentElement.find('.city-id');
            let wardSelect = parentElement.find('.ward-id');

            $(citySelect).html('');
            $(citySelect).append(`<option value="">District</option>`);

            $(wardSelect).html('');
            $(wardSelect).append(`<option value="">Ward</option>`);

            let countryId = $('#countryId').children("option:selected").val();
            let stateId = $(this).children("option:selected").val();
            let currentAdminDistrictId = $('#current_admin_district_id').val();

            Expert.getListCity(countryId, stateId, currentAdminDistrictId, citySelect);
        }
    });

    $(document).on('change', '#cityId', function () {
        let checkPageToCallListWard = $('#wardId').val();
        if (typeof checkPageToCallListWard !== 'undefined') {
            let wardId = '#wardId';
            $(wardId).html('');
            $(wardId).append(`<option value="">Ward</option>`);
            let districtId = $(this).children("option:selected").val();
            let currentAdminWardId = $('#current_admin_ward_id').val();
            Expert.getListWard(districtId, currentAdminWardId);
        }
    });

    $(document).on('change', '.city-id', function () {
        let checkPageToCallListWard = $('.ward-id').val();
        if (typeof checkPageToCallListWard !== 'undefined') {
            let parentElement = $(this).closest('.col-md-11');
            let wardSelect = parentElement.find('.ward-id');
            $(wardSelect).html('');
            $(wardSelect).append(`<option value="">Ward</option>`);
            let districtId = $(this).children("option:selected").val();
            let currentAdminWardId = $('#current_admin_ward_id').val();
            Expert.getListWard(districtId, currentAdminWardId, wardSelect);
        }
    });
});

$(document).on("turbolinks:load", function () {
    let formNewExpert = '#new_expert'
    let formEditExpert = '.edit_expert'
    Expert.selectInState();
    Expert.selectInCity();
    Expert.selectInWard();
    Expert.selectInStateEdit();

    $(document).on('change', '.input-custom-style, .textarea-custom', function () {
        $(this).val($(this).val().trim());
    });

    $(document).on('click', '.btn-lock', function () {
        Expert.lockAccount();
    });

    $(document).on('click', '.btn-unlock', function () {
        Expert.unlockAccount();
    });

    // show hide password expert
    $(document).on('click', '.expert_new_password', function () {
        togglePassword('.new_password', '#expert_password');
    });

    $(document).on('click', '.expert_confirm_password', function () {
        togglePassword('.password_confirmation', '#expert_password_confirmation');
    });

    // check image before upload
    $(document).on('change', '#expert_avatar', function () {
        previewImage($(this)[0], '.img_preview');
        if ($(this).valid()) {
            $("#expert_avatar .error").removeClass('error');
        }
    });

    $(document).on('click', '.btn-download-csv-chat', function() {
        let loadingIcon = $('.gif-loader');
        const checkboxs = document.querySelector('.checkbox-id-expert:checked');
        loadingIcon.show();
        if (!checkboxs) {
            loadingIcon.remove();
            alert('Please select the experts to download the file.');
            return;
        }
        $('form#download-csv-chat').submit();
        loadingIcon.remove();

        // Expert.loading(false)
    })

    // validate form new_expert
    validate_form_create(formNewExpert);

    function validate_form_create(form_id) {
        $(form_id).validate({
            ignore: [],
            onfocusout: function (element) {
                this.element(element);
            },
            errorElement: "p",
            normalizer: function (value) {
                return $.trim(value);
            },
            rules: {
                // account tab pane
                'expert[username]': {
                    required: true,
                    rangelength: [6, 20],
                    nowhitespace: true,
                    validUsername: true,
                    remote: {
                        url: '/management/account/experts/check_unique_fields',
                        type: 'get',
                        data: {
                            username: function () {
                                return $('#expert_username').val()
                            }
                        }

                    }
                },
                'expert[password]': {
                    required: true,
                    minlength: 6,
                    maxlength: 20,
                    validPassword: true
                },
                'expert[password_confirmation]': {
                    required: true,
                    equalTo: "#expert_password"
                },

                // personal tab pane
                "expert[avatar]": {
                    extension: "png|jpg|jpeg|gif",
                    maxsize: 5242880
                },

                'expert[account_type]': {
                    selectRequired: '0',
                },
                'expert[full_name]': {
                    required: true,
                    maxlength: 30
                },
                'expert[ward]': {
                    maxlength: 255,
                },
                'expert[address_detail]': {
                    maxlength: 255,
                },
                'expert[phone_number]': {
                    required: true,
                    digits: true,
                    rangelength: [7, 20],
                    remote: {
                        url: '/management/account/experts/check_unique_fields',
                        type: 'get',
                        data: {
                            phone_number: function () {
                                return $('#expert_phone_number').val()
                            }
                        }

                    }
                },
                'expert[email]': {
                    required: true,
                    maxlength: 255,
                    email: true,
                    remote: {
                        url: '/management/account/experts/check_unique_fields',
                        type: 'get',
                        data: {
                            email: function () {
                                return $('#expert_email').val()
                            }
                        }

                    }
                },
                'expert[technique]': {
                    maxlength: 1000,
                },
                'expert[introduction]': {
                    maxlength: 1000,
                },
                'expert[other_info]': {
                    maxlength: 1000,
                },

                // services tab pane
                'expert[consultant_fee]': {
                    required: true,
                    number: true,
                    min: 0,
                    maxlength: 10
                },
                'point[]': {
                    required: true,
                    maxlength: 10,
                    digits: true
                },
            },
            messages: {
                "expert[avatar]": {
                    extension: "Image must be a PNG or JPG or JPEG or GIF",
                    maxsize: "File size must not exceed 5MB"
                },
                'expert[username]': {
                    remote: $.validator.format("Username {0} is already exists")
                },
                'expert[phone_number]': {
                    remote: $.validator.format("Phone number {0} is already exists")
                },
                'expert[email]': {
                    remote: $.validator.format("Email {0} is already exists")
                }
            },

            submitHandler: function (form) {
                Expert.setNationProvinceDistrict();
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }

    // validate form edit_expert
    validate_form_edit(formEditExpert);

    function validate_form_edit(form_id) {
        $(form_id).validate({
            ignore: [],
            errorElement: "p",
            onfocusout: function (element) {
                this.element(element);
            },
            normalizer: function (value) {
                return $.trim(value);
            },
            rules: {
                // account tab pane
                'expert[password]': {
                    minlength: 6,
                    maxlength: 20,
                    validPassword: true
                },
                'expert[password_confirmation]': {
                    equalTo: "#expert_password"
                },

                // personal tab pane
                "expert[avatar]": {
                    extension: "png|jpg|jpeg|gif",
                    maxsize: 5242880
                },
                'expert[account_type]': {
                    selectRequired: '0',
                },
                'expert[full_name]': {
                    required: true,
                    maxlength: 30
                },
                'expert[ward]': {
                    maxlength: 255,
                },
                'expert[address_detail]': {
                    maxlength: 255,
                },
                'expert[phone_number]': {
                    required: true,
                    digits: true,
                    rangelength: [7, 20],
                    remote: {
                        url: '/management/account/experts/check_unique_fields',
                        type: 'get',
                        data: {
                            phone_number: function () {
                                return $('#expert_phone_number').val()
                            },
                            id: function () {
                                return $('#expert_id').val()
                            }
                        }

                    }
                },
                'expert[email]': {
                    required: true,
                    maxlength: 255,
                    email: true,
                    remote: {
                        url: '/management/account/experts/check_unique_fields',
                        type: 'get',
                        data: {
                            email: function () {
                                return $('#expert_email').val()
                            },
                            id: function () {
                                return $('#expert_id').val()
                            }
                        }

                    }
                },
                'expert[technique]': {
                    maxlength: 1000,
                },
                'expert[introduction]': {
                    maxlength: 1000,
                },
                'expert[other_info]': {
                    maxlength: 1000,
                },

                // services tab pane
                'expert[consultant_fee]': {
                    required: true,
                    number: true,
                    min: 0,
                    maxlength: 10
                },
                'point[]': {
                    required: true,
                    maxlength: 10,
                    digits: true
                },
            },
            messages: {
                "expert[avatar]": {
                    extension: "Image must be a PNG or JPG or JPEG or GIF",
                    maxsize: "File size must not exceed 5MB"
                },
                'expert[phone_number]': {
                    remote: $.validator.format("Phone number {0} is already exists")
                },
                'expert[email]': {
                    remote: $.validator.format("Email {0} is already exists")
                }
            },

            submitHandler: function (form) {
                Expert.setNationProvinceDistrict();
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }
});