var HospitalClinic = (function () {
    let modules = {};

    modules.validationImage = function (obj) {
        $('.err-message').text('');
        let fileName = obj.val().replace(/.*[\/\\]/, '');
        let file = obj.prop('files')[0];
        let imgElement = obj.parent().parent().find('.img-style');
        let extension = file.type.split('/').pop().toLowerCase();
        let errorElement = obj.parent().parent().find('.err-message');

        if (extension !== "jpeg" && extension !== "jpg" && extension !== "png" && extension !== "gif") {
            errorElement.text('Image must be a PNG or JPG or JPEG or GIF');
            $(obj).val('');
            return false;
        } else if (file.size > 5 * 1024 * 1000) {
            errorElement.text('File size must not exceed 5MB');
            $(obj).val('');
            return false;
        } else if (fileName.length > 0) {
            let img = $('<img>', {
                src: URL.createObjectURL(file)
            })

            imgElement.html('');
            imgElement.append(img);
        } else {
            imgElement.html('');
            imgElement.append('<i class="fas fa-plus"></i>');
        }
    }

    modules.getIdToDeleteItem = function (obj) {
        $('#hospital-id').val(obj.attr("data-id"));
        $('#product-id').val(obj.attr("data-id"));
        $('#reference-reading-id').val(obj.attr("data-id"));
        $('#question-id').val(obj.attr("data-id"));
        $('#assessment-set-id').val(obj.attr("data-id"));
        $('#assessment-question-id').val(obj.attr("data-id"));
        $('#assessment-question-add-id').val(obj.attr("data-add-id"));
        $('#assessment-set-question-add-id').val(obj.attr("data-assessment-set-id"));
    }

    modules.deleteHospitalClinic = function () {
        let id = $('#hospital-id').val();

        if (isNaN(id)) {
            return false;
        }

        $.ajax({
            type: 'DELETE',
            url: '/management/master/hospital-clinics/' + id,
            data: {
                id: id
            }
        });
    }

    modules.setNationProvinceDistrict = function () {
        let nation = $('#countryId').children("option:selected").html();
        if (nation === 'Nation') {
            nation = '';
        }

        let state = $('#stateId').children("option:selected").html() + ', ';
        if (state === 'Province/City, ') {
            state = '';
        }

        let city = $('#cityId').children("option:selected").html() + ', ';
        if (city === 'District, ') {
            city = '';
        }

        $('#hospital_clinic_nation_province_district').val(city + state + nation);
    }

    modules.validate_form = function (form) {
        $(form).validate({
            ignore: [],
            onfocusout: function (element) {
                this.element(element);
            },
            errorElement: "span",
            normalizer: function (value) {
                return $.trim(value);
            },
            rules: {
                // account tab pane
                'hospital_clinic[is_hospital]': {
                    required: true,
                },
                'hospital_clinic[name]': {
                    required: true,
                    maxlength: 255,
                },
                'hospital_clinic[nationality]': {
                    required: true,
                },
                "hospital_clinic[province]": {
                    required: true,
                },
                "hospital_clinic[district]": {
                    required: true,
                },
                "hospital_clinic[address_detail]": {
                    required: true,
                },
                'hospital_clinic[phone_number]': {
                    digits: true,
                    rangelength: [7, 15]
                },
                'hospital_clinic[hotline]': {
                    digits: true,
                    rangelength: [7, 15]
                },
                'hospital_clinic[email]': {
                    maxlength: 255,
                    email: true
                },
                'hospital_clinic[website]': {
                    url: true
                },
                'hospital_clinic[workday]': {
                    maxlength: 150
                },
                'hospital_clinic[timework]': {
                    maxlength: 150
                },
                'hospital_clinic[introduction]': {
                    maxlength: 1000
                },
            },

            submitHandler: function (form) {
                HospitalClinic.setNationProvinceDistrict();
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }

    return modules;
}(window.jQuery, window, document));

$(document).on("turbolinks:load", function () {

    $(document).on('click', '.btn-search', function () {
        $('.form-search').submit();
        $(this).attr("disabled", true);
    });

    $(document).on('click', '.btn-delete', function () {
        HospitalClinic.getIdToDeleteItem($(this));
    });

    $(document).on('click', '.btn-confirm-delete', function () {
        HospitalClinic.deleteHospitalClinic();
    });

    // validate form create and edit
    HospitalClinic.validate_form('#new_hospital_clinic')
    HospitalClinic.validate_form('.edit_hospital_clinic')

    $(document).on('change', '.file-custom', function () {
        HospitalClinic.validationImage($(this));
    });
    //end validate
});
