let MoodScale = (function () {
    let modules = {};

    modules.validateFormEdit = function (form_id) {
        $(form_id).validate({
            onfocusout: function (element) {
                this.element(element);
            },
            onkeyup: function (element) {
                $(element).valid()
            },
            errorElement: "p",
            rules: {
                'question[language]': {
                    required: true,
                    maxlength: 255
                },
                'question[note]': {
                    maxlength: 1000
                },
            },
            messages: {},
            submitHandler: function (form) {
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }

    modules.setDataInModalEditChange = function () {
        $('#question-edit-vn').val($('.lg-vietnamese input').val());
        $('#question-edit-en').val($('.lg-english input').val());
        $('#question-edit-sp').val($('.lg-spanish input').val());
        $('#question-edit-note').val($('.question-note').val());
    }

    modules.setDataModalInView = function () {
        let modalTextVn = $('#question-edit-vn');
        let modalTextEn = $('#question-edit-en');
        let modalTextSp = $('#question-edit-sp');
        let modalTextNote = $('#question-edit-note');

        if (modalTextVn.valid() && modalTextEn.valid() && modalTextSp.valid() && modalTextNote.valid()) {
            $('.lg-vietnamese :input').val(modalTextVn.val());
            $('.lg-english :input').val(modalTextEn.val());
            $('.lg-spanish :input').val(modalTextSp.val());
            $('.question-note').val(modalTextNote.val());

            $('#edit-change').modal('hide');
        }
    }

    return modules
}(window.jQuery, window, document));

$(document).on("turbolinks:load", function () {

    // validate form except answer_translation[]
    MoodScale.validateFormEdit('#edit_mood_scale')

    // validate for answer_translation[]
    $('[name*="answer_translations"]').each(function () {
        $(this).rules('add', {
            required: true,
            maxlength: 255
        });
    });

    $(document).on('click', '#btn-submit-modal-edit-assessment-question', function () {
        MoodScale.setDataModalInView();
    });

    $(document).on('click', '.mood-scale', function () {
        MoodScale.setDataInModalEditChange();
    });
})
