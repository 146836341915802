$(document).ready(function () {
    let list_id_remove = [];

    function showNotification(text, thisElement, elementError) {
        if (text === '') {
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text('This field is required.');
            return false;
        } else if (text.length > 2000) {
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text('Please enter no more than 2000 characters.');
            return false;
        } else {
            $(thisElement).removeClass('err-input');
            elementError.removeClass('msg-error').text('');
            return true;
        }
    }

    function showNotificationTrial(text, thisElement, elementError) {
        if (text === '') {
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text('This field is required.');
            return false;
        } else if (text.length > 20000) {
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text('Please enter no more than 20000 characters.');
            return false;
        } else {
            $(thisElement).removeClass('err-input');
            elementError.removeClass('msg-error').text('');
            return true;
        }
    }

    function trimParamForm() {
        $.each($('.container-modal-setting .modal-settings-container textarea'), async (index, element) => {
            $(element).val($(element).val().trim());
        });
    }

    function scrollToStep (top, left) {
        $('html, body').animate({
            scrollTop: top,
            scrollLeft: left
        });
    }

    function resetOrderFormTrial(parent) {
        $.each(parent.find('.form-modal-trial'), (index, value) => {
            $(value).find('.text-decoration-none').text(`Step ${index + 1}`);
            $(value).find('.order-trial').val(index + 1);
        })
    }

    function getHtml(random_id) {
        return `
            <div class="d-flex w-100 form-modal-trial">
                <div class="ml-4 mr-3 w-90">
                    <a data-toggle="collapse" href="#collapse-modal-trial-${random_id}" role="button" aria-expanded="true" aria-controls="collapse-modal-trial-${random_id}" class="with-chevron">
                        <div class="d-flex align-items-center justify-content-between question-set-title title-outline-style bg-banana">
                            <div class="text-decoration-none">Step 1</div><i class="fa fa-angle-down"></i>
                        </div>
                    </a>

                    <div id="collapse-modal-trial-${random_id}" class="collapse show">
                        <div class="form-video m-4">
                            <div class="control">
                                <h3 class="">Header:</h3>
                            </div>
                            <div class="m-4">
                                <div class="video-item mb-2">
                                    <p class="">Vietnamese:</p>
                                    <textarea name="modal_trial[${random_id}[header_vn]]" class="title-modal" rows="4"></textarea>
                                    <span class="error-answer color-red"></span>
                                </div>
                                <div class="video-item mb-2">
                                    <p class="">English:</p>
                                    <textarea name="modal_trial[${random_id}[header_en]]" class="title-modal" rows="4"></textarea>
                                    <span class="error-answer color-red"></span>
                                </div>
                                <div class="video-item mb-2">
                                    <p class="">Spanish:</p>
                                    <textarea name="modal_trial[${random_id}[header_sp]]" class="title-modal" rows="4"></textarea>
                                    <span class="error-answer color-red"></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-text m-4">
                            <div class="control">
                                <h3 class="">Text:</h3>
                            </div>
                            <div class="m-4">
                                <div class="text-item mb-2">
                                    <p class="">Vietnamese:</p>
                                    <textarea name="modal_trial[${random_id}[content_vn]]" class="content-modal" rows="4"></textarea>
                                    <span class="error-answer color-red"></span>
                                </div>
                                <div class="text-item mb-2">
                                    <p class="">English:</p>
                                    <textarea name="modal_trial[${random_id}[content_en]]" class="content-modal" rows="4"></textarea>
                                    <span class="error-answer color-red"></span>
                                </div>
                                <div class="text-item mb-2">
                                    <p class="">Spanish:</p>
                                    <textarea name="modal_trial[${random_id}[content_sp]]" class="content-modal" rows="4"></textarea>
                                    <span class="error-answer color-red"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-lg-end align-items-start mr-2 group-btn">
                    <div class="remove-template remove-template-1 mr-3">
                        <i class="fas fa-minus text-danger"></i>
                    </div>
                    <div class="add-template">
                        <i class="fas fa-plus color-2faf6b"></i>
                    </div>
                </div>
                
                <input type="hidden" name="modal_trial[${random_id}[order]]" class="order-trial" value="1">
            </div>
        `;
    }

    $(document).on('click', '.container-modal-setting .create-modal-setting', async function (){
        let valid = true;
        let id = $(this).attr('data-id')
        $(`.container-modal-setting .modal-settings-container .modal-text-${id}`).map( function() {
            let check = showNotification($(this).val().trim(), $(this), $(this).parent().find('.error-text'));
            if (check == false){
                valid = false;
            }
        })

        if (valid === true) {
            let form = $(this).parents('form');
            await trimParamForm();
            await form.submit();
        } else {
            let element = $('.container-modal-setting .modal-settings-container .msg-error:first');
            element.focus();
            scrollToStep(element.offset().top - 200, element.offset().left);
        }
    });

    $(document).on('click', '.tab-pane.active .group-form-modal-trial .remove-template', function() {
        let div = $(this).parent().parent();
        let parent = $(this).parents('.form-sub');

        if (div.find('.id-record').length) {
            list_id_remove.push(div.find('.id-record').val());
        }

        div.remove();
        resetOrderFormTrial(parent);
    });

    $(document).on('click', '.tab-pane.active .group-form-modal-trial .add-template', function () {
        let parent = $('.tab-pane.active .group-form-modal-trial .form-sub');
        let random_id = Math.floor(Math.random() * 1000000);
        parent.append(getHtml(random_id));
        scrollToStep(parent.find('.form-modal-trial:last-child').offset().top - 200, 0)
        resetOrderFormTrial(parent);
    });

    $(document).on('click', '.container-modal-setting .btn-submit-form-trial', async function (){
        let valid = true;

        $(`.container-modal-setting .tab-pane.active textarea`).map( function() {
            $(this).val($(this).val().trim());
            let check = showNotificationTrial($(this).val().trim(), $(this), $(this).parent().find('.error-text'));
            if (!check){ valid = false; }
        })

        if (valid === true) {
            let form = $(this).parents('form');
            list_id_remove.map((val) => {
                form.append(`<input type="hidden" name="id_remove[]" value="${val}">`)
            })
            await form.submit();
        } else {
            let element = $('.container-modal-setting .tab-pane.active .msg-error:first');
            scrollToStep(element.offset().top - 200, element.offset().left)
        }
    });
})