let SubAdminAccount = (function () {
    let modules = {};

    modules.getDataPermissions = function () {
        let id = $('#admin_classification_id').val();

        function show_permission_type(value) {
            return ['Please choose', 'Account management', 'Master management', 'Data management', 'Setting'][value]
        }

        $.ajax({
            type: 'GET',
            url: `/management/account/administrator/sub-admin-accounts/${id}/data_permissions`,
            success: function (response) {
                let data = response.permissions_of_classification
                let showPermission = $('#show_permissions')
                let show_permission_title = $('#show_permission_title')

                showPermission.html('')
                show_permission_title.html('')
                show_permission_title.append('<span class="font-weight-bold">Permission allowed list:</span>')

                // group permission_types
                let newArr = [];
                response.permissions.map((value) => {
                    let arr2 = response.permissions.filter(e => e.permission_type === value.permission_type);
                    for (let i = 0; i < newArr.length; i++) {
                        if (newArr[i][0] === arr2[0] && newArr[i][1] === arr2[1]) {
                            return false;
                        }
                    }
                    newArr.push(arr2);
                });

                $.each(newArr, function (key, value) {
                    let html1 = "";
                    $.each(value, function (index, item) {
                        html1 += `<div class="col-12 checkbox-custom" data-id="${item.id}">
                                      <i class="far fa-square"></i>
                                      <label class="permission">${item.name}</label>
                                  </div>`;
                    })

                    showPermission.append(`
                        <div class="col-12 col-xl-6 permission-list">
                          <div class="container-outline-style m20b">
                            <div class="role-style">
                              <div class="col-12 checkbox-custom p0 permission_type_head">
                                <i class="far fa-square"></i>
                                <label class="permission_title">${show_permission_type(value[0].permission_type)}</label>
                              </div>
                            </div>
                            <div class="row m0 text-center content-outline-style permission_type_group">
                                ${html1}
                            </div>
                          </div>
                        </div>
                    `)
                });

                // checked permissions
                $.each(data, function (key, value) {
                    let permission_checked = $('[data-id="' + value.id + '"]')
                    permission_checked.children('.fa-square').remove();
                    permission_checked.prepend('<i class="fas fa-check-square is-check"></i>');
                });

                // checked parent element if any chill element is checked
                let permissionElement = $(document).find('.permission-list')
                $.each(permissionElement, function (key, value) {
                    if ($(value).find('.is-check').length !== 0) {
                        let permission_type_head = $(value).find('.permission_type_head')
                        permission_type_head.children('.fa-square').remove();
                        permission_type_head.prepend('<i class="fas fa-check-square"></i>');
                    }
                })
            },

        });
    }

    modules.validateFormCreate = function (form_id) {
        $(form_id).validate({
            onfocusout: function (element) {
                this.element(element);
            },
            onkeyup: function (element) {
                $(element).valid()
            },
            errorElement: "p",
            rules: {
                "admin[username]": {
                    required: true,
                    nowhitespace: true,
                    validUsername: true,
                    rangelength: [6, 20],
                    remote: {
                        url: '/management/account/administrator/sub-admin-accounts/check_duplicate_username',
                        type: "get",
                        data: {
                            username: function () {
                                return $('#admin_username').val();
                            },
                        }
                    }
                },
                "admin[password]": {
                    required: true,
                    rangelength: [6, 20],
                    validPassword: true
                },
                "admin[password_confirmation]": {
                    required: true,
                    equalTo: "#admin_password"
                },
                "admin[full_name]": {
                    required: true,
                    maxlength: 50
                },
                "admin[classification_id]": {
                    required: true
                },
                "admin[note]": {
                    maxlength: 1000
                },

            },
            messages: {
                "admin[username]": {
                    remote: $.validator.format("Username {0} is already exists")
                }
            },
            submitHandler: function (form) {
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }

    modules.validateFormEdit = function (form_id) {
        $(form_id).validate({
            onfocusout: function (element) {
                this.element(element);
            },
            onkeyup: function (element) {
                $(element).valid()
            },
            errorElement: "p",
            rules: {
                "admin[username]": {
                    required: true,
                    nowhitespace: true,
                    validUsername: true,
                    rangelength: [6, 20],
                    remote: {
                        url: '/management/account/administrator/sub-admin-accounts/check_duplicate_username',
                        type: "get",
                        data: {
                            username: function () {
                                return $('#admin_username').val();
                            },
                            id: function () {
                                return $('#admin_id').val();
                            }
                        }
                    }
                },
                "admin[password]": {
                    rangelength: [6, 20],
                    validPassword: true
                },
                "admin[password_confirmation]": {
                    equalTo: "#admin_password"
                },
                "admin[full_name]": {
                    required: true,
                    maxlength: 50
                },
                "admin[classification_id]": {
                    required: true
                },
                "admin[note]": {
                    maxlength: 1000
                },
            },
            messages: {
                "admin[username]": {
                    remote: $.validator.format("Username {0} is already exists")
                }
            },
            submitHandler: function (form) {
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }

    modules.lockAccount = function () {
        let statusAccount = $('.status-account');

        $('#admin_status').val('Locked');
        statusAccount.removeClass('status-active');
        statusAccount.removeClass('status-none');
        statusAccount.addClass('status-locked');
        statusAccount.text('Locked');
        $('.btn-show-modal-lock').attr('hidden', true);
        $('.btn-show-modal-unlock').attr('hidden', false);
    }

    modules.unlockAccount = function () {
        let statusAccount = $('.status-account');

        $('#admin_status').val('Active');
        statusAccount.removeClass('status-locked');
        statusAccount.removeClass('status-none');
        statusAccount.addClass('status-active');
        statusAccount.text('Active');
        $('.btn-show-modal-unlock').attr('hidden', true);
        $('.btn-show-modal-lock').attr('hidden', false);
    }

    return modules
}(window.jQuery, window, document));

$(document).on("turbolinks:load", function () {
    // show permissions list when classification chosen
    let accountType = $('#admin_classification_id')

    if ($(this).find('.edit_admin').length !== 0) {
        SubAdminAccount.getDataPermissions();
    }

    accountType.on('change', function () {
        if ($(this).val() === '') {
            $('#show_permissions').html('')
            $('#show_permission_title').html('')
        } else {
            SubAdminAccount.getDataPermissions();
        }
    });

    // show or hide password
    $("#sub_admin_password").click(function () {
        togglePassword('.new_password', '#admin_password')
    });

    $("#sub_admin_confirm_password").click(function () {
        togglePassword('.password_confirmation', '#admin_password_confirmation')
    });

    // validate form
    SubAdminAccount.validateFormCreate('#new_admin')
    SubAdminAccount.validateFormEdit('.edit_admin')

    //validate username field when it has flash message
    handleActiveRecordValidation('#admin_username', '.flash_error')

    // lock or unlock sub admin account
    $('.btn-lock').on('click', function () {
        SubAdminAccount.lockAccount();
    });

    $('.btn-unlock').on('click', function () {
        SubAdminAccount.unlockAccount();
    });
})
