const MAX_LENGTH = 250

let readings = (function () {
    let modules = {};

    modules.readURL = function (input) {
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            reader.onload = function (e) {
                $('#img-preview').attr('src', e.target.result);
            };
            reader.readAsDataURL(input.files[0]); // convert to base64 string
        }
    };

    modules.destroyReferenceReading = function () {
        let id = $('#reference-reading-id').val();
        console.log(id)

        if (isNaN(id)) {
            return false;
        }

        $.ajax({
            type: 'DELETE',
            url: '/management/master/reference-readings/' + id,
            data: {
                id: id
            }
        });
    };

    return modules;
}(window.jQuery, window, document));

$(document).on("turbolinks:load", function () {
    let form_update = '#form_update_readings'
    let form_create = '#form_create_readings'
    $('#img-preview').click(function () {
        $('#img-readings').trigger('click');
    })

    $('#img-readings').on('change', function () {
        previewImage($(this)[0], $('.img-preview'));
        if ($(this).valid()) {
            $("#img-readings .error").removeClass('error')
        }
    })

    validate_form(form_update)
    validate_form(form_create)
    // validate for input elements into form create/edit activity types
    function validate_form(form_id) {
        $(form_id).validate({
            onfocusout: function (element) {
                this.element(element);
            },
            normalizer: function (value) {
                return $.trim(value);
            },
            errorElement: "p",
            rules: {
                'readings_name[]': {
                    required: true,
                    maxlength: 250
                },
                'readings_image': {
                    extension: "png|jpg|jpeg|gif",
                    maxsize: 5242880
                },
                'reference_link': {
                    required: true,
                    url: true
                },
            },
            messages: {
                "readings_image": {
                    extension: "Image must be a PNG or JPG or JPEG or GIF",
                    maxsize: "File size must not exceed 5MB"
                },
            },
            submitHandler: function (form) {
                $(".submit").attr("disabled", true);
                form.submit();
            }
        });
    }

    $(document).on('click', '.btn-confirm-delete-reading', function () {
        readings.destroyReferenceReading();
    });
});