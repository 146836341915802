$(document).ready(function () {
    let form_problem_solving = $('#problem-solving').html()
    let form_reactivate_your_life = $('#reactivate-your-life').html()
    let form_positive_thinking_practice = $('#positive-thinking-practice').html()
    let form_relap_prevention = $('#relap-prevention').html()
    let form_introduction_vmoop = $('#introduction-vmoop').html()
    let form_introduction_depression = $('#introduction-depression').html()

    $(document).on('click', ".tab-problem-solving:not('.active')", function () {
        $('#problem-solving').html(form_problem_solving)
    })

    $(document).on('click', ".tab-reactivate-your-life:not('.active')", function () {
        $('#reactivate-your-life').html(form_reactivate_your_life)
    })

    $(document).on('click', ".tab-positive-thinking-practice:not('.active')", function () {
        $('#positive-thinking-practice').html(form_positive_thinking_practice)
    })

    $(document).on('click', ".tab-relap-prevention:not('.active')", function () {
        $('#relap-prevention').html(form_relap_prevention)
    })

    $(document).on('click', ".tab-introduction-depression:not('.active')", function () {
        $('#introduction-depression').html(form_introduction_depression)
    })

    $(document).on('click', ".tab-introduction-vmoop:not('.active')", function () {
        $('#introduction-vmoop').html(form_introduction_vmoop)
    })

    function getHtml(class_form, length_temp, name_help_document) {
        return `
            <div class="d-flex w-100" id="step-${name_help_document}-${length_temp}">
                <div class="${class_form} ml-4 mr-3 w-90"><br>
                  <a data-toggle="collapse" href="#collapse-${name_help_document}-${length_temp}" role="button" aria-expanded="true" aria-controls="collapse-${name_help_document}-${length_temp}" class="with-chevron">
                    <div class="d-flex align-items-center justify-content-between question-set-title title-outline-style bg-banana">
                      <div class="text-decoration-none">Step ${length_temp}</div><i class="fa fa-angle-down"></i>
                    </div>
                  </a>
    
                  <div id="collapse-${name_help_document}-${length_temp}" class="collapse show">
                    <div class="form-video m-4">
                      <div class="control">
                        <h3 class="">Video ID from Youtube:</h3>
                      </div>
                      <div class="m-4">
                        <div class="video-item mb-2">
                          <p class="">Vietnamese:</p>
                          <input name="problem_solving[step_${length_temp}[link_vn]]" class="video-type link-youtube lang-vn" rows="4">
                          <span class="error-answer color-red"></span>
                        </div>
    
                        <div class="video-item mb-2">
                          <p class="">English:</p>
                          <input name="problem_solving[step_${length_temp}[link_en]]" class="video-type link-youtube lang-en" rows="4">
                          <span class="error-answer color-red"></span>
                        </div>
    
                        <div class="video-item mb-2">
                          <p class="">Spanish:</p>
                          <input name="problem_solving[step_${length_temp}[link_sp]]" class="video-type link-youtube lang-sp" rows="4">
                          <span class="error-answer color-red"></span>
                        </div>
                      </div>
                    </div>
                    <div class="form-video m-4">
                      <div class="control">
                        <h3 class="">Header:</h3>
                      </div>
                      <div class="m-4">
                        <div class="video-item mb-2">
                          <p class="">Vietnamese:</p>
                          <textarea name="problem_solving[step_${length_temp}[header_video_vn]]" class="video-type lang-vn" rows="4"></textarea>
                          <span class="error-answer color-red"></span>
                        </div>
    
                        <div class="video-item mb-2">
                          <p class="">English:</p>
                          <textarea name="problem_solving[step_${length_temp}[header_video_en]]" class="video-type lang-en" rows="4"></textarea>
                          <span class="error-answer color-red"></span>
                        </div>
    
                        <div class="video-item mb-2">
                          <p class="">Spanish:</p>
                          <textarea name="problem_solving[step_${length_temp}[header_video_sp]]" class="video-type lang-sp" rows="4"></textarea>
                          <span class="error-answer color-red"></span>
                        </div>
                      </div>
                    </div>
                    <div class="form-text m-4">
                      <div class="control">
                        <h3 class="">Text:</h3>
                      </div>
                      <div class="m-4">
                        <div class="text-item mb-2">
                          <p class="">Vietnamese:</p>
                          <textarea name="problem_solving[step_${length_temp}[title_vn]]" class="video-type text-type lang-vn" rows="4"></textarea>
                          <span class="error-answer color-red"></span>
                        </div>
    
                        <div class="text-item mb-2">
                          <p class="">English:</p>
                          <textarea name="problem_solving[step_${length_temp}[title_en]]" class="video-type text-type lang-en" rows="4"></textarea>
                          <span class="error-answer color-red"></span>
                        </div>
    
                        <div class="text-item mb-2">
                          <p class="">Spanish:</p>
                          <textarea name="problem_solving[step_${length_temp}[title_sp]]" class="video-type text-type lang-sp" rows="4"></textarea>
                          <span class="error-answer color-red"></span>
                        </div>
                      </div>
                    </div>
    
                  </div>
                </div>
                <div class="d-flex justify-content-lg-end align-items-start mr-2 mt-4">
                  <div class="remove-template remove-template-${length_temp} mr-3">-</div>
                  <div class="add-template">+</div>
                </div>
            </div>
        `;
    }

    function getHtmlIntroductionDepression(class_form, length_temp, name_help_document) {
        return `
            <div class="d-flex w-100" id="step-${name_help_document}-${length_temp}">
                <div class="${class_form} ml-4 mr-3 w-90"><br>
                  <a data-toggle="collapse" href="#collapse-${name_help_document}-${length_temp}" role="button" aria-expanded="true" aria-controls="collapse-${name_help_document}-${length_temp}" class="with-chevron">
                    <div class="d-flex align-items-center justify-content-between question-set-title title-outline-style bg-banana">
                      <div class="text-decoration-none">Step ${length_temp}</div><i class="fa fa-angle-down"></i>
                    </div>
                  </a>
                  <div id="collapse-${name_help_document}-${length_temp}" class="collapse show">
                    <div class="form-video m-4">
                      <div class="control">
                        <h3 class="">Video ID from Youtube:</h3>
                      </div>
                      <div class="m-4">
                        <div class="video-item mb-2">
                          <p class="">Vietnamese:</p>
                          <input name="problem_solving[step_${length_temp}[link_vn]]" class="video-type link-youtube lang-vn" rows="4">
                          <span class="error-answer color-red"></span>
                        </div>
                        <div class="video-item mb-2">
                          <p class="">English:</p>
                          <input name="problem_solving[step_${length_temp}[link_en]]" class="video-type link-youtube lang-en" rows="4">
                          <span class="error-answer color-red"></span>
                        </div>
                        <div class="video-item mb-2">
                          <p class="">Spanish:</p>
                          <input name="problem_solving[step_${length_temp}[link_sp]]" class="video-type link-youtube lang-sp" rows="4">
                          <span class="error-answer color-red"></span>
                        </div>
                      </div>
                    </div>
                    <div class="form-text m-4">
                      <div class="control">
                        <h3 class="">Text:</h3>
                      </div>
                      <div class="m-4">
                        <div class="text-item mb-2">
                          <p class="">Vietnamese:</p>
                          <textarea name="problem_solving[step_${length_temp}[text_vn]]" class="video-type text-type lang-vn" rows="4"></textarea>
                          <span class="error-answer color-red"></span>
                        </div>
                        <div class="text-item mb-2">
                          <p class="">English:</p>
                          <textarea name="problem_solving[step_${length_temp}[text_en]]" class="video-type text-type lang-en" rows="4"></textarea>
                          <span class="error-answer color-red"></span>
                        </div>
                        <div class="text-item mb-2">
                          <p class="">Spanish:</p>
                          <textarea name="problem_solving[step_${length_temp}[text_sp]]" class="video-type text-type lang-sp" rows="4"></textarea>
                          <span class="error-answer color-red"></span>
                        </div>
                      </div>
                    </div>
                    <div class="form-video m-4">
                      <div class="control">
                        <h3 class="">Learn more link:</h3>
                      </div>
                      <div class="m-1 m-4">
                        <div class="video-item mb-2">
                          <p class="">Vietnamese:</p>
                          <input name="problem_solving[step_${length_temp}[learn_more_link_vn]]" class="video-type link-refer lang-vn" rows="4" value="">
                          <span class="error-answer color-red"></span>
                        </div>
                        <div class="video-item mb-2">
                          <p class="">English:</p>
                          <input name="problem_solving[step_${length_temp}[learn_more_link_en]]" class="video-type link-refer lang-en" rows="4" value="">
                          <span class="error-answer color-red"></span>
                        </div>
                        <div class="video-item mb-2">
                          <p class="">Spanish:</p>
                          <input name="problem_solving[step_${length_temp}[learn_more_link_sp]]" class="video-type link-refer lang-sp" rows="4" value="">
                          <span class="error-answer color-red"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-lg-end align-items-start mr-2 mt-4">
                  <div class="remove-template remove-template-${length_temp} mr-3">-</div>
                  <div class="add-template">+</div>
                </div>
            </div>
        `;
    }

    function showNotification(text, thisElement, elementError) {
        let valid = true;
        if (text === '') {
            valid = false;
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text('This field is required.');
            return valid;
        } else if (text.length > 2000) {
            valid = false;
            $(thisElement).addClass('err-input');
            elementError.addClass('msg-error').text('Please enter no more than 2000 characters.');
            return valid;
        } else {
            $(thisElement).removeClass('err-input');
            elementError.removeClass('msg-error').text('');
            return true;
        }
    }

    function validateYouTubeUrl(url) {
        if (url != undefined || url != '') {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length == 11) {
                return true
            }
            return false
        }
    }

    function isUrlValid(url) {
        return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
    }

    $(document).on('change', '#img-introductions', function () {
        let file = $(this).prop('files')
        if (file) {
            if (file[0].size > 1024 * 1024 * 5) {
                $(this).parent().parent().find('.error-answer').addClass('msg-error').text('Images can be uploaded up to 5MB.');
            } else {
                previewImage($(this)[0], $(this).parent().find('.img-preview'));
                $(this).parent().parent().find('.error-answer').removeClass('msg-error').text('');
                $(this).parents('.form-group').removeClass('err-input msg-error');
            }
        }
    })

    function addErrors(thisElement) {
        $(thisElement).addClass('err-input msg-error');
        $(thisElement).parent().find('.error-answer').text('Youtube link format is not correct');
    }

    function getStepAndCollapse(thisElement, index) {
        $(thisElement).parent().parent().find('.text-decoration-none').text(`Step ${index + 1}`)
        $(thisElement).parent().parent().find('.text-decoration-none').parent().parent().attr("href", `#collapse-problem-solving-${index + 1}`)
        $(thisElement).parent().parent().find('.collapse').attr("id", `collapse-problem-solving-${index + 1}`)
    }

    function scrollToError(thisElement) {
        let topPos = thisElement[0].offsetTop;
        window.scrollTo(0, topPos - 170);
    }

    function scrollToStep(id) {
        document.getElementById(id).scrollIntoView(true, {behavior: "smooth"})
        window.scrollBy(0, -80);
    }

    function trimParamForm(parent) {
        let data = parent.serializeArray();
        if (data.length !== 0) {
            $.each(data, async (index, element) => {
                await parent.find(`textarea[name="${element.name}"]`).val(element.value.trim());
                await parent.find(`input.video-type[name="${element.name}"]`).val(element.value.trim());
            });
        }
    }

    $(document).on('click', '#problem-solving .add-template', function () {
        let length_temp = $('#problem-solving .form-sub').children().length;
        let html = getHtml('form-problem-solving', length_temp, 'problem-solving');
        $('#problem-solving .form-sub').append(html);
        scrollToStep(`step-problem-solving-${length_temp}`)
        $('#problem-solving .remove-template').map( function (index){
            getStepAndCollapse($(this), index)
        })
    });

    $(document).on('click', '#problem-solving .remove-template', function () {
        let div = $(this).parent().parent();
        div.remove()
        $('#problem-solving .remove-template').map( function (index){
            $(this).parent().parent().find('.text-decoration-none').text(`Step ${index + 1}`)
        })
    });

    $(document).on('click', '#reactivate-your-life .add-template', function () {
        let length_temp = $('#reactivate-your-life .form-sub').children().length;
        let html = getHtml('form-reactivate-your-life', length_temp, 'reactivate-your-life');
        $('#reactivate-your-life .form-sub').append(html);
        scrollToStep(`step-reactivate-your-life-${length_temp}`)
        $('#reactivate-your-life .remove-template').map( function (index){
            getStepAndCollapse($(this), index)
        })
    });

    $(document).on('click', '#reactivate-your-life .remove-template', function () {
        let div = $(this).parent().parent();
        div.remove()
        $('#reactivate-your-life .remove-template').map( function (index){
            $(this).parent().parent().find('.text-decoration-none').text(`Step ${index + 1}`)
        })
    });

    $(document).on('click', '#positive-thinking-practice .add-template', function () {
        let length_temp = $('#positive-thinking-practice .form-sub').children().length;
        let html = getHtml('form-thinking-practice-guide', length_temp, 'thinking-practice-guide');
        $('#positive-thinking-practice .form-sub').append(html);
        scrollToStep(`step-thinking-practice-guide-${length_temp}`)
        $('#positive-thinking-practice .remove-template').map( function (index){
            getStepAndCollapse($(this), index)
        })
    });

    $(document).on('click', '#positive-thinking-practice .remove-template', function () {
        let div = $(this).parent().parent();
        div.remove()
        $('#positive-thinking-practice .remove-template').map( function (index){
            $(this).parent().parent().find('.text-decoration-none').text(`Step ${index + 1}`)
        })
    });

    $(document).on('click', '#relap-prevention .add-template', function () {
        let length_temp = $('#relap-prevention .form-sub').children().length;
        let html = getHtml('form-relap-prevention', length_temp, 'relap-prevention');
        $('#relap-prevention .form-sub').append(html);
        scrollToStep(`step-relap-prevention-${length_temp}`)
        $('#relap-prevention .remove-template').map( function (index){
            getStepAndCollapse($(this), index)
        })
    });

    $(document).on('click', '#relap-prevention .remove-template', function () {
        let div = $(this).parent().parent();
        div.remove()
        $('#relap-prevention .remove-template').map( function (index){
            $(this).parent().parent().find('.text-decoration-none').text(`Step ${index + 1}`)
        })
    });

    //tab6
    $(document).on('click', '#introduction-depression .add-template', function () {
        let length_temp = $('#introduction-depression .form-sub').children().length;
        let html = getHtmlIntroductionDepression('form-introduction-depression', length_temp, 'introduction-depression');
        $('#introduction-depression .form-sub').append(html);
        scrollToStep(`step-introduction-depression-${length_temp}`);
        $('#introduction-depression .remove-template').map(function (index){
            getStepAndCollapse($(this), index);
        });
    });

    $(document).on('click', '#introduction-depression .remove-template', function () {
        $(this).parent().parent().remove();
        $('#introduction-depression .remove-template').map(function (index){
            $(this).parent().parent().find('.text-decoration-none').text(`Step ${index + 1}`)
        });
    });
    //end

    $(document).on('click', '#introduction-vmoop .add-template', function () {
        let length_temp = $('#introduction-vmoop .form-content-introduction .form-video').children().length;
        let html = `
          <div class="m-1 d-flex w-100" id="step-content-introduction-${length_temp}">
            <div class="w-88">
              <a data-toggle="collapse" href="#collapse-content-introduction-${length_temp + 2}" role="button" aria-expanded="true" aria-controls="collapse-content-introduction-${length_temp + 2}" class="with-chevron">
                <div class="d-flex align-items-center justify-content-between question-set-title title-outline-style bg-banana">
                  <div class="text-decoration-none">Step ${length_temp}</div><i class="fa fa-angle-down"></i>
                </div>
              </a>
              <div id="collapse-content-introduction-${length_temp + 2}" class="collapse show">
                <div class="control mt-2">
                  <h4 class="">Header:</h4>
                </div>
                <div class="">
                  <div class="video-item">
                    <p class="">Vietnamese:</p>
                    <textarea name="introduction[content${length_temp}[header_video_vn]]" class="video-type lang-vn" rows="4" value=""></textarea>
                    <span class="error-answer color-red"></span>
                  </div>

                  <div class="video-item mb-2">
                    <p class="">English:</p>
                    <textarea name="introduction[content${length_temp}[header_video_en]]" class="video-type lang-en" rows="4" value=""></textarea>
                    <span class="error-answer color-red"></span>
                  </div>

                  <div class="video-item mb-2">
                    <p class="">Spanish:</p>
                    <textarea name="introduction[content${length_temp}[header_video_sp]]" class="video-type lang-sp" rows="4" value=""></textarea>
                    <span class="error-answer color-red"></span>
                  </div>
                </div>
                <div class="control">
                  <h4 class="">Image:</h4>
                </div>
                <div class="form-group my-auto img-introduction d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <img alt="your image" class="img-circle img-account img-preview mr-2" src="/assets/product/prodgr_default_product-f3e7782561d1bde73946a59020a1f36e371653d39a69455ff0ac2074208d27b0.png">
                    <input id="img-introductions" class="validate" accept="image/png,image/jpeg,image/jpg,image/gif" name="introduction[content${length_temp}[image_introduction]]" type="file">
                  </div>
                  <span class="error-answer color-red"></span>
                </div>
                <div class="form-text mt-4">
                  <div class="">
                    <div class="text-item">
                      <p class="">Vietnamese:</p>
                      <textarea name="introduction[content${length_temp}[title_vn]]" class="video-type text-type lang-vn" rows="4" value=""></textarea>
                      <span class="error-answer color-red"></span>
                    </div>

                    <div class="text-item mb-2">
                      <p class="">English:</p>
                      <textarea name="introduction[content${length_temp}[title_en]]" class="video-type text-type lang-en" rows="4" value=">"></textarea>
                      <span class="error-answer color-red"></span>
                    </div>

                    <div class="text-item mb-2">
                      <p class="">Spanish:</p>
                      <textarea name="introduction[content${length_temp}[title_sp]]" class="video-type text-type lang-sp" rows="4" value=""></textarea>
                      <span class="error-answer color-red"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-lg-end align-items-start mr-2 ml-2 mt-2">
              <div class="remove-template remove-template-1 mr-3">-</div>
              <div class="add-template">+</div>
            </div>
          </div>
        `
        $('#introduction-vmoop .form-content-introduction .form-video').append(html);
        scrollToStep(`step-content-introduction-${length_temp}`)
        $('#introduction-vmoop .remove-template').map( function (index){
            $(this).parent().parent().find('.text-decoration-none').text(`Step ${index + 1}`)
            $(this).parent().parent().find('.text-decoration-none').parent().parent().attr("href", `#collapse-content-introduction-${index + 1}`)
            $(this).parent().parent().find('.collapse').attr("id", `collapse-content-introduction-${index + 1}`)
        })
    });

    $(document).on('click', '#introduction-vmoop .form-content-introduction .remove-template', function () {
        $(this).parent().parent().remove();
        $('#introduction-vmoop .remove-template').map( function (index){
            $(this).parent().parent().find('.text-decoration-none').text(`Step ${index + 1}`)
        })
    });

    $(document).on('click', '#introduction-help .add-template', function () {
        let length_temp = $('#introduction-help .form-content-introduction .form-video').children().length;
        let html = `
          <div class="m-1 d-flex w-100" id="step-content-help-${length_temp}">
            <div class="w-88">
              <a data-toggle="collapse" href="#collapse-content-help-${length_temp + 2}" role="button" aria-expanded="true" aria-controls="collapse-content-help-${length_temp + 2}" class="with-chevron">
                <div class="d-flex align-items-center justify-content-between question-set-title title-outline-style bg-banana">
                  <div class="text-decoration-none">Step ${length_temp}</div><i class="fa fa-angle-down"></i>
                </div>
              </a>
              <div id="collapse-content-help-${length_temp + 2}" class="collapse show">
                <div class="control mt-2">
                  <h4 class="">Header:</h4>
                </div>
                <div class="">
                  <div class="video-item">
                    <p class="">Vietnamese:</p>
                    <textarea name="introduction[content${length_temp}[header_video_vn]]" class="video-type lang-vn" rows="4" value=""></textarea>
                    <span class="error-answer color-red"></span>
                  </div>

                  <div class="video-item mb-2">
                    <p class="">English:</p>
                    <textarea name="introduction[content${length_temp}[header_video_en]]" class="video-type lang-en" rows="4" value=""></textarea>
                    <span class="error-answer color-red"></span>
                  </div>

                  <div class="video-item mb-2">
                    <p class="">Spanish:</p>
                    <textarea name="introduction[content${length_temp}[header_video_sp]]" class="video-type lang-sp" rows="4" value=""></textarea>
                    <span class="error-answer color-red"></span>
                  </div>
                </div>
                <div class="control">
                  <h4 class="">Image:</h4>
                </div>
                <div class="form-group my-auto img-introduction d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <img alt="your image" class="img-circle img-account img-preview mr-2" src="/assets/product/prodgr_default_product-f3e7782561d1bde73946a59020a1f36e371653d39a69455ff0ac2074208d27b0.png">
                    <input id="img-introductions" class="validate" accept="image/png,image/jpeg,image/jpg,image/gif" name="introduction[content${length_temp}[image_introduction]]" type="file">
                  </div>
                  <span class="error-answer color-red"></span>
                </div>
                <div class="form-text mt-4">
                  <div class="">
                    <div class="text-item">
                      <p class="">Vietnamese:</p>
                      <textarea name="introduction[content${length_temp}[title_vn]]" class="video-type text-type lang-vn" rows="4" value=""></textarea>
                      <span class="error-answer color-red"></span>
                    </div>

                    <div class="text-item mb-2">
                      <p class="">English:</p>
                      <textarea name="introduction[content${length_temp}[title_en]]" class="video-type text-type lang-en" rows="4" value=">"></textarea>
                      <span class="error-answer color-red"></span>
                    </div>

                    <div class="text-item mb-2">
                      <p class="">Spanish:</p>
                      <textarea name="introduction[content${length_temp}[title_sp]]" class="video-type text-type lang-sp" rows="4" value=""></textarea>
                      <span class="error-answer color-red"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-lg-end align-items-start mr-2 ml-2 mt-2">
              <div class="remove-template remove-template-1 mr-3">-</div>
              <div class="add-template">+</div>
            </div>
          </div>
        `
        $('#introduction-help .form-content-introduction .form-video').append(html);
        scrollToStep(`step-content-help-${length_temp}`)
        $('#introduction-help .remove-template').map( function (index){
            $(this).parent().parent().find('.text-decoration-none').text(`Step ${index + 1}`)
            $(this).parent().parent().find('.text-decoration-none').parent().parent().attr("href", `#collapse-content-help-${index + 1}`)
            $(this).parent().parent().find('.collapse').attr("id", `collapse-content-help-${index + 1}`)
        })
    });

    $(document).on('click', '#introduction-help .form-content-introduction .remove-template', function () {
        $(this).parent().parent().remove();
        $('#introduction-help .remove-template').map( function (index){
            $(this).parent().parent().find('.text-decoration-none').text(`Step ${index + 1}`)
        })
    });

    $(document).on('click', '.create-help-document', async function () {
        let valid = true;
        $('.form-problem-solving .video-type').map( function() {
            let check = showNotification($(this).val().trim(), $(this), $(this).parent().find('.error-answer'));
            if (check == false){
                valid = false;
            } else {
                if ($(this).hasClass('link-youtube')){
                    if (!validateYouTubeUrl($(this).val().trim())){
                        valid = false;
                        addErrors($(this))
                    }
                }
            }
        })

        if (valid == true) {
            let form = $('#problem-solving #form-create-help-document');
            await trimParamForm(form);
            await form.submit();
        } else {
            let element = $('.form-problem-solving .msg-error').first();
            scrollToError(element)
        }
    });

    $(document).on('click', '.create-help-document-tab2', async function () {
        let valid = true;
        $('.form-reactivate-your-life .video-type').map( function() {
            let check = showNotification($(this).val().trim(), $(this), $(this).parent().find('.error-answer'));
            if (check == false){
                valid = false;
            } else {
                if ($(this).hasClass('link-youtube')){
                    if (!validateYouTubeUrl($(this).val().trim())){
                        valid = false;
                        addErrors($(this))
                    }
                }
            }
        })
        if (valid == true) {
            let form = $('#reactivate-your-life #form-create-help-document');
            await trimParamForm(form);
            await form.submit();
        } else {
            let element = $('.form-reactivate-your-life .msg-error').first();
            scrollToError(element)
        }
    });

    $(document).on('click', '.create-help-document-tab3', async function () {
        let valid = true;
        $('.form-thinking-practice-guide .video-type').map( function() {
            let check = showNotification($(this).val().trim(), $(this), $(this).parent().find('.error-answer'));
            if (check == false){
                valid = false;
            } else {
                if ($(this).hasClass('link-youtube')){
                    if (!validateYouTubeUrl($(this).val().trim())){
                        valid = false;
                        addErrors($(this))
                    }
                }
            }
        })
        if (valid == true) {
            let form = $('#positive-thinking-practice #form-create-help-document');
            await trimParamForm(form);
            await form.submit();
        } else {
            let element = $('.form-thinking-practice-guide .msg-error').first();
            scrollToError(element)
        }
    });

    $(document).on('click', '.create-help-document-tab4', async function () {
        let valid = true;
        $('.form-relap-prevention .video-type').map( function() {
            let check = showNotification($(this).val().trim(), $(this), $(this).parent().find('.error-answer'));
            if (check == false){
                valid = false;
            } else {
                if ($(this).hasClass('link-youtube')){
                    if (!validateYouTubeUrl($(this).val().trim())){
                        valid = false;
                        addErrors($(this))
                    }
                }
            }
        })
        if (valid == true) {
            let form = $('#relap-prevention #form-create-help-document');
            await trimParamForm(form);
            await form.submit();
        } else {
            let element = $('.form-relap-prevention .msg-error').first();
            scrollToError(element)
        }
    });

    $(document).on('click', '#introduction-vmoop .create-help-document-tab5', async function () {
        let valid = true;
        $('#introduction-vmoop #form-create-introduction-vmoop .video-type').map( function() {
            let check = showNotification($(this).val().trim(), $(this), $(this).parent().find('.error-answer'));
            if (check == false){
                valid = false;
            } else {
                if ($(this).hasClass('link-youtube')){
                    if (!validateYouTubeUrl($(this).val().trim())){
                        valid = false;
                        addErrors($(this))
                    }
                }
            }
        })

        $('#introduction-vmoop #form-create-introduction-vmoop .form-group.my-auto.img-introduction').map( function (){
            let file = $(this).find('#img-introductions').prop('files');
            let div = $(this).find('.image-introduct');
            if (div.length === 0 && file.length === 0) {
                $(this).find('.error-answer').text('This field is required.');
                valid = false;
            }
        });
        let element = $('#introduction-vmoop .form-introduction .msg-error').first();
        if (element.length > 0) {
            valid = false;
        }
        if (valid == true) {
            let form = $('#introduction-vmoop #form-create-introduction-vmoop');
            await trimParamForm(form);
            await form.submit();
        } else {
            scrollToError(element)
        }
    });

    $(document).on('click', '#introduction-depression .create-help-document-tab6', async function () {
        let valid = true;
        $('#introduction-depression #form-create-introduction_depression .video-type').map( function() {
            let check = showNotification($(this).val().trim(), $(this), $(this).parent().find('.error-answer'));
            if (check == false){
                valid = false;
            } else {
                if ($(this).hasClass('link-youtube')){
                    if (!validateYouTubeUrl($(this).val().trim())){
                        valid = false;
                        addErrors($(this))
                    }
                }
                if ($(this).hasClass('link-refer')){
                    if (!isUrlValid($(this).val().trim())){
                        valid = false;
                        $(this).addClass('err-input msg-error');
                        $(this).parent().find('.error-answer').text('Link format is not correct');
                    }
                }
            }
        })

        if (valid == true) {
            let form = $('#introduction-depression #form-create-introduction_depression');
            await trimParamForm(form);
            await form.submit();
        } else {
            let element = $('.form-introduction-depression .msg-error').first();
            scrollToError(element)
        }
    });

    $(document).on('click', '#introduction-help .create-help-document-tab7', async function () {
        let valid = true;
        $('#introduction-help #form-create-introduction-help .video-type').map( function() {
            let check = showNotification($(this).val().trim(), $(this), $(this).parent().find('.error-answer'));
            if (check == false){
                valid = false;
            } else {
                if ($(this).hasClass('link-youtube')){
                    if (!validateYouTubeUrl($(this).val().trim())){
                        valid = false;
                        addErrors($(this))
                    }
                }
            }
        })

        $('#introduction-help #form-create-introduction-help .form-group.my-auto.img-introduction').map( function (){
            let file = $(this).find('#img-introductions').prop('files');
            let div = $(this).find('.image-introduct');
            if (div.length === 0 && file.length === 0) {
                $(this).addClass('err-input msg-error');
                $(this).find('.error-answer').text('This field is required.');
                valid = false;
            }
        });
        let element = $('#introduction-help .form-introduction .msg-error').first();
        if (element.length > 0) {
            valid = false;
        }
        if (valid == true) {
            let form = $('#introduction-help #form-create-introduction-help');
            await trimParamForm(form);
            await form.submit();
        } else {
            scrollToError(element)
        }
    });
});
